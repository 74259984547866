.admin-dashboard-flyout {
  top: 37px !important;
  font-family: Verdana, Arial, sans-serif;
}

#legacy-admin-header-top {
  .for-some-reason-the-first-class-is-ignored {
    font: inherit;
  }

  .admin-menu-button-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .admin-menu-button-box {
    display: flex;
    float: right;
    max-width: 100px;
    padding: 0px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: -19px;
    margin-right: -5px;
    position: relative;
  }

  .admin-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 38px;
    padding: 0px !important;
    overflow: visible;
    border-radius: 5px;
    border: 1px solid black;
    min-width: 35px;
    margin: 0px;
    background-color: #1eabe3;
  }

  .md-button:not([disabled]):hover {
    background-color: #1eabe3;
  }

  .admin-svg-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    width: 23px;
    height: 23px;
    overflow: visible;
  }

  .admin-menu-button-icon {
    width: 100%;
    height: 100%;
  }

  .md-open-menu-container.md-active {
    border: 1.5px solid #ababab;
    border-radius: 8px !important;
    font-family: calibri, sans-serif;
    font-size: 15px;
    position: absolute;
  }

  .menu-content {
    padding: 0px !important;
    width: 165px;
  }

  .menu-item {
    min-height: 39px;
    height: 39px;
  }

  .menu-item-button {
    margin: 0px !important;
    padding-right: 0px;
    padding-left: 15px !important;

    line-height: 22.5px;
    background-color: white !important;
  }

  .menu-item-button:hover {
    background-color: #fafbff !important;
  }

  .menu-divider {
    line-height: 15px;
    margin: 0px !important;
    border: 0.5px solid #ababab;
  }
}

#legacy-admin-header {
  .for-some-reason-the-first-class-is-ignored {
    font: inherit;
  }

  .admin-header-items-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-bottom: none !important;
    align-items: flex-end;
    padding: 0 !important;
    margin: 0 -15px !important;
    font-family: Calibri, sans-serif;
    font-size: 1.4em;
    min-height: 80px;
  }

  .admin-hamburger-container {
    border-bottom: 1.5px solid lightgray !important;
    padding: 0.5em 1em;
    height: 45.13px;
    width: 3em !important;
  }

  #flyoutHolder {
    margin-right: 15px;
    padding-bottom: 0;
  }

  .logo-container {
    border-bottom: 1.5px solid lightgray;
    padding-bottom: 0.5em;
    padding-right: 1.5em;
    padding-top: 0.5em;
  }

  .admin-header-banner-logo {
    align-self: flex-end !important;
    flex-wrap: nowrap !important;
    margin-left: 21px !important;
    max-height: 5em !important;
    object-fit: contain !important;
    width: 7em !important;
  }

  .admin-header-tabs {
    box-sizing: border-box;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Calibri, sans-serif;
    font-size: 1.4em;
    position: relative;
    scrollbar-width: none;
    white-space: nowrap;
  }

  .admin-tab-tooltip {
    background-color: rgba(85, 85, 85, 0.92) !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    height: auto !important;
    line-height: 1.45em !important;
    margin: 0.5em !important;
    padding: 4px 8px !important;
  }

  .legacy-tab {
    border: none;
    border-bottom: 1.5px solid lightgray;
    border-radius: 5px 5px 0 0;
    border-image: initial;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-family: Calibri, sans-serif;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    letter-spacing: 0.02857em;
    line-height: 1.25;
    margin-top: 1.5em;
    min-height: 1em;
    max-height: 2.2em;
    min-width: 90px;
    max-width: 360px;
    padding: 0.5em;
    text-transform: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    white-space: normal;
    width: auto;
  }

  .legacy-tab:not(.admin-tab-selected-color, .legacy-tab--disabled) {
    color: gray !important;
  }

  .legacy-tab--disabled {
    color: lightgray !important;
  }

  .legacy-tab > .ng-scope > svg {
    color: inherit;
    background-color: inherit;
    height: 20px !important;
    margin-right: 3px;
    width: auto;

    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .ah-icon:not(.admin-tab-selected-color, .ah-icon--disabled) > svg {
    fill: gray !important;
    stroke: gray !important;
  }

  .ah-icon--disabled > svg {
    fill: lightgray !important;
    stroke: lightgray !important;
  }

  .ah-dashboard,
  .ah-training-insights,
  .ah-recommendations {
    fill: none;
    stroke-width: 20;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .ah-career-pathing,
  .ah-management,
  .ah-people,
  .ah-rules {
    fill: none;
    stroke-width: 20;
    stroke-miterlimit: 10;
  }

  .ah-content-b {
    fill: none;
    stroke-width: 6;
    stroke-miterlimit: 10;
  }

  .legacy-tab.admin-tab-selected-color {
    border-radius: 5px 5px 0 0;
    border-top: 1.5px solid lightgray;
    border-right: 1.5px solid lightgray;
    border-bottom: none;
    border-left: 1.5px solid lightgray;
    border-image: initial;
  }

  .spacer {
    flex-grow: 500;
    margin-left: -2px;
    border-bottom: 1.5px solid lightgray;
  }
}
