.singlecourse {
    margin-bottom: 5px;
    width: 100%;
    margin-top: 3px;
    padding: 0px 5px;
}

.singlecourse h3 {
    margin: 0px 0px 15px;
}

/*.arrow {*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-style: solid;*/
    /*border-width: 30px 250px 0 250px;*/
    /*border-color: #e5e5e5 transparent transparent transparent !important;*/
/*}*/

.arrow {
    display: none;
    position: absolute;
    bottom: -3px;
    width: calc(100% - 10px);
}

.assignment.rightColumn .arrow, .assignment.leftColumn .arrow {
    width: calc(100% - 5px);
}

.arrow .arrowTopBorder {
    height: 1.1rem;
    background-color: #e5e5e5;
}

.arrow .point {
    width: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
}

.singlecourse .body {
    background-color: #f2f2f2;
    overflow: hidden;
    padding: 5px;
    padding-left: 10px;
}

.users .row .col-md-12 h4,
.singlecourse .body h4 {
    margin: 0px;
}

.singlecourse .body .info {

}

.singlecourse .body .info .content {
    overflow: hidden;
    width: 300px;
    float: left;
    margin-right: 15px;
    position: relative;
}

.singlecourse .body .info .content .mainImg {
    background-color: #bbb;
    float: left;
    margin-right: 15px;
    width: 300px;
}

.singlecourse .closer {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    height: 30px;
    line-height: 27px;
    right: 15px;
    width: 30px;
}

.singlecourse .body .info .infoHolder  {
    float: left;
    width: 100%;
}

.singlecourse .offeringSlide {
    background-color: #fff;
    display: none;
    max-height: 120px;
    overflow: scroll;
    position: absolute;
    right: 10px;
    width: 50%;
    z-index: 100;
}

.singlecourse .body .info .infoHolder .apiActionLoader {
    margin-bottom: 7px;
    overflow: hidden;
}

/* code, type and source on slide down */
.slideDownMeta {
    padding-left: 0px;
    padding-top: 4rem;
    color: black;
    font-size: 16px;
    list-style: none;
}

.dateBlock{

    display:block 
}

.slideDownMeta li {
    display: inline-block;
}

.slideDownMeta li.space {
    margin: 0px 15px;
}

.singlecourse .offerings {
    width: 100%;
}

/***** assignment list & catalog list pages *****/
/* Need some specific styling for these pages */
#assignmentCourseList #singlecourse {
    margin-left: calc(8.3% * 3);
    width: calc(100% - 8.3% * 3);
    padding: 0;
    margin-top: 0;
}
/* Hide the meta information on the single course slide down on these pages only */
#assignmentCourseList #singlecourse .slideDownMeta {
    display: none;
}
#assignmentCourseList #singlecourse .body {
    background-color: white;
}
#assignmentCourseList #singlecourse .body .description {
    padding-bottom: 1rem;
}

.ScheduleCourseModal .title {
    font-weight: bold;

}

.ScheduleCourseModal .modal .requestContainer
{
    width: 100%;
}

.ScheduleCourseModal .dateContainer {
    position: relative;
    overflow: hidden;
    display: flex;
}

.ScheduleCourseModal .actionHolder {
    margin-top: 1rem;
    overflow: visible;
    width: 100%;
}

.ScheduleCourseModal .RequestApprovalbtn
{
    padding: 1rem 3rem !important;
}

.ScheduleCourseModal .Schedulebtn
{
    padding: 1rem 6rem !important;
}


