#catalogLibrary .tile {
    margin-bottom: 1.75em;
}

#catalogLibrary .tile .tile-inner {
    margin-left: 2em;
    margin-right: 2em;
    border: 2px solid #ccc;
    min-height: 32.5em;
    padding: 1.75em;
}

#catalogLibrary .tile .margin-bottom {
    margin-bottom: .75em;
}

#catalogLibrary .tile .description {
    min-height: 10em;
    font-size: 1em;
    text-align: left;
}

#catalogLibrary .view-all {
    color: #1EABE3;
    font-size: 1.25em;
}

#catalogLibrary #categoriesContainer {
    padding: 0px;
}

#catalogLibrary .name {
    font-size: 2em;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

#catalogLibrary .align-bottom {
    position: absolute;
    bottom: 0;
    right: 2em;
}

#catalogLibrary .panel-title {
    height: 8em;
}

#catalogLibrary .top-description {
    margin: 20px;
}
    