.repeatable {
    margin-bottom: 15px;
}

.ta .offerings .remover {
    font-size: 12px;
    text-decoration: underline;
}

.ta .rosterSpots {
    font-size: 12px;
    float: right!important;
}

.ta .offerings .actor {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
}

.ta .offerings button.actor {
    background: none;
    border: none;
}

.ta .offerings .actor span {
    text-decoration: underline;
}

.ta .offerings .actor:before {
    content: '+';
    text-decoration: none;
}

.repeatableBody {
    background-color: #E5E5E5;
    padding: 15px;
}

.col-md-12 .panel-group .panel.offering-has-error .panel-heading{
    color: #a94442;
    background-color: #f8d8d6;
    border-color: #ebccd1;
}

.repeater .repeatable .repeatableBody .row.form-inline {
    margin: 15px -15px;
}

.repeater .repeatable .repeatableBody .row.form-inline label {
    margin-right: 7px;
}

.repeater .repeatable .repeatableBody .row.form-inline .form-group {
    margin-right: 15px;
}

.repeater .repeatable hr {
    border-top: 1px solid #ccc;
}

.table h6 {
    font-size: 12px;
    font-weight: bold;
}

.repeater .days {
    float: right;
    margin: 0px;
    padding: 0px;
}

.repeater .days .day {
    background-color: #fff;
    border: 1px solid #ccc;
    border-right: none;
    display: inline-block;
    float: left;
    height: 30px;
    margin: 0px;
    padding: 5px;
    text-align: center;
    width: 30px;
}

.repeater .days .day:first-child {
    border-left: 1px solid #ccc;
}

.repeater .days .day:last-child {
    border-right: 1px solid #ccc;
}

.repeater .days .day.active {
    background-color: #ccc;
}

.repeater .repeatable .repeatableBody {

}

.repeater .repeatable .repeatableBody .row.form-inline {
    margin: 15px -15px;
}

.repeater .repeatable .repeatableBody .row.form-inline label {
    margin-right: 7px;
}

.repeater .repeatable .repeatableBody .row.form-inline .form-group {
    margin-right: 15px;
}

.repeater .repeatable hr {
    border-top: 1px solid #ccc;
}

.table h6 {
    font-size: 12px;
    font-weight: bold;
}

.repeater .days {
    float: right;
    margin: 0px;
    padding: 0px;
}

.repeater .days .day {
    background-color: #fff;
    border: 1px solid #ccc;
    border-right: none;
    display: inline-block;
    float: left;
    height: 30px;
    margin: 0px;
    padding: 5px;
    text-align: center;
    width: 30px;
}

.repeater .days .day:first-child {
    border-left: 1px solid #ccc;
}

.repeater .days .day:last-child {
    border-right: 1px solid #ccc;
}

.repeater .days .day.active {
    background-color: #ccc;
}

.ta .create .spinner {
    margin: 15px auto;
}

.ta .modal-body .row {
    margin-bottom: 15px;
}

.ta .modal-body .row:last-child {
    margin-bottom: 0px;
}

.ta .modal-body .row .iltCount {
    border: 1px solid #ccc;
    padding: 30px 0 15px;
}

.ta .modal-body .iltCount:first-child {
    margin: 0px 30px;
}

.ta .modal-body .row .iltCount p {
    font-size: 16px;
    font-weight: bold;
}

.ta .modal-body .row .iltCount .count {
    color: #999;
    font-size: 36px;
}

.ta .modal-body .container-fluid {
    text-align: center;
}