/**** Manager page ****/

/** Summary box at top of page 
 * Includes those two rows of meta information and the chart on the right **/
#manager .summary {
    background-color: #f5f5f5;
    padding-left: 2rem;
    padding-right: 2rem;
}

/* The people/assignments headers in the summary
 * Also the pie chart header
 */
#manager .summary .summaryHeader {
    font-weight: bold;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: .5rem;
}

/* The boxes underneath the people and assignment sections in the summary */
#manager .summary .summaryRow {
    display: flex;
    justify-content: flex-begin;
}
/* The formatting for each summary box */
#manager .summary .summaryRow .summaryBox {
    /*
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    */
    width: 16rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#manager .summary .summaryRow .summaryBox .title {
    font-size: 12px;
    color: black;
    text-align: center;
}
#manager .summary .summaryRow .summaryBox .amount {
    font-weight: bold;
    font-size: 26px;
    text-align: center;
}

/* Pie chart */
#manager .summary .summaryPieChart .summaryPieChartContainer .chart-container {
    margin-top: 1rem;
    margin-left: 0;
}

/* summary pie chart legend */
#manager .summary .summaryPieChart .chartBody {
    /* Make the pie chart and chart legend have equal height */
    display: flex;
}
#manager .summary .summaryPieChart .chartBody .chartLegend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: 6rem;
    padding-bottom: 1rem;
}
#manager .summary .summaryPieChart .chartBody .chartLegend .amount {
    font-weight: bold;
    font-size: 24px;
    display: block;
    text-align:center;
}

#manager .summary .summaryPieChart .chartBody .chartLegend .title {
    font-weight: bold;
    font-size: 12px;
    display: block;
    margin-top: -1rem;
    text-align:center;
    padding: 5px 0 5px 0;
}

/* Make the summary pie chart work at different resolutions */
@media (min-width: 1024px) and (max-width: 1299px) {

    /* Summary boxes */
    div#manager .summary .summaryRow .summaryBox {
        /* width: 11rem; */
        width: 18%;
        justify-content: flex-end;
    }
    div#manager .summary .summaryRow .summaryBox .title {
        /* This makes word wrapping work in ie11 */
        width: 100%;
    }
    div#manager .summary .summaryRows {
        width: 60%;
    }

    /* Summary pie chart */
    div#manager .summary .summaryHeader {
        text-align: center;
    }
    div#manager .summary .summaryPieChart .summaryPieChartContainer .chart-container {
        margin-left: 1rem;
    }
    div#manager .summary .summaryPieChart .chartBody .chartLegend {
        padding-left: 3rem;
    }

    div#manager .resultPieChartContainer .resultPieChart {
        margin-left: 0;
    }

    /* Team summary */
    div#manager .searchResult.teamSummary .assignmentTotals {
        padding-left: 2.5rem;
    }
}

/*** EMPTY PIE CHART STUFF ***/
/* Every empty pie chart is just a big white circle */
#manager .emptyPieChart {
    background-color: white;
    display: inline-block;
    border-radius: 50%;
}
/* Minion empty pie charts */
#manager .emptyPieChartContainer {
    display: flex;
    justify-content: center;
}
#manager .emptyPieChartContainer .emptyPieChart.minion {
    margin-left: 7rem;
    /* Make sure this thing never gets bigger or smaller */
    min-width: 177px;
    min-height: 177px;
    max-width: 177px;
    max-height: 177px;
}

@media (min-width: 992px) and (max-width: 1023px) {
    #manager div.emptyPieChartContainer .emptyPieChart.minion  {
        margin-left: 0rem;
    }
}
@media (max-width: 1299px) and (min-width: 1024px) {
    #manager div.emptyPieChartContainer .emptyPieChart.minion  {
        margin-left: 3rem;
    }
}

/* top of page summary */
#manager .emptySummaryPieChartContainer {
    margin-top: 1rem;
    margin-left: 0;
}

/* The team lead under the search result. Also top of page summary */
#manager .emptyPieChart.summary {
    /* Make sure this thing never gets bigger or smaller */
    min-width: 195px;
    min-height: 195px;
    max-width: 195px;
    max-height: 195px;
}

/** The little summary expander **/
#manager .summaryExpander {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

/** Search bar and filters **/
#manager .filterAndSearchBar {
    margin-top: 3rem;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
    display: flex;
    align-items: baseline;
}

/* Team filters to the left on search bar */
#manager .filterAndSearchBar .filterBar {
    padding-left: 0;
}

/* The direct team filter on search bar */
#manager .directTeam {
    /* Make it so we can have a flyout under the direct team link */
    position: relative;
    font-size: 14px;
}
#manager .filterAndSearchBar .filterBar .selected a {
    font-weight: bold;
    color: black;
    text-decoration: none;
    border-bottom: 2px solid black;
}
/* This is the little down error next to the direct team action item */
#manager .filterAndSearchBar .filterBar .directTeam img {
    padding-left: 1px;
    margin-bottom: 3px;
}

/* The alternate team filter on search bar */
#manager .filterAndSearchBar .filterBar .alternateTeam {
    color: #aeaeae;
    font-size: 14px;
}

/* The direct team flyout */
#manager .directTeamFlyout {
    position: absolute;
    left: 10rem;
    top: 2rem;
    background-color: white;
    -webkit-box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    padding-left: 0;
    z-index: 1;
}
#manager .directTeamFlyout li {
    list-style-type: none;
    width: 22rem;
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-left: 1rem;
    border-bottom: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    cursor: pointer;
    /* Makes the text and checkmark on either side of the row */
    display: flex;
    justify-content: space-between;
}
/* Enhance the shadow effects in the flyout */
#manager .directTeamFlyout li:last-child {
    border-bottom: initial;
}
#manager .directTeamFlyout li:first-child {
    border-top: 1px solid rgba(0,0,0,.1);
}
/* The check mark on the right of selected items in the direct team flyout */
#manager .directTeamFlyout li .checkMark {
    padding-right: 1.5rem;
}


/* The pipe character between the team filters on the search bar */
#manager .filterAndSearchBar .filterBar .filter + .filter:before {
    content: "|";
    color: black;
    margin-left: 1rem;
    margin-right: 1rem;
}

/* The input box on the search bar */
#manager .filterAndSearchBar .searchBar {
    display: flex;
    padding-right: 0;
    position: relative;
}
#manager .filterAndSearchBar .searchBar input {
    font-size: 14px;
    border: 1px solid #7f7f7f;
    padding-left: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 1rem;
    flex-grow: 1;
}

/* The searchbar flyout */
#manager .searchBarFlyout {
    position: absolute;
    left: 1.5rem;
    top: 4rem;
    background-color: white;
    -webkit-box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.23);
    padding-left: 0;
}
#manager .searchBarFlyout li {
    list-style-type: none;
    width: 13rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    border-bottom: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    display: flex;
    cursor: pointer;
}
/* Make the row not interfere with the shadow */
#manager .searchBarFlyout li:last-child {
    border-bottom: initial;
}
/* Give the first row an extra shadow effect */
#manager .searchBarFlyout li:first-child {
    border-top: 1px solid rgba(0,0,0,.1);
}
#manager .searchBarFlyout li img {
    margin-right: .8rem;
}
/* The icon in left of each row of the search bar flyout */
#manager .searchBarFlyout li .icon {
    width: 3rem;
    display: flex;
    justify-content: center;
}
/* The selection mark on the right of each flyout */
#manager .searchBarFlyout li .checkMark {
    position: absolute;
    right: 0;
}


/* color the default text in the search bar box */
#manager .filterAndSearchBar .searchBar input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #7f7f7f;
    font-weight: bold;
}
#manager .filterAndSearchBar .searchBar input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #7f7f7f;
    opacity:  1;
    font-weight: bold;
}
#manager .filterAndSearchBar .searchBar input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #7f7f7f;
    opacity:  1;
    font-weight: bold;
}
#manager .filterAndSearchBar .searchBar input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7f7f7f;
    font-weight: bold;
}

/** Breadcrumb **/
#manager .breadcrumb {
    margin-bottom: 2rem;
}
/* spacing around separator is slightly different on
 * manager dashboard */
#manager .breadcrumb .active span {
    padding-left: 6px;
}
#manager .breadcrumb .active {
    padding-left: 0;
}


/** Search results title **/
#manager .resultsHeader {
    margin: 4rem 0rem 2rem 0rem;
}

#manager .resultsHeader a {
    float: right;
    font-size: 12px;
}

#manager .resultsHeader h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
}

/** Individual search results **/
#manager .searchResult {
    margin-top: 1rem;
}
/* Search result header */
#manager .searchResult .header {
    background-color: #e5e5e5;
    overflow: hidden;
    /* height: 12rem; */
    height: 4rem;
}
#manager .searchResult .memberNameAndProfileTable {
    width: 30%;
}
#manager .searchResult .header .name {
    display: inline-block;
    font-size: 20px;
    padding-left: .8rem;
}
#manager .searchResult .managerExpander {
    display: inline-block;
}

/* The little sheeple icon */
#manager .searchResult .sheepleIcon {
    vertical-align: baseline;
    margin-bottom: -1px;
    padding-left: .2rem;
}

/* The list of folks under the manager if search result is a manager */
#manager .searchResult .numberOfReports a,
#manager .searchResult .numberOfReports {
    font-size: 10px;
}

#manager .searchResult .numberOfReports {
    top: -1rem;
}

/* The assignment / view transcript actions on the header of each search result */
#manager .searchResult .actionItems {
    height: 4rem; /* matches height of #manager .searchResult .header. be sure to change both if you change this */
    display: flex;
    align-items: flex-end;
    padding-bottom: .8rem;
    padding-right: 2rem;
}

#manager .searchResult .actionItems a {
    font-size: 12px;
}

/* The pipe character between the action items */
#manager .searchResult .actionItem + .actionItem:before {
    content: "|";
    color: black;
    margin-left: 1rem;
    margin-right: 1rem;
}

/* Search result profile info */
#manager .searchResult .profileInfo {
    background-color: #e5e5e5;
    margin-left: 0;
    margin-right: 0;
    padding-left: .9rem;
    padding-bottom: 1.2rem;
}

#manager .searchResult .closeProfileInfo {
    margin-top: .2rem;
}
#manager .searchResult .closeProfileInfo a {
    font-size: 12px;
}
#manager .searchResult .profileInfo .italic {
    font-style: italic;
}
#manager .searchResult .profileInfo .alignRight {
    text-align: right;
}

/* Search result body */
#manager .searchResult .body {
    background-color: #f5f5f5;
    overflow: hidden;
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

/* Indent the pie chart for the search results */
#manager .resultPieChartContainer .resultPieChart {
    margin-left: 3rem;
}
/* Make the pie chart and legend have the same height */
#manager .searchResult .resultPieChartContainer {
    display: flex;
}

/* Search result pie chart legend */
#manager .searchResult .legend {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 3rem;
    padding-left: 0;
    width: 11rem;
}
#manager .searchResult .legendItem {
    border: 1px solid black;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 10rem;
    background-color: #f9f9f9;
}
#manager .searchResult .legendItem + .legendItem {
    margin-top: 1.5rem;
}
#manager .searchResult .legendItem .amount {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -.7rem;
}

#manager .searchResult .legendItem .title {
    font-weight: bold;
}

/* Search result assignment totals table */
#manager .searchResult .assignmentTotals {
    /* On the search results, not the team summary,
     * the table is slightly indented */
    padding-left: 5rem;
}
#manager .searchResult .assignmentTotals table {
    width: 100%;
}
#manager .searchResult .assignmentTotals th {
    font-weight: bold;
    text-align: center;
}
#manager .searchResult .assignmentTotals tbody tr {
    border-bottom: 1px solid #c3c2c2;
}
#manager .searchResult .assignmentTotals tbody td {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

#manager .searchResult .assignmentTotals tbody td a,
#manager .searchResult .assignmentTotals tbody td p {
    display: inline;
    font-size: 12px;
}

/* The data in the table (not the left most column) should be centered in the middle */
#manager .searchResult .assignmentTotals tbody td + td {
    text-align: center;
}
#manager .searchResult .assignmentTotals tfoot {
    font-weight: bold;
}
#manager .searchResult .assignmentTotals tfoot td {
    padding-top: .5rem;
}
/* The footer boxes except for the leftmost column should be centered */
#manager .searchResult .assignmentTotals tfoot td + td {
    text-align: center;
}

#manager .searchResult .assignmentTotals .disable-href {
    pointer-events: none !important;
}

#manager .searchResult .assignmentTotals .enable-href {
    pointer-events: auto !important;
}

/** Team summary overrides
 * The team summary component is just like the searchResult component except with 
 * some overrides for the background color and pie chart */
/* Background, title and team overrides */
#manager .searchResult.teamSummary .header {
    background-color: initial;
    height: initial;
}
#manager .searchResult.teamSummary .header .name {
    font-size: 24px;
    font-weight: bold;
}
#manager .searchResult.teamSummary .body {
    background-color: initial;
    padding-bottom: 3rem;
    border-bottom: 3px solid #009ce6;
}
/* Legend override */
#manager .searchResult.teamSummary .legendItem {
    width: 11rem;
    background-color: initial;
}
#manager .searchResult.teamSummary .legendItem .amount {
    font-size: 30px;
}
#manager .searchResult.teamSummary .legendItem .title {
    font-size: 14px;
}
/* Pie chart override. Don't indent pie cahrt on manager summary */
#manager .teamSummary .resultPieChartContainer .resultPieChart {
    margin-left: initial;
}

/* Table override */
#manager .searchResult.teamSummary .assignmentTotals {
    padding-left: initial;
}

.managerCourseList .md-datepicker-input-container .md-datepicker-input,
.managerCourseList .md-datepicker-input-container .md-datepicker-triangle-button ,
.managerCourseList .md-datepicker-input-container {
    visibility: hidden;
}

.managerCourseList .edit {
    margin-right: 15px;
}

.managerCourseList .spinner {
    margin: 0px 125px;
}

.featureClass, .featureClass td {
    text-decoration: none !important;
    color: #979797 !important;
}

#manager .searchBar .magnifying-glass svg {
    height: 32px;
    width: 32px;
}

#manager .assignmentsHeader #hierarchyToggle {
    margin-right: 5px;
    vertical-align: text-bottom;
}

#manager .chartLegend .row{
    background-color:white;
    border: solid black 1px;
    padding:0 1rem  0 1rem;
    min-width:10rem;
    margin-top:10px;
}

#manager .overdue{
    color:#f63f3f;
}

#manager .complete .amount{
    color:#009ce6;
}
#manager .chartLegend .incomplete .amount{
    color: #d6d6d6;
}

/* Make the team list work at different resolutions */
@media screen and (max-width: 1200px) {
  .searchResultRow {
     width:100%;
     background-color: #e5e5e5;
     height:100%;
  }

  #manager .searchResult .header {
      overflow: visible;
  }

  .barPercentComplete{
      padding-left:20%;
      padding-top:0px !important;
  }

  .actionItemLinks{
      float: left !important;
      padding-left:20%;
  }
}

#manager #overdueModal .overdueAssignmentList{
    max-height:55rem;
    overflow:auto;
}

#manager #overdueModal .overdueAssignment{
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
    padding-left:40px;
}

#manager #overdueModal .title{
    font-size:16px;
    font-weight: bold;
}

#manager #overdueModal .exportSpinner .spinner{
    margin: 10px 10px 0px 0px;
    float:right;
}

#manager .exportOverdue .spinner{
    height:20px;
    margin:0;
    width:100px;
}

#manager .exportOverdue .title {
    text-decoration:underline;
    border: none;
    background-color: white;
    padding-left:20%;
}


/*  Career Path Student Summary - Start */

#careerPathStudentSummary .cpStudentTile{
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

#careerPathStudentSummary .cpStudentHeader{
    border-bottom: 1px solid #e5e5e5;
    width: 90%;
}

#careerPathStudentSummary .cpStudentHeader span {
    padding-bottom: 5px;
    padding-left:0px;
}

#careerPathStudentSummary .cpStudentDetails {
    padding-top:20px;
    width: 90%;
}

#careerPathStudentSummary .cpStudentDetails span {
    padding-bottom: 5px;
    padding-left:0px;
}

#careerPathStudentSummary .resultsHeader a {
    float: right;
    font-size: 12px;
}

.masonry-layout {
  column-count: 3;
  -webkit-column-count: 3;
}

#careerPathStudentSummary .cpDetails {
    width:100%;
    padding-bottom: 15px !important;
}

#careerPathStudentSummary .filterCPBar {
    margin-left:0px;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-right: 0px;
    border-bottom: none;
}

#careerPathStudentSummary .lastItemInCPDeets{
    padding-bottom:5px;
}

#careerPathStudentSummary  .noLeftPadding{
    padding-left:0px;
}

#careerPathStudentSummary .smallProfileLink{
    font-size:12px;
    color:#000;
}

#careerPathStudentSummary .flexAlign{
  display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    
}


/*  Career Path Student Summary - End */