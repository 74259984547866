.course .certHolder {
    padding-top: 45px;
}

.course .certHolder .col-md-12 {
    background-color: #1EABE3;
    height: 400px;
    margin-bottom: 300px;
}

.course .certHolder .col-md-12 .col-md-4 {
    margin-top: 120px;
}

.course .certHolder .col-md-12 .col-md-4 h2 {
    color: #fff;
    font-weight: bold;
}

.certInfo {
    border: 7px solid #000;
    float: right;
    margin: 15px 0px;
    padding: 0px;
    position: relative;
}

.certInfo img {
    width: 100%;
}

.certInfo span {
    position: absolute;
}

.certInfo .name {
    color: #228FC3;
    font-size: 45px;
    left: 0px;
    text-align: center;
    top: 39%;
    width: 100%;
}

.certInfo .courseName {
    font-size: 20px;
    left: 0px;
    text-align: center;
    top: 55%;
    width: 100%;
}

.certInfo .completionDate {
    width: 42%;
    bottom: 22%;
    color: #228fc3;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    left: 0px;
}

.certInfo .score {
    text-align:center;
    bottom: 20%;
    color: #228fc3;
    font-size: 60px;
    font-style: normal;
    right: 0px;
    width: 42%;
}

.courseCertNMLS .name {
    font-size: 20px !important;
    top: 82% !important; 
}

.courseCertNMLS .courseName {
    font-size: 30px !important;
    top: 54% !important;
    color: #228FC3;
}

.courseCertNMLS .completionDate {
    font-size: 20px !important;
    top: 82% !important; 
    font-weight: normal;
}

.courseCertNMLS .nmlsID {
    font-size: 20px;
    top: 82%; 
    color: #228FC3;
    left: 250px;
    text-align: center;
    width: 100%;
}

.courseCertNMLS .courseCode
{
    font-size: 20px;
    top: 70%; 
    color: #228FC3;
    left: 0px;
    text-align: center;
    width: 100%;
}

.certInfoView {
  color: #000;
  max-width: 1500px;
}

.certInfoView .header {
  margin: 2% auto 10% auto;
}

.certInfoView .seperator ~ .description {
  font-size: 0.9em;
  text-align: left;
  margin-left: 55px;
}

.certInfoView .name {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 2%;
}

.certInfoView .courseName {
  font-size: 1.8em;
  font-weight: bold;
  margin-top: 3%;
}

.certInfoView .completionDate,
.certInfoView .score {
  margin-left: 7%;
  max-width: calc(50% + 10px);
  display: inline-block;
  align-items: first baseline;
}

.certInfoView .fillInValue {
  line-height: 1.5em;
  font-size: 2em;
  font-weight: bold;
  height: 35px;
}

.certInfoView .header ~ h1,
.certInfoView .header ~ .h1 {
  font-size: 3vh !important;
}

.certInfoView .header .seperator {
  border-bottom: 3px solid #000;
  width: 90%;
  margin: 0 auto;
  height: 2%;
}

.certInfoView .fillInValue ~ .seperator {
  border-bottom: 2px solid grey;
  max-width: 70%;
  margin-left: 55px;
}

.certSuccessfulBg {
  width: 100%;
  height: 400px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  font-family: 'Verdana', 'Arial', 'sans-serif';
}

.certificateContainer .holder {
  width: 100%;
  margin-top: 20px;
  height: 100%;
}

.certificateContainer .certSuccessfulBg .messageText {
  display: flex;
  font-weight: bold;
  font-size: 2.5em;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 60px;
  font-family: 'Verdana', 'Arial', 'sans-serif';
}

.certificateView {
  padding-top: 25px;
  width: 70%;
  height: min-content !important;
}


.assignmentsHeader {
  margin: 0 auto !important;
}

.certSuccessfulBg div .buttons {
  display: flex;
  margin-top: 20px;
  padding-left: 15px;
}

.certSuccessfulBg div button {
  border: solid black 3px;
  font-weight: bold;
  background-color: #fff !important;
  color: #000 !important;
  margin-right: 1%;
  max-width: 200px;
  min-width: 130px;
  width: auto;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  padding: 0;
}

.assignmentsHeader > h2 {
  padding-left: 0px !important;
  font-family: 'Rockwell', 'Verdana', 'Arial', 'san-serif';
  text-wrap: nowrap;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
}
