#courseGroupList {
    margin-bottom: 5px;
    width: 100%;
    margin-top: 3px;   
    overflow: hidden;
}

#courseGroupList .col-md-12{
    background-color: #e5e5e5
}

#courseGroupList .courseGroupInfo {
   background-color: #f2f2f2;
   padding-left: 2rem;
}
#courseGroupList h3 {
    margin: 0px 0px 15px;
}

#courseGroupList .mainImg
{
    width: 100%;
}

#courseGroupList .assignment
{
    border-bottom: 6px solid #ffffff;
    padding-top: 5px;
    padding-bottom: 2rem;
}

#courseGroupList .dueDate{
    padding-left: 4rem;
    display: block;
    padding-bottom: 1.5rem;
}

#courseGroupList .description.short {
    padding-right: 1rem;
}


#courseGroupList .mainImg{
    padding-left: 2rem;

}

#courseGroupList .info
{
    padding-left: 2rem;

}

#courseGroupList .assignmentName{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 16px;
}

.arrow {
    display: none;
    position: absolute;
    bottom: -3px;
    width: calc(100% - 10px);
}

.assignment.rightColumn .arrow, .assignment.leftColumn .arrow {
    width: calc(100% - 5px);
}

.arrow .arrowTopBorder {
    height: 1.1rem;
    background-color: #e5e5e5;
}

.arrow .point {
    width: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
}

#courseGroupList .body {
    background-color: #f2f2f2;
    overflow: hidden;
    padding: 5px;
    padding-left: 10px;
}

.users .row .col-md-12 h4,
#courseGroupList .body h4 {
    margin: 0px;
}

#courseGroupList .body .info {

}

#courseGroupList .body .info .content {
    overflow: hidden;
    width: 300px;
    float: left;
    margin-right: 15px;
    position: relative;
}

#courseGroupList .body .info .content .mainImg {
    background-color: #bbb;
    float: left;
    margin-right: 15px;
    width: 300px;
}

#courseGroupList .closer {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    height: 30px;
    line-height: 27px;
    right: 15px;
    width: 30px;
}

#courseGroupList .body .info .infoHolder  {
    float: left;
    width: 100%;
}

#courseGroupList .offeringSlide {
    background-color: #fff;
    display: none;
    max-height: 120px;
    overflow: scroll;
    position: absolute;
    right: 10px;
    width: 50%;
    z-index: 100;
}

#courseGroupList .body .info .infoHolder .apiActionLoader {
    margin-bottom: 7px;
    overflow: hidden;
}

/* code, type and source on slide down */
.slideDownMeta {
    padding-left: 0px;
    padding-top: 4rem;
    color: black;
    font-size: 16px;
    list-style: none;
}

.dateBlock{

    display:block 
}

.slideDownMeta li {
    display: inline-block;
}

.slideDownMeta li.space {
    margin: 0px 15px;
}

.singlecourse .offerings {
    width: 100%;
}

/***** assignment list & catalog list pages *****/
/* Need some specific styling for these pages */
#assignmentCourseList #singlecourse {
    margin-left: calc(8.3% * 3);
    width: calc(100% - 8.3% * 3);
    padding: 0;
    margin-top: 0;
}
/* Hide the meta information on the single course slide down on these pages only */
#assignmentCourseList #singlecourse .slideDownMeta {
    display: none;
}
#assignmentCourseList #singlecourse .body {
    background-color: white;
}
#assignmentCourseList #singlecourse .body .description {
    padding-bottom: 1rem;
}

.ScheduleCourseModal .title {
    font-weight: bold;

}

.ScheduleCourseModal .modal .requestContainer
{
    width: 100%;
}

.ScheduleCourseModal .dateContainer {
    position: relative;
    overflow: hidden;
    display: flex;
}

.ScheduleCourseModal .actionHolder {
    margin-top: 1rem;
    overflow: visible;
    width: 100%;
}

.ScheduleCourseModal .RequestApprovalbtn
{
    padding: 1rem 3rem !important;
}

.ScheduleCourseModal .Schedulebtn
{
    padding: 1rem 6rem !important;
}


/* The course meta id/type/etc */
#courseGroupList .courseMeta {
    display: flex;
    justify-content: flex-begin;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
    flex-wrap: wrap;
}
/* Pipe bars between course meta items*/
#courseGroupList .courseMeta span:nth-last-child(n+2):after  {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

/* The course meta id/type/etc */
#courseGroupList .courseMetaManager {
    display: flex;
    justify-content: flex-begin;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
}
/* Pipe bars between course meta items*/
#courseGroupList .courseMetaSpan:nth-last-child(n+2):after e {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

#courseGroupList .singlecourse
{
    margin-left: 0px !important;
    width: 100% !important;
    border-bottom: 6px solid #ffffff;
}

#courseGroupList .space {
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

#courseGroupList .courseGroupInfoHolder {
    display: inline-flex;
}

#courseGroupList .resource {
    color: black;
    float: left;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 10px;
}

#assignmentCourseList #singlecourse .title {
    padding-right: 10px;
}

#courseGroupList .point{
    top: 30px;
    border-top-color: #e5e5e5;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-width: 40px;
    border-right-width: 200px;
    border-left-width: 200px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;

}

#courseGroupList .arrow {
    position: absolute;
    bottom: 0px !important;
    width: 20% !important;
    background-color: #ffffff !important;
}

#assignmentCourseList #courseGroupList .arrowTopBorder {
    background-color: #ffffff !important;
    height: 1.1rem;
}

#assignmentCourseList #courseGroupList .assignment{
    border-top: 0px solid #ffffff !important;
}