/**** Calendar view ****/

[class*="cal-cell"] {
    float: left;
    margin-left: 0;
    min-height: 1px;
}

.cal-row-fluid {
    width: 100%;
    *zoom: 1;
}

.cal-row-fluid:before,
.cal-row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
}

.cal-row-fluid:after {
    clear: both;
}
.cal-row-fluid [class*="cal-cell"] {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 0%;
    *margin-left: -0.05213764337851929%;
}
.cal-row-fluid [class*="cal-cell"]:first-child {
    margin-left: 0;
}
.cal-row-fluid .controls-row [class*="cal-cell"] + [class*="cal-cell"] {
    margin-left: 0%;
}
.cal-row-fluid .cal-cell7 {
    width: 100%;
    *width: 99.94669509594883%;
}
.cal-row-fluid .cal-cell6 {
    width: 85.71428571428571%;
    *width: 85.66098081023453%;
}
.cal-row-fluid .cal-cell5 {
    width: 71.42857142857142%;
    *width: 71.37526652452024%;
}
.cal-row-fluid .cal-cell4 {
    width: 57.14285714285714%;
    *width: 57.089552238805965%;
}
.cal-row-fluid .cal-cell3 {
    width: 42.857142857142854%;
    *width: 42.80383795309168%;
}
.cal-row-fluid .cal-cell2 {
    width: 28.57142857142857%;
    *width: 28.518123667377395%;
}
.cal-row-fluid .cal-cell1 {
    width: 14.285714285714285%;
    *width: 14.232409381663112%;
}
.cal-week-box .cal-offset7,
.cal-row-fluid .cal-offset7,
.cal-row-fluid .cal-offset7:first-child {
    margin-left: 100%;
    *margin-left: 99.89339019189765%;
}
.cal-week-box .cal-offset6,
.cal-row-fluid .cal-offset6,
.cal-row-fluid .cal-offset6:first-child {
    margin-left: 85.71428571428571%;
    *margin-left: 85.60767590618336%;
}
.cal-week-box .cal-offset5,
.cal-row-fluid .cal-offset5,
.cal-row-fluid .cal-offset5:first-child {
    margin-left: 71.42857142857142%;
    *margin-left: 71.32196162046907%;
}
.cal-week-box .cal-offset4,
.cal-row-fluid .cal-offset4,
.cal-row-fluid .cal-offset4:first-child {
    margin-left: 57.14285714285714%;
    *margin-left: 57.03624733475479%;
}
.cal-week-box .cal-offset3,
.cal-row-fluid .cal-offset3,
.cal-row-fluid .cal-offset3:first-child {
    margin-left: 42.857142857142854%;
    *margin-left: 42.750533049040506%;
}
.cal-week-box .cal-offset2,
.cal-row-fluid .cal-offset2,
.cal-row-fluid .cal-offset2:first-child {
    margin-left: 28.57142857142857%;
    *margin-left: 28.46481876332622%;
}
.cal-week-box .cal-offset1,
.cal-row-fluid .cal-offset1,
.cal-row-fluid .cal-offset1:first-child {
    margin-left: 14.285714285714285%;
    *margin-left: 14.17910447761194%;
}
.cal-row-fluid .cal-cell1 {
    width: 14.285714285714285%;
    *width: 14.233576642335766%;
}
[class*="cal-cell"].hide,
.cal-row-fluid [class*="cal-cell"].hide {
    display: none;
}
[class*="cal-cell"].pull-right,
.cal-row-fluid [class*="cal-cell"].pull-right {
    float: right;
}
.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
    min-height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cal-events-num {
    margin-top: 20px;
}
.cal-month-day {
    position: relative;
    display: block;
    width: 100%;
}
#cal-week-box {
    position: absolute;
    width: 70px;
    left: -71px;
    top: -1px;
    padding: 8px 5px;
    cursor: pointer;
}
#cal-day-tick {
    position: absolute;
    right: 50%;
    bottom: -21px;
    padding: 0px 5px;
    cursor: pointer;
    z-index: 5;
    text-align: center;
    width: 26px;
    margin-right: -17px;
}
.cal-year-box #cal-day-tick {
    margin-right: -7px;
}
#cal-slide-box {
    position: relative;
}
#cal-slide-tick {
    position: absolute;
    width: 16px;
    margin-left: -7px;
    height: 9px;
    top: -1px;
    z-index: 1;
}
#cal-slide-tick.tick-month1 {
    left: 12.5%;
}
#cal-slide-tick.tick-month2 {
    left: 37.5%;
}
#cal-slide-tick.tick-month3 {
    left: 62.5%;
}
#cal-slide-tick.tick-month4 {
    left: 87.5%;
}
#cal-slide-tick.tick-day1 {
    left: 7.14285714285715%;
}
#cal-slide-tick.tick-day2 {
    left: 21.42857142857143%;
}
#cal-slide-tick.tick-day3 {
    left: 35.71428571428572%;
}
#cal-slide-tick.tick-day4 {
    left: 50%;
}
#cal-slide-tick.tick-day5 {
    left: 64.2857142857143%;
}
#cal-slide-tick.tick-day6 {
    left: 78.57142857142859%;
}
#cal-slide-tick.tick-day7 {
    left: 92.85714285714285%;
}
#cal-slide-content ul.unstyled {
    margin-bottom: 0;
}
.cal-week-box {
    position: relative;
}
.cal-week-box [data-event-class] {
    white-space: nowrap;
    height: 30px;
    margin: 1px 1px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 10px;
}
.cal-week-box .cal-column {
    position: absolute;
    height: 100%;
    z-index: -1;
}
.cal-week-box .arrow-before,
.cal-week-box .arrow-after {
    position: relative;
}
.cal-week-box .arrow-after:after {
    content: "";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    right: 0;
    border-top: 15px solid #ffffff;
    border-left: 8px solid;
    border-bottom: 15px solid #FFFFFF;
}
.cal-week-box .arrow-before:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 0;
    height: 0;
    left: 1px;
    border-top: 15px solid transparent;
    border-left: 8px solid #FFFFFF;
    border-bottom: 15px solid transparent;
}
#cal-day-box {
    text-wrap: none;
}
#cal-day-box .cal-day-hour-part {
    height: 30px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: thin dashed #e1e1e1;
}
#cal-day-box .cal-day-hour .day-highlight {
    height: 30px;
}
#cal-day-box .cal-hours {
    font-weight: bolder;
}
#cal-day-box .cal-day-hour:nth-child(odd) {
    background-color: #fafafa;
}
#cal-day-box #cal-day-panel {
    position: relative;
    padding-left: 60px;
}
#cal-day-box #cal-day-panel-hour {
    position: absolute;
    width: 100%;
    margin-left: -60px;
}
#cal-day-box .day-event {
    position: relative;
    max-width: 200px;
    overflow: hidden;
}
#cal-day-box .day-highlight {
    line-height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #c3c3c3;
    margin: 1px 1px;
    overflow: hidden;
    text-overflow: ellipsis;
}
#cal-day-box .day-highlight.dh-event-important {
    border: 1px solid #ad2121;
}
#cal-day-box .day-highlight.dh-event-warning {
    border: 1px solid #e3bc08;
}
#cal-day-box .day-highlight.dh-event-info {
    border: 1px solid #1e90ff;
}
#cal-day-box .day-highlight.dh-event-inverse {
    border: 1px solid #1b1b1b;
}
#cal-day-box .day-highlight.dh-event-success {
    border: 1px solid #006400;
}
#cal-day-box .day-highlight.dh-event-special {
    background-color: #ffe6ff;
    border: 1px solid #800080;
}
.event {
    display: block;
    background-color: #c3c3c3;
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-bottom: 2px;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    border: 1px solid #ffffff;
}
.event-block {
    display: block;
    background-color: #c3c3c3;
    width: 20px;
    height: 100%;
}
.cal-event-list .event.pull-left {
    margin-top: 3px;
}
.event-important {
    background-color: #ad2121;
}
.event-info {
    background-color: #1e90ff;
}
.event-warning {
    background-color: #e3bc08;
}
.event-inverse {
    background-color: #1b1b1b;
}
.event-success {
    background-color: #006400;
}
.event-special {
    background-color: #800080;
}
.day-highlight {
    background-color: #dddddd;
}
.day-highlight.dh-event-important {
    background-color: #fae3e3;
}
.day-highlight.dh-event-warning {
    background-color: #fdf1ba;
}
.day-highlight.dh-event-info {
    background-color: #d1e8ff;
}
.day-highlight.dh-event-inverse {
    background-color: #c1c1c1;
}
.day-highlight.dh-event-success {
    background-color: #caffca;
}
.day-highlight.dh-event-special {
    background-color: #ffe6ff;
}
.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
    text-align: center;
    border: 0px solid;
    padding: 5px 0;
}
.cal-row-head [class*="cal-cell"] small {
    font-weight: normal;
}

.cal-month-day {
    height: 100px;
}
[class*="cal-cell"]:hover {
    background-color: #ededed;
}
.cal-row-fluid .week .cal-cell1.cal-cell,
.cal-row-fluid .week .cal-cell1.cal-cell:hover {
    background-color: #fff;
    color: #000;
}

.cal-year-box [class*="span"],
.cal-month-box [class*="cal-cell"] {
    min-height: 13vh; /* was 100px sean neilan */
    border-right: 1px solid #e1e1e1;
    position: relative;
}
.cal-year-box [class*="span"] {
    min-height: 60px;
}
.cal-year-box .row-fluid [class*="span"]:last-child,
.cal-month-box .cal-row-fluid [class*="cal-cell"]:last-child {
    border-right: 0px;
}
.cal-year-box .row-fluid,
.cal-month-box .cal-row-fluid {
    border-bottom: 1px solid #e1e1e1;
    margin-left: 0px;
    margin-right: 0px;
}
.cal-year-box .row-fluid:last-child,
.cal-month-box .cal-row-fluid:last-child {
    border-bottom: 0px;
}
.cal-month-box,
.cal-year-box,
.cal-week-box {
    border-bottom: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-radius: 2px;
}
span[data-cal-date] {
    font-size: 1.2em;
    font-weight: normal;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    margin-top: 15px;
    margin-right: 15px;
}
span[data-cal-date]:hover {
    opacity: 1;
}
.cal-day-outmonth span[data-cal-date] {
    opacity: 0.1;
    cursor: default;
}
.cal-day-today {
    background-color: #e8fde7;
}
.cal-day-today span[data-cal-date] {
    color: darkgreen;
}
.cal-month-box .cal-day-today span[data-cal-date] {
    font-size: 1.9em;
}
.cal-day-holiday span[data-cal-date] {
    color: #800080;
}
.cal-day-weekend span[data-cal-date] {
    color: darkred;
}
#cal-week-box {
    border: 1px solid #e1e1e1;
    border-right: 0px;
    border-radius: 5px 0 0 5px;
    background-color: #fafafa;
    text-align: right;
}
#cal-day-tick {
    border: 1px solid #e1e1e1;
    border-top: 0px solid;
    border-radius: 0 0 5px 5px;
    background-color: #ededed;
    text-align: center;
}
#cal-slide-box {
    border-top: 0px solid #8c8c8c;
}
#cal-slide-content {
    padding: 20px;
    color: #ffffff;
    background-image: url("../img/dark_wood.png");
    -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}
#cal-slide-tick {
    background-image: url("../img/tick.png?2");
}
#cal-slide-content:hover {
    background-color: transparent;
}
#cal-slide-content a.event-item {
    color: #ffffff;
    font-weight: normal;
    line-height: 22px;
}
.cal-column {
    border-left: 1px solid #e1e1e1;
}

a.cal-event-week {
    text-decoration: none;
    color: #151515;
}

.border-box {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.border-box {
     box-sizing: border-box;
     -moz-box-sizing: border-box;
}

/**** Calendar related layout stuff ****/
/* layout for the calendar and todo list*/
.innerCalendarContainer {
    display: flex;
}

#courseCalendarHolder {
    width: 60%;
}

.calendar {
    float: left;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.calendar div.header {
    float: left;
    width: 100%;
    background: white !important;
    height: 40px;
    color: white;
}

.calendar div.header * {
    height: 40px;
    line-height: 40px !important;
    display: inline-block;
    float: left;
    vertical-align: middle;
}

.calendar div.header i {
    float: left;
    width: 40px;
    font-size: 1.125em;
    font-weight: bold;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 0 11%;
    cursor: pointer;
}

.calendar div.header i.fa-angle-left {
    text-align: left;
}

.calendar div.header i.fa-angle-right {
    text-align: right;
}

.calendar div.header div {
    float: left;
    width: 100%;
    font-weight: bold;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding-left: 50px;
    padding-right: 40px;
    color: black;
}

/* Center the month selector */
.calendar div.header div.nav {
    display: flex;
    justify-content: center;
}

/* Make the month name at the top huge */
.calendar div.header div.nav span {
    font-size: 2rem;
}

.calendar div.header .filtering {
    float: right;
    width: 30%;
}

.calendar div.header .filtering span {
    display: block;
    float: right;
    margin-right: 15px;
}

/* Calendar row styling */
.calendar .cal-month-box div.week {
    border-right: solid 1px #e1e1e1;
    border-left: solid 1px #e1e1e1;
}
.calendar div.week {
    float: left;
    width: 100%;
    border-top: solid 1px #e1e1e1;
    border-bottom: none;
}
.calendar div.week:first-child {
    border-top: none;
}
.calendar div.week:last-child {
    border-bottom: solid 1px #e1e1e1;
}
.calendar div.week .cal-cell.day:first-child {
    border-left: none;
}
.calendar div.week > .day.today h5 {
    color: #1EABE3;
    font-weight: bold
}
.calendar div.week .cal-cell.day.different-month {
    color: #C0C0C0;
}

/* If you select a different month, the gray text will look bad
 * on most selectors, so, change it to black. this gets changed to white in the css.js service if the portalBackground color is too dark */
.calendar div.week .cal-cell.day.different-month.selected {
    color: black;
}
.calendar div.week .cal-cell.day.selected {
    background: #e5e5e5;
    outline: none;
}
.calendar div.week.names div {
    color: #2875c7;
}

.calendar .calendarList .h3 a {
    font-size: 24px;
}

/* Individual cell layout */
.calendar div.week .cal-cell.day {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0rem;
    padding-left: .5rem;
    padding-top: .7rem;
    padding-right: .5rem;
}


/* upper right todo bubble count */
.calendar div.week .todoCount {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 3rem;
    height: 3rem;
    background-color: #1EABE3;
    border-radius: 10rem;
    color: white;
    font-weight: bold;
    z-index: 1;
    line-height: 30px;
    -webkit-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.33);
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.33);
}

/* Individual cell styling */
.calendar .calendarCellItem {
    text-align: left;
    font-weight: bold;
}
.calendar .calendarCellItem span {
    text-align: left;
    font-weight: bold;
    display: block;
    overflow: hidden;
    height: 5vh;
}

.calendar div.week > .day {
    float: left;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: center;
    height: 30px;
    line-height: 30px !important;
    display: inline-block;
    vertical-align: middle;
    background: white;
    cursor: pointer;
    color: black;
}

.calendar div.week > .day .content p {
    margin: 0;
    padding: 0px 0 3px 7px;
    line-height: 16px;
    font-size: 12px;
}

.calendar div.week > .day h5 {
    margin: 3px;
    text-align: left;
}


/**** Assignments list ****/
/* Make sure the assignments list floats to the right */
#courseCalendarAssignmentHolder {
    /* Stored in calendar.html */
    float: right;
    display: flex;
    width: 40%;
    margin-left: 1rem;
}

#calendarAssignmentsList {
    /* Background */
    background-color: #e5e5e5;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    overflow-y: scroll;
    width: 100%;
}

#calendarAssignmentsList h2 {
    color: black !important;
    font-size: 2rem;
    text-align: center;
    font-family: sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

#calendarAssignmentsList h3.dueDate {
    color: black !important;
    font-size: 4rem;
    text-align: center;
    font-family: sans-serif;
    font-weight: bold;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 3rem;

    /* Technical debt from a similar
    class called .dueDate in another file */
    position: initial;
    top: 0;
}

/* list style layout */
ul.todoList {
    list-style: none;
    padding-left: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
}

ul.todoList li {
    display: flex;
    justify-content: space-between;
}

/* Assignment status styling */
ul.todoList li span.status {
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
    width: 23%;
}

/* Assignment title */
ul.todoList li .title {
    color: black;
    font-size: 16px;

    /* this combo makes it so we have a nice border on the left side */
    border-left: 1px solid #cccccc;
    padding-bottom: 30px;

    padding-left: 5%;
    font-weight: bold;
    width: 75%;
}

/* ILT Schedule offering */
ul.todoList li a.schedule {
    font-size: 12px;
    display: block;
    margin-top: 7px;
}

ul.todoList li .sessions {
    padding: 7px 0px 0px 15px;
}

ul.todoList li .sessions li {
    display: block;
}

ul.todoList li .sessions li p {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
}

#calendarAssignmentsList .offeringDetailsTitle 
{
    color: black;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
}

#calendarAssignmentsList .offeringDetailsValue 
{
    color: black;
    font-size: 14px;
    width: 75%;
    font-weight: normal;
}

#calendarAssignmentsList .dateList {
    column-gap: 3px;
}

#calendarAssignmentsList .dateList .offeringDetailsTitle{
        width: auto;
 }

