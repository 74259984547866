/* We're gonna be ripping out these pieces that are tied to bootstrap clases.
.row.header {
    margin-top: 15px;
}
*/
/* Replacing it with an id */
#header {
    margin-top: 15px;
}

#header .navbar-brand {
    height: inherit;
    padding: 0px;
}

/* Ensure we don't override the logo from custom css, as style below is more specific. */
#logo {
    background-image: url('../../app/images/BAI_HorizontalLockup_Primary_CMYK.png');
    background-position: left center;
    background-repeat: no-repeat;
}

#BAIOnlylogo {
    background-image: url('../../app/images/BAI_HorizontalLockup_Primary_CMYK.png');
    background-position: left center;
    background-repeat: no-repeat;
    min-height: 88px;
    margin: 0px 0px 15px;
    padding: 0px;
    text-indent: -99999px;
    background-size: contain;
    min-width: 75%;
    float: left;
}

#header #logo {
    min-height: 88px;
    margin: 0px 0px 15px;
    padding: 0px;
    text-indent: -99999px;
    text-indent: -99999px;
    background-size: contain;
    min-width: 75%;
    float: left;
}

.row.header > .col-md-3 .logout {
    text-align: right;
    position: relative;
}

.row.header > .col-md-3 .logout a {
    cursor: pointer;
    font-size: 12px;
}

.row.header > .col-md-12 .secondaryNav {
    list-style: none;
    margin: 0 auto 15px;
    overflow: hidden;
    padding: 0px;
    /* width: 50%; */
    display: flex;
}

.row.header > .col-md-12 .secondaryNav li {
    display: inline;
    float: right;
    text-align: center;
    width: 33%;
}

/* Search box */
#search {
    background-color: white !important;
    border: gray 1px solid;
    color: black;
    border-radius: initial;
    align-self: center;
}

form.searchForm {
    margin-right: -7px;
    padding-right: 0;
    padding-top: 5px;
}

#search::-webkit-input-placeholder {
    color: #a0a0a0;
}

#search:-moz-placeholder { /* Firefox 18- */
    color: #fff;
}

#search::-moz-placeholder {  /* Firefox 19+ */
    color: #fff;
}

#search:-ms-input-placeholder {
    color: #fff;
}

#searchContainer {
    display: flex;
}


#searchContainer .icon-search {
    font-size: 28px;
    padding-left: 15px;
    padding-right: 10px;
}

/* Top right name & logout area */
.logoutAndSearchForm {
    top: 74px;
}

a.logout-text {
    font-weight: bold;
    text-decoration: underline;
}

.lmgrLoginLink{
    float:right;
    color:#1EABE3 !important;
    padding-bottom:10px;
}

.hello-text {
    color: black;
    font-size: 12px;
    font-weight: bold;
}

.hello-text a {
    font-size: 12px;
    color: black;
}

.logout-pipe {
    color: black;
    margin-left: .8rem;
    margin-right: .8rem;
}

/* Breadcrumbs */
ul.breadcrumb {
    clear: both;
    background-color: #e5e5e5;
    height: 36px;
    padding-top: 1rem;
    padding-left: 2.5rem;
    padding-bottom: 1rem;

    /* yet another bootstrap override */
    margin-bottom: 0;
}

ul.breadcrumb li , ul.breadcrumb li a {
    color: black;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    text-decoration: none;
}

ul.breadcrumb li.active {
    font-weight: bold;
    color: black;
    padding-left: 4px;
}

ul.breadcrumb>li+li:before {
    color:black;
    margin-left: 4px;
    margin-right: -4px;
}

ul.breadcrumb>li+li.active:before {
    font-weight: normal;
    margin-right: -5px;
    margin-left: -1px;
}

/* Breadcrumbs New - override the existing css when necessary
        then move these into the breadcrumb css above */
ul.breadcrumb-new {
    clear: both;
    background-color: white;
    height: 36px;
    padding-top: 1rem;
    padding-left: 2.5rem;
    padding-bottom: 1rem;

    /* yet another bootstrap override */
    margin-bottom: 0;
}

ul.breadcrumb-new li , ul.breadcrumb-new li a {
    color: black;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    text-decoration: none;
}

ul.breadcrumb-new li a {
    text-decoration: underline;
    color: #1EABE3;
}

ul.breadcrumb-new li.active {
    font-weight: normal;
    color: black;
    padding-left: 4px;
}

ul.breadcrumb-new>li+li:before {
    color:black;
    margin-left: 4px;
    margin-right: -4px;
}

ul.breadcrumb-new>li+li.active:before {
    font-weight: normal;
    margin-right: -5px;
    margin-left: -1px;
    content: ' \003E' !important;
}

/**** Header used on all pages ****/
.assignmentsHeader {
    border-bottom: 2px solid black;
    padding-bottom: 1rem;
}

div.users .assignmentsHeader h2 {
    padding-left: 2rem;
}

#header .chromeWarning {
    border: 2px solid #ffb600;
    color: #ffb600;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}

#header .chromeWarning .title {
    font-weight: bold;
    font-size: 24px;
}

#header .chromeWarning .body {
    padding-top: .5rem;
    font-size: 12px;
    color: black;
}

.capitalized {
    text-transform: capitalize;
}

.header-banner-logo {
    width: 12em;
    max-height: 7em;
    object-fit: contain;
    height: 100%;
}

.header-items-layout {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    border-bottom: 1px solid #B7B7B7;
}

.header-menu-container {
    flex-grow: 0;
    flex-shrink: 1;
    align-self: flex-end;
}

.header-logo-container {
    flex-grow: 0;
    flex-wrap: nowrap;
    align-self: flex-end;
    margin-bottom: 10px;
}

.header-catalog-header-container {
    flex: 1;
    flex-wrap: inherit;
    align-self: flex-end;
}

#logout-link {
    flex: 0;
    flex-wrap: inherit;
    align-self: flex-end;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 5px;
    
}

#logout-link > a {
    font-size: 14px;
}