.modal {
    width: auto;
}

.modal-backdrop {
    background-color: #fff;
}

.modal-backdrop.in {
    filter: alpha(opacity=90);
    opacity: .9;
}

.modal-header {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 15px;
}

/* Modal title */
.modal .modal-title {
    font-weight: bold;
    font-size: 20px;
    margin: 0px 20px 0 0px;
    word-wrap: break-word;
}

.modal .closer {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: black;
    font-weight: normal;
    font-size: 18px;
    height: 20px;
    line-height: 17px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 15px;
    width: 20px;
}

.modal .modal-dialog {
    max-width: 1000px;
}

.modal .modal-dialog .modal-content {
    box-shadow: none;
    border: none;
    background-color: #e5e5e5;
    border-radius: 0;
    width: 100%;
}

/* Modal body */
.modal .modal-dialog .modal-content .modal-body {
    padding: 0px 15px 15px;
    overflow: hidden;
    width: 100%;
}

/*.*/

.modal .modal-body form {
    margin-top: 0;
}

.modal .modal-body .navbar-form {
    width: 100%;
}

.modal .modal-body .navbar-form .form-group {
    margin-bottom: 15px;
}

.modal .modal-body .navbar-form .form-group,
.modal .modal-body .navbar-form .form-group label,
.modal .modal-body .navbar-form .form-group .input-group {
    width: 100%;
}

/* Remove css garbage on modal inputs from other parts of app */
.modal .modal-body input[type='text'],
.modal .modal-body textarea,
.modal .modal-body md-datepicker-input,
.modal .modal-body input[type='number'] {
    border-radius: 0;
    border: 1px solid black;
    width: 100%;
}
/* color the default text in the modal input boxes */
.modal .modal-body input[type='text']::-webkit-input-placeholder,
.modal .modal-body textarea::-webkit-input-placeholder,
.modal .modal-body .md-datepicker-input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: black;
}
.modal .modal-body input[type='text']:-moz-placeholder,
.modal .modal-body textarea:-moz-placeholder,
.modal .modal-body .md-datepicker-input:-moz-placeholder {
   /* Mozilla Firefox 4 to 18 */
   color: black;
   opacity:  1;
}
.modal .modal-body input[type='text']::-moz-placeholder,
.modal .modal-body textarea::-moz-placeholder,
.modal .modal-body .md-datepicker-input::-moz-placeholder {
   /* Mozilla Firefox 19+ */
   color: black;
   opacity:  1;
}
.modal .modal-body input[type='text']:-ms-input-placeholder,
.modal .modal-body textarea:-ms-input-placeholder,
.modal .modal-body .md-datepicker-input:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   color: black;
}


/**** Certificate modal ****/
#certificateModal .modal-dialog .modal-content {
    height: 100%;
}

#certificateModal .modal-header p {
    right: 50px;
    top: 15px;
    position: absolute;
    font-size: 15px;
    cursor: pointer;
}

#certificateModal .modal-body {
    height: 94%;
    width: 100%;
}

#launcherModal .modal-dialog .modal-content {
    height: 100%;
}

/**** Welcome video modal ****/
#welcomeVideoModal {
    margin: 0 auto;
    width: 800px;
}

#welcomeVideoModal .row:first-child {
    margin-bottom: 15px;
}

#welcomeVideoModal .row .checkbox {
    float: left;
    margin-right: 15px;
}

