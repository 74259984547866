@media print {
    #courseCert.certInfo {
        width: 100%;
    }

    .courseCertNMLS {
        width:100%;
    }
    .courseCertNMLS .name {
        font-size: 20px !important;
        top: 82% !important; 
    }

    .courseCertNMLS .courseName {
        font-size: 30px !important;
        top: 54% !important;
        color: #228FC3;
    }

    .courseCertNMLS .completionDate {
        font-size: 20px !important;
        top: 82% !important; 
        font-weight: normal;
    }

    .courseCertNMLS .nmlsID {
        font-size: 20px;
        top: 82%; 
        color: #228FC3;
        left: 250px;
        text-align: center;
        width: 100%;
    }

    .courseCertNMLS .courseCode
    {
        font-size: 20px;
        top: 70%; 
        color: #228FC3;
        left: 0px;
        text-align: center;
        width: 100%;
    }
}