
.student-inner-header {
    font-weight: 600;
    font-family: Rockwell,Verdana,Arial,sans-serif;
    color: #00375D;
    font-size: 16px;
    padding-left: 0.2em;
    margin-top: 0px;
    margin-bottom: 1em;
}

.student-list-image {
    width: 95%;
}

.cp-electives-outer {
    border: 1px solid #809BAF;
    padding-top: 1.5em;
    padding-bottom: 15px;
    padding-left: 1.5%;
}

.cp-name {
    font-weight: 600;
    font-family: Verdana,Arial,sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
}

.cp-description {
    font-size: 14px;
    margin-bottom: 10px;
}

.cp-summary {
    font-size: 12px;
}

.cp-show-list {
    text-align: center;
    margin-top: 5px;
    width: 95%;
}

.cp-show-list a {
    color: #00a1de;
    font-size: 12px;
    padding-right: 15px;
}

.cp-show-list a {
    color: #00a1de;
    font-size: 12px;
    padding-right: 15px;
    text-decoration: none;
}

.cp-show-list a:hover {
    text-decoration: underline;
}

#no-career-paths-msg {
    font-weight: 600;
    font-family: Verdana,Arial,sans-serif;
    color: #00375D;
    font-size: 14px;
    text-align: center;
    padding: 25px;
}

.cp-electives-outer .row {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 15px;
}

.cp-electives-outer hr {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0px;

}

student-career-path-list .infoSection{
    margin-left:10px;
    margin-bottom: 4px;
    color: black;
    font-size: 14px;
    list-style: none;
    padding-top:2rem;
}

student-career-path-list .descriptionSection{
    padding-left: 0px;
    margin-left:10px;
    margin-bottom:10px;
    flex:1;
}

student-career-path-list .infoSection.topLevel{
    font-size:14px;
    padding-top: 2rem;
    padding-bottom:2rem;
}


student-career-path-list .courseGroupContainer .mainImg {
    width: 100%;
}
student-career-path-list .courseGroupContainer .info {
    padding-left: 1rem;
}

student-career-path-list .courseGroupContainer .assignmentName {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 14px;
}
student-career-path-list .courseGroupContainer h3 {
    margin: 0px 0px 15px;
}

student-career-path-list .courseGroupContainer.courseMeta {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
    flex-wrap: wrap;
}

student-career-path-list .courseGroupContainer .courseMetaContainer{
    margin-bottom:4rem;
}

student-career-path-list .courseGroupContainer .imageDate{
    padding-left:0px;
    padding-right:0px;
}

student-career-path-list .courseGroupContainer .courseMeta span:nth-last-child(n+2):after  {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

student-career-path-list .courseGroupContainer .title{
        margin-left: 15px;
        padding-left: 0px;
        width:70%;
}

student-career-path-list .detailsTopContainer{
    width:100%;
}

student-career-path-list .detailsTopContainer.topLevel{
 border:1px solid #00375d;
 }
   
student-career-path-list .courseGroupScroll {
    margin-left: 1%;
    width: 98%;
    flex:1;
}

student-career-path-list .careerPathScroll{
    width:100%;
    margin-top:2rem;
}