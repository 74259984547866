/**** Instructor page ****/

.instructor-filters {
    margin: 20px 0 57px 20px;
}

.instructor-filters a.selected {
     font-weight: 600;
}

.instructor-filters a.choice {
    color: #232021;
    font-size: 14px;
}

.instructor-filter-by {
    color: #232021;
    font-size: 14px;
    font-weight: 600;
    padding-right: 20px;
}

.instructor-offering-container img {
    margin: 12px 0;
    width: 100%;
}

.instructor-offering-detail-container {
    font-size: 12px;
    margin: 35px 0 24px 0;
}

.instructor-offering-detail-half div {
    margin-bottom: 2px;
}

.instructor-offering-detail-half.col-md-7,
.instructor-offering-detail-half.col-md-5 {
    padding: 0px;
}

.instructor-offering-detail-type {
    display: inline-block;
    font-weight: 600;
    width: 90px;
}
.semi-b  {
    font-weight: 600;
}

.instructor-offering {
    margin-right: 10px;
    padding: 15px 10px 17px 10px;
    width: 100%;
}
.instructor-offering-location {
    position: relative;
    top: -3px;
}

.instructor-title-text {
    font-size: 16px;
    font-weight: 600;
}
.instructor-date-text {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.tile .instructor-date-text img {
    float: right;
    height: 20px;
    margin: 0px;
    width: 20px;
}

.pipe {
    color: #232021;
    padding: 0 15px;
}

.filterBy .filterItem + .filterItem:before {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}