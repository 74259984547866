
#bioSigModal .modal-body {

    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    width: 750px;
}

#bioSigModal .bioSigBtn {
    margin-top: 1rem;
    float: right;
}

#bioSigModal .modal-content {
    height: 100%;
}

#bioSigModal .modal-dialog {
    width: 750px;
    margin-top: 50px;
}

#bioSigModal .nmlsEmail{
    padding-top:10px;
}

#bioSigModal .modal-title {
    padding-left: 15px;
}

#bioSigModal input {
    border-radius: 0;
    border: 1px solid black;
    width: 100%;
}

#bioSigModal .bioSigFrame {
    height: 700px;
    width: 720px;
}

#bioSigModal .bioSigHelp {
    margin-left: 87%;
    position: absolute;
    right: 55px;
    text-align: center;
    top: 10px;
}

#bioSigModal .modal-header {
    padding-bottom: 25px;
}

.bioSigModal .alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
}

.bioSigModal .alert {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
}