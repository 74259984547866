/*Vertical Resizer = Vertical resizer to drag left and right
Horizontal Resizer  = Horizontal resizer to drag top and bottom*/

/*Below CSS stuff for the Vertical resizer, which are in action*/

#sidebar {
    left: 0;
    width: calc(25%);
    min-width: 200px;
}

#content {
    bottom: 0;
    right: 0;
    overflow: visible;
}

#sidebar-resizer {
    background-color: #e0e0e0;
    position: absolute;
    border-radius: 5px;
    top: 0px;
    bottom: 0;
    left: calc(25% - 3px);
    width: 5px;
    cursor: col-resize;
}

#sidebar-resizer:hover, #preview-resizer:hover {
    background-color: #c1c1c1;
}

/*In future if need to do horizontal resizer., then use below classes*/

/*#topnav {
    background-color: #333333;
    display: block;
    height: 35px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #DDD;
}*/

/*#top-content {
    position: absolute;
    top: 0;
    bottom: 136px;  130 + 6 
    left: 0;
    right: 0;
    background-color: #444;
    overflow: auto;
}*/

/*#bottom-content {
    position: absolute;
    height: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: #777;
}*/

/*#content-resizer {
    position: absolute;
    height: 5px;
    bottom: 130px;
    left: 0;
    right: 0;
    background-color: #666;
    cursor: n-resize;
}*/

