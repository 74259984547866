div#student-no-career-paths {
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    background-color: #00375D;
    padding-bottom: 20px;
    padding-top: 20px;
}

div#student-no-career-paths .message-container {
    flex-wrap: wrap;
    align-self: center;
}

div#student-no-career-paths .message-title {
    color: white;
    font-weight: bold;
    font-size: 22px;
    align-self: center;
}

div#student-no-career-paths .message-link {
    color: white;
    font-size: 14px;
    align-self: center;
}

div#student-no-career-paths>img {
    height: 7.5em;
}
