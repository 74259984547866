@keyframes slideOutLeft {
    to      { transform: translateX(-100%); }
}

@keyframes slideInRight {
    from    { transform:translateX(100%); }
    to      { transform: translateX(0); }
}

.animatable.ng-enter {
    animation: slideInRight 1s both ease-in;
    z-index: 8888;
}
.animatable.ng-leave {
    animation: slideOutLeft 1s both ease-in;
    z-index: 9999;
}
