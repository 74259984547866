/**** Documents Catalog tile page stuff ****/
/* /lms-web/catalog/ & */
.catalog .assignmentsHeader {
    margin-bottom: 30px;
}

.catalog .row.catalogBody {
    margin-left: -20px;
    margin-right: -20px;
}

/**** Subcategory styling. (The list page under each category tile) ****/
/* Ex: lms-web/catalog/view/1025/ */

.catalogList .assignmentsHeader h2,
#documents .assignmentsHeader h2 {
    padding-left: 3rem;
}

.catalog .assignmentsHeader img.icon {
    display: block;
    height: 60px;
    margin-left: 2rem;
    position: relative;
    top: 15px;
    width: 60px;
}

/* body */
#subcategory .row.subcategoryBody,
#documentsSubcategory .row.documentsBody {
    margin-right: -30px;
    margin-left: -30px;
}

/* Make sure the accordion rows are nice and tight */
.col-md-12 .panel-group .panel .panel {
    margin-top: .8rem;
}

.catalogList .panel-group .panel + .panel {
    margin-top: .8rem;
}

.catalogList .panel-group {
    margin-bottom: 0;
    margin-top: .8rem;
}

.catalogList .panel-body {
    padding-left: 7.5rem;
}
/* End accordion row styling */

/* The two header levels in the document/catalog accordion */
.col-md-12 .panel-group .panel .panel-heading .accordion-toggle {
    font-weight: bold;
}

/* the lowest level links in the accordion */
#subcategory .childLink {
    padding-left: 2.5rem;
    margin-top: .8rem;
}

#subcategory .childLink a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

#subcategory .childLink a:hover {
    text-decoration: underline;
}

/* Plus minus icon on document & category list pages */
.col-md-12 .panel-group .panel .panel-heading .accordion-toggle .plusMinus {
    color: #1EABE3;
    width: 15px;
    margin-right: 15px;
    display: inline-block;
}


/**** Category list page 
 * This page is right below the accordion page. You get there by clicking on of the accordion links
 * ****/

/* Turn the description into a preview 
Make the short version of the description have a line wrapping. */
#subcategory p.description.short  {
    overflow: hidden;    
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* Hide the launch button in the slide down on the list page */
#subcategory .hideOnListPages {
    display: none;
}

/**** UNCATEGORIZED CSS CLASSES ****/
.col-md-12 .panel-group .panel {
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

.col-md-12 .panel-group .panel .panel-heading {
    background: none;
    background-color: #E5E5E5;
}

.col-md-12 .panel-group .panel .panel-heading .accordion-toggle {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}

.col-md-12 .panel-group .panel .panel-collapse .panel-body {
    border: none;
    padding: 0px;
}

.catalogList .col-md-12 .panel-group .panel .panel-collapse .panel-body {
    padding-left: 30px;
}

.col-md-12 .panel-group .panel .panel-collapse .panel-body .children h3 a {
    font-size: 42px;
}

.catalog .col-md-4 {
    padding: 5px;
}

#catalog .col-md-4 .background {
    height: 200px;
    width: 100%;
}

.catalog .col-md-4 .info {
    bottom: 0;
    height: 100%;
    position: absolute;
    width: 100%;
}

.catalog .col-md-4 .info h3 {
    color: #fff;
    margin: 15px auto;
    text-align: center;
    width: 74%;
    font-size: 16px;
    font-weight: bold;
}

.catalogList .panel-group .childLink {
    background-color: #f2f2f2;
    padding: 7px 15px;
}

.catalog .col-md-4 img.icon {
    height: 44%;
    margin: 25px auto 0;
    width: 20%;
}

/* Only catalog header should have
 * a padding-left of 1rem 
 * because it's the only document/category
 * page without an icon to the left of the
 * header */
#catalog .assignmentsHeader h2 {
    padding-left: 1rem;
}

.web .button {
    text-decoration: none;
    background: #fff;
    color: #00a1de;
    outline: 0;
    display: inline-block;
    padding: 0.1em 2em;
    border: 2px solid #00a1de;
    border-radius: 7px;
    font-family: "Calibre";
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.web .button:active, .button:hover {
    outline: 0;
    background-color: #00a1de;
    color: #fff;
}

.web .BAIiconLinks {
    margin:10px;
    width: 50px;
    height: auto;
    display:block;
    margin-left:25%;
}

.web hr {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #0076a8;
    height: 1px;
}

.web h2 {    
    color: #0076a8;
}

.web .buttonDiv {
    float:right;
    padding:20px;
}

.web .bluelink{
    color:#00a1de;
    font-size:14px;
}

.web .productFeatures {
    margin-top:20px;
}

.web .productAnnouncements {
    padding-bottom:20px;
}

.web .subHeader {
    color:#00a3de;
    font-weight:bold;
    padding-bottom:5px;
}

.web ul {
  list-style: none; /* Remove default bullets */
  margin-left:-25px;
}

.web ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #0076a8; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

#downloadCatalogLinks .catalogDownload {
    color:#1EABE3;
    text-decoration:none;
    font-size:1.25em;
    border: none;
    background-color: white;
    float:right;    
    margin-top: -10px;
    margin-bottom: -10px;
}