#overdue-banner {
  font-size: 2em;
  width: 100vw;
  background-color: whitesmoke;
  border-bottom: 1px solid black;
  margin-left: -15px;
  margin-right: -15px;
  font-family: Calibri, sans-serif;
}

#overdue-banner .warning-icon {
  color: white;
}

#overdue-banner p {
  font-weight: bold;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  line-height: 17.15px;
}

#overdue-banner span {
  font-weight: normal;
}

#overdue-banner table {
  margin-left: 40px;
  margin-bottom: 20px;
}

#overdue-banner table #header {
  border-bottom: 1px solid black;
}

#overdue-banner table th,
#overdue-banner table td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  line-height: 17.15px;
}

#overdue-banner table .padding-right {
  padding-right: 80px;
}

#overdue-banner a {
  font-size: 14px;
}

#overdue-banner h3 {
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  text-align: center;
  background-color: rgb(231, 68, 68);
  color: white;
  font-size: 28px;
}

#overdue-banner #error {
  margin-right: 40px;
  margin-left: 20px;
}

#overdue-banner #overdue-total {
  border-top-style: solid;
  border-top-width: 1px;
  font-weight: bold;
}
