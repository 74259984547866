.certificate {
    background-image: url('../../app/images/certComp.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    min-height: 900px;
    width: 100%;
}

.certificate,
.certificate h1,
.certificate h2,
.certificate h3 {
    width: 100%;
}

.certificate h1,
.certificate h2,
.certificate h3 {
    color: #000;
    position: absolute;
    text-align: center;
}

.certificate h1 {
    top: 19%;
    font-size: 42px;
    font-weight: bold;
}

.certificate h2 {
    top: 43%;
    font-size: 32px;
}

.certificate h3 {
    bottom: 29%;
    font-size: 22px;
}