.messaging {
    margin-bottom: 15px;
}

/** Error Styling **/
.messaging .error {
    margin-top: 2rem;
}

.messaging .error header {
    border-bottom: 2px solid #ff3500;
    padding-top: 1rem;
    padding-bottom: 1.1rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 32px;
    font-weight: bold;
    color: #ff3500;
    display: flex;
    justify-content: space-between;
}

.messaging .error .body {
    border-bottom: 1px solid #ff3500;
    padding-top: 1rem;
    color: #ff3500;
}

/* Success Styling */
.messaging .success {
    margin-top: 2rem;
}

.messaging .success header {
    border-bottom: 2px solid #4F8A10;
    padding-top: 1rem;
    padding-bottom: 1.1rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 32px;
    font-weight: bold;
    color: #4F8A10;
    display: flex;
    justify-content: space-between;
}

.messaging .success .body {
    border-bottom: 1px solid #4F8A10;
    padding-top: 1rem;
    color: #4F8A10;
}

/* Warning Styling */
.messaging .warning {
    margin-top: 2rem;
}

.messaging .warning header {
    border-bottom: 2px solid #f2c779;
    padding-top: 1rem;
    padding-bottom: 1.1rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 32px;
    font-weight: bold;
    color: #f2c779;
    display: flex;
    justify-content: space-between;
}

.messaging .warning .body {
    border-bottom: 1px solid #f2c779;
    padding-top: 1rem;
    color: #FEEFB3;
}

/** Micro stying **/
.error.micro {
    border-bottom: none;
    margin: 0px;
}

.error.micro header {
    font-size: 16px;
}

.error.micro .body {
    padding-top: 1rem;
    color: #ff3500;
}
