.premiumAdminEditUserMenu h5 {
    font-size: 14px;
    font-weight: bold;
}

.premiumAdminEditUserMenu .radio {
    float: left;
    margin-right: 25px;
}

.premiumAdminEditUserMenu .form-group .radioHolder {
    overflow: hidden;
}

.premiumAdminEditUserMenu .edit-user-actions .action .spinner {
    margin: 0;
}

.IModify
{
    margin-right: 2rem;
}

.enrollmentInput
{
    width: 55px;
    display: inline;
    margin-right: 5px;
}

.radioEnrollments {
    margin-left: 0px !important;
    margin-right: 25px;

}

.rosterSaveSpinner .spinner {
    margin: 0px 65px 15px 0px !important;
    height: 40px !important;
}

.rosterNote{
    margin-top: 40px;
    margin-left:-15px;
    margin-bottom:5px;
}

.sessionTitle{
    margin-top: -15px;
    margin-bottom: 15px;
    font-size: 14px;
    padding-top: 0.5em;
}

.cpCourseCursor {
    cursor: pointer; 
    user-select: none;
}

.cpOrderIcon {
     margin-right: 15px; 
     float: left;
}

#imageSelectionModal .imgSelectionContainer{
    background-color: white;
    max-height:500px;
    overflow-x:hidden;
}

#imageSelectionModal .mainImage{
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

#imageSelectionModal .firstRow{
    margin-top:70px;
    max-height:300px;
    overflow-y:scroll;
    overflow-x:hidden;
}
#imageSelectionModal .title-class{
    border-bottom: 1px solid #a9a9a9;
    height:35px;
    font-weight:700;
    margin-top:20px;
}

#imageSelectionModal .tileImage{
    max-width:100%;
    max-height:100%;
    border:solid;
    border-color:white;
    border-width:3px;
}
#imageSelectionModal .tileImage:hover{
    border-color:#A1A1A1;
}
#imageSelectionModal .selected{
    border-color:#0078C1;
}

.offeringSession {
    background-color: white;
    padding-left: 0px;
    padding-right: 0px;
    border-right: 15px solid #E5E5E5;
    BORDER-LEFT: 15px solid #E5E5E5;
}

#edit-course-form label, h5, .btn {
    text-transform: capitalize;
}