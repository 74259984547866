/* Layout for assignments page header */
.assignmentsHeader {
    border-bottom: 2px solid black;
    padding-bottom: 1rem;
    flex-direction: row;
    display: flex;
    align-items: baseline;
    margin: 0px -15px;
}

    .assignmentsHeader h2 {
        padding-left: 2rem;
        margin-top: 3rem;
    }

/* viewBy area */
div.viewBy span {
    color: black;
    font-size: 16px;
}

a.viewTile {
    background: url('../../App/images/icons/viewby_tile_inactive.svg');
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    display: inline-block;
}

    a.viewTile.selected {
        background: url('../../App/images/icons/viewby_tile.svg');
        width: 1.5rem;
        height: 1.5rem;
        background-size: contain;
        display: inline-block;
    }

a.viewList {
    background: url('../../App/images/icons/viewby_list_inactive.svg');
    width: 2rem;
    height: 1.5rem;
    background-size: cover;
    display: inline-block;
}

    a.viewList.selected {
        background: url('../../App/images/icons/viewby_list.svg');
        width: 2rem;
        height: 1.5rem;
        background-size: cover;
        display: inline-block;
    }

a.viewCalendar {
    background: url('../../App/images/icons/viewby_calendar_inactive.svg');
    width: 2rem;
    height: 2rem;
    background-size: contain;
    display: inline-block;
}

    a.viewCalendar.selected {
        background: url('../../App/images/icons/viewby_calendar.svg');
        width: 2rem;
        height: 2rem;
        background-size: contain;
        display: inline-block;
    }

a.viewILT {
    background: url('../../App/images/icons/viewby_ilt_inactive.svg');
    width: 3rem;
    height: 2rem;
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
}

    a.viewILT.selected {
        background: url('../../App/images/icons/viewby_ilt.svg');
        width: 3rem;
        height: 2rem;
        background-size: contain;
        display: inline-block;
        background-repeat: no-repeat;
    }

/* filterby area */
.filterBy {
    padding: 2rem;
    font-size: 16px;
    display: flex;
}

    .filterBy a {
        /* Yet another override. */
        font-size: 16px;
    }

    .filterBy span.filterHelpText {
        margin-right: 2rem;
    }

    .filterBy .filterHelpText {
        color: black;
        font-weight: bold;
    }

    .filterBy .filterItem + .filterItem:before {
        content: "|";
        color: black;
        padding-right: 6px;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .filterBy .filterItem a {
        color: black;
    }

    .filterBy span.selected a {
        font-weight: bold;
    }

div.users div.viewBy {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.users .templateSwitcher {
    list-style: none;
}

    .users .templateSwitcher li {
        display: inline;
    }

.filters {
    margin-bottom: 15px;
    padding: 0px;
}

    .filters.col-md-4 {
        text-align: right;
    }

    .filters .assignmentFilter {
        color: #000;
        cursor: pointer;
        font-size: 16px;
        margin-right: 7px;
    }

    .filters.col-md-4 .assignmentFilter {
        margin-left: 7px;
    }

    .filters .assignmentFilter:hover {
        text-decoration: none;
    }

/**
* Manager css
*/
.manFlyout {
    border-top: 1px solid #313131;
    left: 15px;
    top: 30px;
}

#advManagerSearchFilter .body {
    width: 100%;
}

/*student dashboard*/
#studentDash .summary .summaryHeader {
    font-weight: bold;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: .5rem;
}

#studentDash .summary {
    background-color: #f5f5f5;
    padding-left: 2rem;
    padding-right: 2rem;
}

#studentDash .summaryExpander {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

#studentDash .summary .summaryRow .summaryBox {
    width: 16rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#studentDash .summary .summaryRow {
    display: flex;
    justify-content: flex-begin;
    margin-top: 55px;
}

    #studentDash .summary .summaryRow .summaryBox .amount {
        font-weight: bold;
        font-size: 26px;
        text-align: center;
    }

#studentDash .summaryRows {
    padding-right: 45px;
}

.assignmentsHeader > .datePickerHolder {
    padding-right: 2rem;
}

#studentDash .summary .summaryPieChart .chartBody .chartLegend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: 6rem;
    padding-bottom: 1rem;
}

#studentDash .summary .summaryPieChart .chartBody {
    display: flex;
}

    #studentDash .summary .summaryPieChart .chartBody .chartLegend .amount {
        font-weight: bold;
        font-size: 24px;
        display: block;
        margin-bottom: -.7rem;
    }

    #studentDash .summary .summaryPieChart .chartBody .chartLegend .title {
        font-weight: bold;
        font-size: 12px;
        display: block;
    }

#studentDash .incomplete {
    color: #D6D6D6;
}

#studentDash .complete {
    color: #009CE6;
}

#studentDash .overdue {
    color: #f63f3f;
}

#studentDash .chartLegend .row {
    background-color: white;
    border: solid black 1px;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 10rem;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

#studentDash .assignmentsHeader {
    justify-content: space-between;
}

#studentDash .summaryPieChartContainer {
    padding-top: 10px;
}

/* Tabbed navigation */
ul.bai-tab-nav {
    list-style: none;
    padding-left: 0;
    margin: 0;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #00A1DE;
}

    ul.bai-tab-nav li:last-child {
        margin-left: -5px;
    }

    ul.bai-tab-nav li {
        display: inline-block;
        border-top: 1px #000000 solid;
        border-left: 1px #000000 solid;
        border-right: 1px #000000 solid;
        font-size: 14px;
    }

        ul.bai-tab-nav li.active {
            border-top-width: 1px;
            border-top-style: solid;
            border-right-width: 1px;
            border-right-style: solid;
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: #00A1DE;
            border-right-color: #00A1DE;
            border-top-color: #00A1DE;
            background-color: #00A1DE;
        }

        ul.bai-tab-nav li > a {
            color: #000000;
            font-size: 14px;
            text-decoration: none;
            display: block;
            padding: 1rem 5rem;
        }

        ul.bai-tab-nav li.active > a {
            color: #FFFFFF;
            font-weight: 700;
        }

inProgressContainer {
    margin-top: 25px;
    margin-bottom: 25px;
}

#studentDash .inProgressContainer .inProgressSummaryContainer {
    font-size: 1.25em;
    margin-left: 25px;
}

.inProgressSummaryHeader {
    margin-top: 50px;
    font-weight: bold;
}

.inProgressRow {
    margin-top: 25px;
    margin-bottom: 25px;
    border-bottom: 3px solid white;
    padding-bottom: 15px;
}

.doughnutPercentage {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 1.3em;
    text-align: center;
}

.summaryHeader {
    border-bottom: 3px solid white;
    padding-bottom: 10px;
}

.administratorDashboard .summary .summaryRow {
    margin-bottom: 55px;
}

.administratorDashboard {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.dashboardContent {
    border-top: 2px solid black;
    padding-left: 15px;
    padding-right: 15px;
}

    .dashboardContent .spinnerLoading {
        width: 100%;
    }

    .dashboardContent .spinner {
        margin: 0px;
        width: auto !important;
    }


.dashboardContent .summary{
    padding-right:0px !important;
}

#careerPathKey {
    padding-top:10px;
    padding-left:240px;
}

#careerPathKey .cpKeyRow {
    display:inline-flex;
    width:100%;
}

#careerPathKey .careerPathColorBox{
    width:20px;
    height:20px;
    padding-right: 20px;
    margin-right: 5px;
}

#careerPathKey .careerPathKeyName {
  
    padding-top:2px;
}


 #chartjs-tooltip {
     opacity: 0;
     position: absolute;
     background-color:white;
     padding: 10px;
     border-radius: 3px;
     -webkit-transition: all .1s ease;
     transition: all .1s ease;
     pointer-events: none;
     -webkit-transform: translate(-50%, 0);
     transform: translate(-50%, 0);
     width:150px;
     border:solid #e5e5e5 2px;
     z-index:10;
 }

 .myProgressContainer{
     background-color:#00375d;
     color:#ffffff;
     margin-top:20px;
     overflow:hidden;
     background-clip:content-box;
 }

 .progressTitle{
     height:10px;
     margin: 10px 0px 10px 10px;
     font-size:16px;
     font-weight:bold;
 }

 .myProgressContainer .title{
     font-size: 16px;
     text-align:center;
    font-family: 'Verdana', 'Arial', 'sans-serif';
 }

 .myProgressContainer .summaryBox:first-of-type{
     border-bottom:solid;
     border-bottom-color:#ffffff;
     border-bottom-width:thin;
     padding-bottom:6px;
     margin-bottom:6px;
     margin-top:20px;
 }
 
 .myProgressContainer .col-sm-3{
     margin: 16px 16px 16px 20px;
     max-height:180px;
     max-width:191px;
 }

 .myProgressContainer .summarySection{
     padding:40px 0px 0px 50px;
     font-size:20px;
     font-weight:bold;
 }

 .myProgressContainer .summarySection .amount{
     font-size:24px;
 }

 .myProgressContainer .amount{
     font-size:16px;
     font-weight:bolder;
    display: inline-block;
     width:51px;
 }