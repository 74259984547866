bai-catalog-header-lms #root div {
    /*background-color: #E5E5E5;*/
    height: 3.7em;
   /* margin-bottom:10px;*/
}

    bai-catalog-header-lms #root div.col-sm-12 {
        padding-left: 10px;
    }

    bai-catalog-header-lms #root div.col-sm-1 {
        display: flex;
    }

    bai-catalog-header-lms #root div.col-sm-4 {
        display: flex;
    }

        bai-catalog-header-lms #root div.col-sm-4 input[type='text'] {
            border-radius: 0;
            border: 1px solid #A9A9A9;
            background-color: white !important;
            align-self: flex-end;
        }

        bai-catalog-header-lms #root div.col-sm-4.right-side-links {
            align-items: center;
            justify-content: flex-end;
        }

bai-catalog-header-lms button.btn {
    border-radius: 0;
    border: 1px solid transparent;
    border-left: 0;
    align-self: baseline;
    font-weight: normal;
}

    bai-catalog-header-lms button.btn:hover, button.btn:focus {
        border-radius: 0;
        border: 1px solid transparent;
        border-left: 0;
        height: initial;
    }

bai-catalog-header-lms #root div.right-nav {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 5px 10px;
}

bai-catalog-header-lms div.right-nav > a {
    font-size: 12px;
    color: #00A1DE;
    text-decoration: none;
}

    bai-catalog-header-lms div.right-nav > a:hover {
        text-decoration: underline;
    }

bai-catalog-header-lms div.right-nav > img {
    margin-right: 5px;
    display: inline;
    width: 32px;
    height: 32px;
}

bai-catalog-header-lms button.catalog-button.btn-div, bai-catalog-header-lms a.catalog-button.btn-div {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    background-color: #1EABE3;
    height: 3.5em;
    /*margin-top: 0.2em;*/
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    color: white;
}

bai-catalog-header-lms a.catalog-button.btn-div {
    font-size: 1.2rem;
    text-decoration: none;
    display: inline-flex;
}

    bai-catalog-header-lms button.catalog-button.btn-div > span, bai-catalog-header-lms a.catalog-button.btn-div > span {
        padding-left: 5px;
        margin-bottom: 0;
        border: 1px solid transparent;
        padding: 0.3em 0.6em;
        font-size: 14px;
        padding-left: 0;
        font-weight: normal;
        vertical-align: middle;
    }

    bai-catalog-header-lms button.catalog-button.btn-div > img, bai-catalog-header-lms a.catalog-button.btn-div > img {
        width: 32px;
        height: 32px;
        padding-left: 5px;
    }

    bai-catalog-header-lms button.catalog-button.btn-div:focus, bai-catalog-header-lms a.catalog-button.btn-div:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px
    }

    bai-catalog-header-lms button.catalog-button.btn-div:active, bai-catalog-header-lms a.catalog-button.btn-div:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        background-color: #d4d4d4;
        border-color: #8c8c8c;
        color: #333;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px
    }
