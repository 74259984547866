
body, html, md-content,
.back-white {
    background-color: #fff;
}

.offering md-content {
    overflow: inherit;
}

.detail-col-left {
    padding-left: 22px;
}
.detail-col-right {
    margin-top: 35px;
}

.col1 .expand-it,
.col1 .collapse-it {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    outline: none;
    padding-top: 17px;
    vertical-align: top;
    width: 24px;
}

.expanded-student-detail {    
    font-size: 13px;
    padding-left: 28px;
}
.expanded-student-detail p {
    margin-bottom: 2px;
}
.expanded-student-detail p span {
    font-weight: 600;
}

.group-status {
    background-color: #f0f0f0;
    padding: 15px 0px;
}

.group-status h3 {
    font-weight: 600;
    margin-bottom: 3px;
    padding-left: 8px;
}

.group-status .grade-range-error {
    white-space: nowrap;
    font-weight: bold !important;
}

.instructor-nav-secondary {
    background-color: #e0e0e0;
    height: 40px;
    line-height: 40px;
    padding-left: 24px;
}
.instructor-nav-secondary a {
    font-size: 12px;
}
.instructor-nav-secondary a.selected {
    font-weight: 700;
}
.instructor-extra-nav {
    font-size: 16px;
    padding-top: 51px;
    padding-right: 0;
    text-align: right;
}

.landing-button-container {
    padding-top: 12px;
    padding-left: 7px;
}
.landing-button-container button:not(:last-child) {
    margin-right: 5px;
}

.left0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.offerings .actions {
    background-color: #f0f0f0;
    padding: 15px;
}

.offerings .actions h3,
.offerings .actions ul {
    float: left;
    margin: 0px;
    padding: 0px;
}

.offerings .actions ul {
    float: right;
    list-style: none;
}

.offerings .actions ul li {

}

.offering-detail-category {
    display: inline-block;
    font-size: 13px;
    vertical-align: top;
    width: 115px;
}
.offering-detail-category-right {
    display: inline-block;
    font-size: 13px;
    vertical-align: top;
    width: 160px;
}

.offering-detail-value {
    font-size: 13px;
}

p.offering-detail-value {
    margin-bottom: 0;
}

.offering-detail-row {
    margin-bottom: 12px;
}
.offering-detail-row-right {
    margin: 0px;
}

.offering-detail-container {
    display: inline-block;
}

.offering-overwrite {
    background: #F0F0F0;
    padding-bottom: 0;
}

.offerings-button {
    font-size: 16px;
    text-transform: capitalize;
}

.padbot20 {
    padding-bottom: 20px;
}
.padtop24 {
    padding-top: 24px;
}

p.registration-date {
    font-size: 13px;
    margin-top: 0;
}
p.registration-date span {
    font-size: 13px;
    font-weight: 600;
}

.result-container.add-replace {
    height: 478px;
    margin-top: 26px;
    margin-right: 33px;
    overflow-y: scroll; 
}
.results-title.add-replace .add-replace-org-bar {
    background-color: #e5e5e5;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    line-height: 44px;
    margin-bottom: 8px;
    padding-left: 27px;
    text-transform: uppercase;
    width: 591px;
}
.add-replace-org-bar input[type='checkbox'],
.add-replace-group-bar input[type='checkbox'] {
    position: relative;
    top: 2px;
    margin-right: 10px !important;
    margin-left: 14px !important;
}
.result-instance.add-replace .add-replace-group-bar {
    background-color: #f3f1f2;
    font-size: 14px;
    font-weight: 600;
    line-height:  44px;
    margin: 0 0 8px 36px;
    padding-left: 64px;
    text-transform: uppercase;
    width: 555px;    
}
.result-instance.add-replace .add-replace-group-bar:last-child {
    margin-bottom: 10px;
}
.results-title.add-replace {
    background: #fff;
    margin: 44px 0 0 15px;
    padding: 25px 8px 8px 24px;
}
.results-title.add-replace h3 {
    font-size: 20px;
    font-weight: 600;
}
.results-title.add-replace h3 span {
    font-style: italic;
    font-weight: 400;
}
.add-replace-name-bar {
    padding-left: 24px;
}
.add-replace-name-bar div {
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;  
}
.add-replace-name-bar div:last-child {
    padding-bottom: 12px;
}

.roster-action {
    display: inline;
}

.roster-action + .roster-action:before {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.disable-studentCta {
    pointer-events: none !important;
}

.enable-studentCta {
    pointer-events: auto !important;
}

.group-status-detail,
.select-session-detail {
    padding-bottom: 15px;
    background-color: #fff;
}

.group-status-detail:not(:last-child),
.select-session-detail:not(:last-child) {
    border-bottom: solid 1px #e5e5e5;
}
.waitlist-row {
    border-top: solid 1px #e5e5e5;
}

.group-status-detail h3,
.waitlist-row h3 {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 3px; 
}

.waitlist-count {
    font-size: 15px;
    font-weight: 600;
    padding-right: 0;
    padding-bottom: 10px;
    text-align: right; 
}

.group-status-detail h3 span,
.select-session-detail h3 span {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    text-transform: capitalize; 
}

.roster-student-col1 {
    padding-top: 10px;
}
.group-status-detail .expand-it, 
.waitlist-row .expand-it {
    display: inline-block;
}

.select-session {
    background-color: #f0f0f0;
    padding-top: 25px;
}
.select-session h3 {
    font-weight: 600;
    margin-bottom: 3px;
    padding-left: 24px;
}
.select-session-detail h3 {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 3px;
}

.session-col-left {    
    padding-left: 10px;
}
.session-color {
    background-color: #f0f0f0;
}

#remove-confirmation {
    color: red;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding-top: 3px;
}

/*** column 1 screens */

.group-status .col1 {
    padding-top: 2px;
}
.select-session .col1 {
    padding-top: 5px;
    padding-left: 0;
}

.row .group-status-detail .col1,
.row .select-session-detail .col1,
.row .waitlist-row .col1 {
    padding-left: 0 !important;
    padding-right: 0;
    padding-top: 11px;
}

.row .group-status .col1 {
    padding-left: 28px !important;
}
.row .group-status-detail .col1,
.row .waitlist-row .col1 {
    padding-left: 15px !important;
}

.group-status-detail .col1 > p,
.waitlist-row .col1 > p,
.select-session-detail .col1 > p {
    padding-left: 28px;
}

.group-status-detail .col1 p span,
.select-session-detail .col1 p span,
.waitlist-row .col1 p span {
    font-weight: 600;
}

/*** column 2 screens ***/

.select-session .col2 {
    padding-top: 8px;
}
 
.group-status .col2 span {
    display: inline-block;
    font-size: 11px;
    padding-bottom: 8px;
}
.group-status-detail .col2 span {
    display: inline-block;    
    font-size: 11px;
    padding-top: 28px;
    padding-bottom: 8px;
}

.group-status .col2 select {
    border: solid 1px #999;
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;    
    font-weight: 400;
    height: 25px;
    margin-bottom: 40px;
    margin-top: 0;
    padding: 0 4px;
    width: 237px;
    -webkit-appearance: menulist;
}
.group-status-detail .col2 select {
    border: solid 1px #999;
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;    
    font-weight: 400;
    height: 25px;
    margin-bottom: 40px;
    padding: 0 4px;
    -webkit-appearance: menulist;
}

.select-session .col2 select {
    border: solid 1px #999;
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 25px;
    margin-top: 18px;
    margin-bottom: 40px;
    padding: 0 4px;
    width: 307px;
    -webkit-appearance: menulist;
}

/*** column 3 ***/

.group-status .col3,
.group-status-detail .col3 {
    padding-left: 5px;
}
.select-session .col3,
.select-session-detail .col3 {
    padding-left: 71px;
    padding-top: 4px;
}

.group-status .col3 span {
    display: inline-block;
    font-size: 11px;
    font-weight: 400;  
    padding-bottom: 8px;
    padding-top: 2px; 
}
.group-status-detail .col3 span {
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    padding-bottom: 8px;
    padding-top: 28px;
}
.select-session .col3 span,
.select-session-detail .col3 span {
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 4px;
}
.select-session .col3 span {
    padding-bottom: 2px;
}
.select-session-detail .col3 span {
    padding-top: 24px;
}

.group-status .col3 input,
.group-status-detail .col3 input,
.select-session .col3 input,
.select-session-detail .col3 input {
    border: solid 1px #999;
    box-sizing: border-box;
    display: inline-block;    
    font-size: 16px;
    height: 25px;
    padding: 4px;
    width: 108px;
}

/*** column 4 ***/

.group-status .col4 {
    padding-top: 17px;
}
.group-status-detail .col4 {
    padding-top: 44px;
    text-align: center;
}
.select-session .col4 {
    padding-top: 18px;
}
.select-session-detail .col4 {
    padding-top: 37px;
}

.group-status .col4 {text-align: center;}
.group-status .col4 button,
.group-status-detail .col4 button,
.select-session .col4 button,
.select-session-detail .col4 button {
     display: inline-block;
     margin: 0 auto !important;
     width: 142px;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden; /* fixes a bug specific to Chrome, where a hover event causes the hovered element to slide horizontally */
}
.group-status .col4 button,
.select-session .col4 button {
    padding-left: 42px;
}

.waitlist-row .col4 {
    padding-top: 29px;
    color: red;
    font-size: 13px;
    text-align: right;
}
.waitlist-row .col4 span {
    display: none;
    font-weight: 600;
}

.waitlist-row .col4 select {
     border: solid 1px #999;
     box-sizing: border-box;
     display: inline-block;
     font-size: 14px;
     font-weight: 400;
     height: 25px;
     margin-bottom: 40px;
     padding: 0 4px;
     width: 237px;
     -webkit-appearance: menulist; 
}

/*** column 5 ***/

.group-status .col5,
.select-session .col5 {
    padding-top: 27px;
}
.group-status-detail .col5 {
    padding-top: 54px;
}
.select-session-detail .col5 {
    padding-top: 47px;
}
.waitlist-row .col5 {
    padding-top: 33px;
    padding-right: 0px;
}

.group-status .col5 a,
.group-status-detail .col5 a,
.select-session .col5 a,
.select-session-detail .col5 a {
    font-size: 13px !important;
    font-weight: 400;
}
.group-status-detail .col5 span {
    padding: 0 10px;
}

.group-status-detail .student-grade-error {
    white-space: nowrap; 
    padding-top: 5px !important; 
    margin-top: 15px;
    font-weight: bold !important;
}

/*** end column classes ***/

footer.container {
    padding-bottom: 30px;
}

.col5 ul {
    padding: 0px;
}

.col5 ul li {
    display: inline;
    float: left;
}

.offeringModal .buttons .spinner,
.offeringModal .buttons .spinner {
    float: right;
    margin: 0 65px;
}

.roster .col-md-2 .spinner {
    margin: 0 auto;
}

.col5 .spinner {
    margin: 0 auto;
}