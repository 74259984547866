/*
    Tile view
*/
.users .row .assignment.col-md-3 {
    padding: .5rem;
}

.users .row .col-md-3 .content {
    cursor: pointer;
    height: 100%;
    position: relative;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.users .row .col-md-3 .content img {
    height: 100%;
    width: 100%;
}

.users .row .col-md-3 .title {
    padding-top: .25rem;
}

.users .row .col-md-3 .title h4 {
    font-size: 16px;
    font-weight: bold;
    height: 6rem;
    /* yet another bootstrap override */
    /* Bootstrap is more like a block of cheese at this point */
    margin-top: 0;
}

.users .row .col-md-3 .title .more {
    border-left: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    height: 59px;
    margin: 0px;
    padding: 20px 0px 0px 0px;
    text-align: center;
    vertical-align: middle;
}

/*
 * This is absolute garbage and causes extra work styling every page. never, ever, ever tie your css to bootstrap classes.
 * Use bootstrap to do the layout. if you have to adjust margins/padding to bootstrap, just make new classes and put the
 * margins and padding on those.
.users .row .row {
    margin: 7px 0px;
}
*/

/* Assignment status */
.status {
    color: black;
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
}

.score {
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
}

.new,
.notRegistered {
    color: #003399;
}

.confirmed,
.inProgress {
    color: #339933;
}

.waitlist {
    color: #F70;
}

.overdue {
    color: red;
}

.duesoon {
    color: #FFd000;
}

.unsuccessDiv
{
    padding-top:35px;
    padding-bottom:30px;
}

.btn.relaunchBtn
{
    text-transform: none;
    font-weight:normal;
    padding: 6px 38px 6px 38px;
    text-shadow:initial;
    margin-top: 14px;
    border:none;
}
.btn.relaunchBtn:hover,
.btn.relaunchBtn:active,
.btn.relaunchBtn:focus
{
    text-transform: none;
    font-weight:normal;
    padding: 6px 38px 6px 38px;
    text-shadow:0px 0px 0px #fff;
    margin-top: 14px;
    border:none;
}
.unsuccessAssignName
{
    margin:0 0 10px;
}
.unsuccessAttempts
{
    padding-top: 5px;
    padding-bottom: 1px;  
    font-size: 14px;  
}
.unsuccessScore
{
     margin:0 0 3px;
     font-size:14px;
     color: red;
}
.unsuccessHeader
{
    padding-left:25px;
    padding-top:30px;
    font-size:35px;
}
.unsuccessTitle
{
    font-size:16px;
}

/**
* Assignment tile
*/

.tile {
    padding: 5px;
}

/* On the tile view, make the tiles in the left column
 * have a left padding of 0 and the tiles in the right
 * column have a right padding of 0. that way the tiles
 * are flush with the margins of the page */
#listPage div.tile.leftColumn {
    padding-left: 0;
}
#listPage div.tile.rightColumn {
    padding-right: 0;
}

.assignmentPadding {
    background-color: #e5e5e5;
    min-height: 375px;
    padding: 1rem;
}

.careerpath-assignmentPadding {
    background-color: #e5e5e5;
    min-height: 475px;
    padding: 1rem;
}

.tile img {
    margin-bottom: 15px;
    width: 100%;
}

.tile h4 {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

/* Do not move these in a different order
Otherwise the won't fire when the page shrinks correctly */
@media (min-width: 768px) {
    .tile h4 {
        height: 6.5rem;
    }
}

@media (min-width: 992px) {
    .tile h4 {
        height: 5rem;
    }
}
@media (min-width: 1300px) {
    .tile h4 {
        height: 3.5rem;
    }
}


.dueDate {
    color: black;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}

/* Make ng-show do visibility hidden on the dueDate */
.dueDate.hidden {
  /* Override display: none !important in vendor.min.css */
  display: block !important;
  visibility: hidden;
}

#courseList .actionHolder .spinner4 {
    float: left;
    margin-right: 15px;
}

.more {
    color: black;
    text-decoration: underline;
    font-size: 14px;
    padding-bottom: 1rem;
}

#courseList .more {
    color: black;
    float: left;
    font-size: 14px;
    text-decoration: underline;
}

.courseGroupMore {
    color: black;
    float: left;
    font-size: 14px;
    text-decoration: underline;
}

a.assignmentFilter {
    margin-left: 2rem;
}

.alert-tile {
    color: #000;
    margin: 1rem 0rem;
    background-color: #f2dede;
    padding: 1px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 15px;
    margin-top: -10px;
    border-color: #ebccd1 !important;
}

.alert-list {
    color: #000;
    margin: 1rem 0rem;
    background-color: #f2dede;
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: #ebccd1 !important;
    margin: 0rem 0rem;
    text-align: center;
}

/*
* Generic rule for the launch button which appears outside of the slide down.
*/
#courseList .actionHolder a.btn,
#courseList .actionHolder button {
    float: left;
    margin-right: 45px;
}

.courseGroupIcon {
    width: 3rem;
    height: 2rem;
    background: url('../../App/images/icons/multi_session_black.svg');
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;

}

#courseList .courseGroupIcon{
    margin-left: 2rem;
    margin-top: 1rem;
}
.statusContainer{
    display: inline-flex;
    height: 2px;
}

#courseList .courseListTitle{
    display: flex;
}

/* View Resources start */

.resource
{
    color: black;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 10px;
    float: left;
    padding-bottom: 1rem;
    padding-left: 15px;
}

.resourceForCourseGroup
{
    margin-top: 0px !important;
}

#viewResources {
    margin-bottom: 5px;
    width: 100%;
    margin-top: 3px;
    padding: 0px 5px;
    float: left;
    display: block;
    position: relative;
}

#courseList #resourcesContainer {
    padding-top: 40px;
}

.resourceHeaders{
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
}
.resourceName{
    width: 80%;
    padding-left: 20px;   
}
.resourceFileFormat{
    width: 10%;
}
.resourceSize{
    width: 10%;
}

.resourceBody {
    border-bottom: 1px solid #e5e5e5;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px !important;
}

.resourceNameDetails {  
    width: 80%;
    padding-left: 20px;
}

.resourceFileFormatDetails {
    width: 10%;
}

.resourceSizeDetails {
    width: 10%;
}

.launchBtnResources
{
    margin-right: 7px !important;
}

#courseList #viewResources .body {
    background-color: #f2f2f2;
    overflow: hidden;
    padding: 5px;
    padding-left: 10px;
    padding-bottom: 25px;
}

.disabled-btn {
    color: black;
}
/* View Resources end */
.inactivetab {
    text-align: right;
    width: 50%;
    padding-top: 10px;
}