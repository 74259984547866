/*.ta .singleLineRockwellHeader {
    font-size: 26px;
    margin-top: 10px;
}
*/
.ta .singleLineRockwellHeader {
    color: black;
    font-size: 26px;
    font-family: 'Rockwell';
    margin: 30px 0px 30px;
}