/* Background */
.loginBg {
    height: 100%;
    width: 100%;
    background-image: url('../../App/images/bai_login_background_blue.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: auto;
    z-index: 1;
}

div.loginBgGrayOverride {
    background-image: url('../../App/images/bai_login_background_gray.jpg');
}

/* The visit BAI link in the top right corner */
.visit-bai {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: white;
    font-weight: bold;
    padding-top: 3vh;
}

.visit-bai a {
    color: white;
    margin-right: 17vmin;
}

/* logo */
.login h1#logo {
    margin-top: 1.5vh;
    margin-bottom: 1vh;
    background-image: url('../../App/images/BAI_Learning_Manager_Logo_White.svg');
    background-position: center;
    text-indent: 0;
    min-width: 17vw; /* @TODO: fix this up */
    width: 100%;
    height: 11vmin;
    background-size: contain;
}

/* Login form layout */
#loginPageContainer {
    width: 100vw;
    z-index: 4;
    height: 100%;
    position: fixed;
    left: 0;
    top: auto;
    overflow: hidden;
}

.loginFormContainer {
    margin: 0 auto;
    width: 100%;
}

.loginFormContainer {
    padding-bottom: 1rem;
    width: 33vw;
}

.loginFormContainer form {
    background-color: white;
    padding-top: 5vh;
    padding-bottom: 6vh;
}

form .poweredByBai {
    margin-top: -2rem;
    float: right;
    align-items: baseline;
    display: none;
    position: absolute;
    bottom: 15px;
    right: 0px
}

form .poweredByBai span {
    margin-right: 1rem;
}

form .poweredByBai img {
    margin-right: 1.5rem;
    width: 30px;
    height: 30px;
}


/* Login form title */
.loginFormContainer h3 {
    font-family: 'Rockwell', Times, "Times New Roman", serif;
    font-size: 2.3vw;
    text-align: center;
    color: white;
    background-color: none;
    width: 33vw;
    padding-bottom: 2rem;
    margin-bottom: 0;
    /*margin-top: 5.5vh;*/
}

/* Hide all forms initially 
 * These will be activated by angular
 * */

.login .enterpin,
.login .forgotpassword,
.login .loading,
.login .resetpassword {
    display: none;
}

/* Style the input elements */
.loginFormContainer form div.form-group input {
    background-color: white;
    color: #747474;
    margin-bottom: 7px;
    padding: 7px;
    width: 100%;
    border-radius: 0px;
    margin-bottom: 1.5rem;
    height: 7vh;
    text-shadow: 0 0 .01px rgba(0,0,0,0.5);
    font-size: 20px;
    padding-left: 1em;
}

.loginFormContainer form p {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.loginFormContainer .form-group {
    margin-left: 2.4vw;
    margin-right: 2.4vw;
    margin-bottom: 2.5vh;
}

#loginPageContainer button.btn-default.portalBackground {
    font-size: 16px;
    width: 41%;
    margin-left: 2.3vw;
    margin-top: 1vh;
    border-radius: 12px;
    text-transform: none;
    text-shadow: none;
    height: 6vh;
}

a.portalLoginLink {
    color: rgb(22, 170, 230);
    margin-top: 2.5vh;
    display: block;
    margin-left: 3vw;
    font-size: 16px;
}

/* Error group styling */
.loginFormContainer.enterpin .error-group,
.loginFormContainer.forgotpassword .error-group,
.loginFormContainer.resetpassword .error-group,
.loginFormContainer.signin .error-group {
    overflow: hidden;
}

.loginFormContainer .error-group {
    margin-bottom: 0;
}

.loginFormContainer .error {
    background-color: #f00;
    display: none;
    float: right;
    padding: 3px 15px;
}

/* Help and copyright at bottom of page */
.loginHelpText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.loginHelpText p {
   width: 33vw;
   align-self: center;
   text-align: left;
   font-size: 1.3rem;
   font-weight: bold;
   padding-top: .9rem;
   margin-bottom: 0;
}

.loginCopyright {
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: center;
    width: 100%;
}

.loginCopyright p {
    width: 33vw;
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    color: white;
    margin-bottom: 2rem;
}

.loginCopyright p a {
    color: #fff;
    font-size: 1rem;
}