#navigation {
    padding-left: 0;
}

#flyoutHolder {
    align-items: baseline;
    position: relative;
    display: flex;
    float: left;
    margin-right: 50px;
    padding-bottom: 15px;
    width: 40px;
}

#hamburger {
    float: left;
    margin-right: 50px;
    position: relative; 
    width: 40px;
    padding-bottom: 3px;
    padding-left: 10px; 
    height: 100px;
    border: 3px;
    top: 64px;
}

#hamburger-lms {
    float: left;
    margin-right: 50px;
    position: relative;
    width: 40px;
    padding-bottom: 3px;
   /* padding-left: 10px;*/
    height: 100px;
    border: 3px;
    top: 64px;
}

#hamburger .messageCount {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    background-color: #f00;
    border-radius: 15px;
    color: #fff;
    left: 30px;
    position: absolute;
    top: -10px;
    padding: 3px 7px;
}

.flyout {
    background-color: white;
    display: none;
    position: absolute;
    top: 103px;
    z-index: 106;
    left: 0;
    -webkit-box-shadow: 0px 2px 0.5px 0.7px rgba(51,51,51,0.35);
    -moz-box-shadow: 0px 2px 0.5px 0.7px rgba(51,51,51,0.35);
    box-shadow: 0px 2px 0.5px 0.7px rgba(51,51,51,0.35);
}

.flyout .body {
    float: left;
    width: 215px;
}

.flyout .body ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.flyout .body ul li {
    border-bottom: 1px solid #313131;
    display: block;
}

.flyout .body ul li:last-child {
    border-bottom: none;
}

.flyout .body ul li:hover {
    border-bottom: 1px solid #313131;
    background:#e5e5e5;
    cursor: pointer;
}

.flyout .body ul li a {
    font-size: 14px;
    color: #333333;
    display: block;
    padding: 15px 2rem;
    width:100%;
    text-decoration: none;
}

.messageCount {
    color: white;
    background: #3399cc;
    margin-left: 3px;
    font-size: 11px;
    font-weight: bold;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding-left: 6px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 4px;
}

.flyout .body ul a:hover {
    /*border-bottom: 1px solid #1EABE3;*/
    /*text-decoration: none;
    color: #333333;
    background-color: #e5e5e5*/
}

.c-hamburger {
    background: none;
    display: block;
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 60px;
    height: 27px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
}

.c-hamburger:focus {
    outline: none;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    right: 26px;
    height: 4px;
    background-color: #1EABE3;
    border-radius: 3px;
}

.c-hamburger span::before,
.c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #1EABE3;
    content: "";
    border-radius: 3px;
}

.c-hamburger span::before {
    top: -10px;
}

.c-hamburger span::after {
    bottom: -10px;
}

.c-hamburger--htla {
}

.c-hamburger--htla span {
}

.c-hamburger--htla span::before {
    transform-origin: top right;
}

.c-hamburger--htla span::after {
    transform-origin: bottom right;
}

/* active state, i.e. menu open */
.c-hamburger--htla.is-active .portalBackground {
    background-color: white !important;
}

/* make the X flush right with the hamburger */
.c-hamburger--htla.is-active {
    left: 7px;
}

.c-hamburger--htla.is-active span {
    transform: rotate(180deg);
}

/*
.c-hamburger--htla.is-active span::before,
.c-hamburger--htla.is-active span::after {
    width: 50%;
}
*/

.c-hamburger--htla.is-active span::before {
    top: 12px;
    transform: rotate(45deg);
}

.c-hamburger--htla.is-active span::after {
    bottom: 13px;
    transform: rotate(-45deg);
}

.c-hamburger--htla.is-active span::before, .c-hamburger--htla.is-active span::after {
    height: 3px;
    width: 95%;
    border-radius: 5px;
}
