.taModal.modal label {
    font-weight: normal;
    margin-bottom: 2px;
}

.ta .radio {
    display: inline-block;
}

.ta .radio + .radio {
    margin-left: 3rem;
}

#enforceOrderContainer .radio + .radio {
    margin-left: 3rem;
}
