.ta .assignmentTypeBox {
    height: 10rem;
    margin-bottom: 1rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    background: rgba(186,186,188,1);
    background: -moz-radial-gradient(center, ellipse cover, rgba(186,186,188,1) 0%, rgba(148,143,149,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(186,186,188,1)), color-stop(100%, rgba(148,143,149,1)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(186,186,188,1) 0%, rgba(148,143,149,1) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(186,186,188,1) 0%, rgba(148,143,149,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(186,186,188,1) 0%, rgba(148,143,149,1) 100%);
    background: radial-gradient(ellipse at center, rgba(186,186,188,1) 0%, rgba(148,143,149,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bababc', endColorstr='#948f95', GradientType=1 );

    display: flex;
    justify-content: center;
    align-items: center;
}

.ta .assignmentTypeBox .assignmentTypeText {
    width: 50%
}
