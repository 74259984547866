student-tile-container .required-section {
    background-color: #00375D;
}

student-tile-container .tile-holder {
    border: 1px solid #809BAF;
    min-height: 100px;
    padding-top: 1.5em;
    padding-bottom: 15px;
    padding-left: 1.5%;
}

student-tile-container .no-top-border {
    border-top: none;
}

student-tile-container .no-gutters {
    padding-left: 0px;
    padding-right: 0px;
}