.grayBoxList {
    padding-left: 0;
}

.documentUpload .inlineEditor {
    width: 60%;
}

.documentUpload .fileList .editIcon img {
    height: 2rem;
    width: 2rem;
}

.documentUpload .fileList .icon img {
    height: 2rem;
    width: 2rem;
}

.documentUpload .fileList .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    display: inline-block;
}

.grayBoxList .grayBox {
    list-style-type: none;
    background-color: #eeeeee;
    border: 1px solid #e3e3e3;
    height: 3.5rem;
    position: relative;
    padding-left: 1rem;
    padding-top: 0.6rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
}

.documentUpload .file span {
    margin-right: .5rem;
}

.grayBoxList .grayBox .deleteIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.documentUpload .resourceLaunch:hover .resourceLaunchToolTip {
    visibility: visible;
}

.documentUpload .resourceLaunch .resourceLaunchToolTip {
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    border-color: #B6B6B6;
    border-width: .5px;
    border-style: solid;
    padding: 2px 2px;
}

/*Changing the z-index on bootstrap modal backdrop will affect the fade animations on the modals.
DO NOT change the default classes that come with bootstrap*/

/*.modal-backdrop {
    z-index: -1;
}*/
