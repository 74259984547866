/* Premium admin specific overrides for forms */

/* Makes radio buttons sidewize */
.ta .radio {
    display: inline-block;
}

.ta .radio:nth-child(1) {
    margin-left: 5px;
}

.ta .radio:nth-child(2) {
    margin-left: 36px;
}

/* Make the dropdowns larger for TA forms */
.ta .selectContainer .fakeSelectElement {
    height: 25px;
}

