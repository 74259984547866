bai-course-action-button .spinner {
    margin: 0px !important;
    height: 20px !important;
    text-align: left !important;
    float: right;
}

bai-course-action-button .course-title-link {
    font-size: 12px;
}

assignment-action-button .spinner {
    margin: 0px !important;
    height: 20px !important;
    width: auto !important;
    text-align: left !important;
    float: right;
}

assignment-action-button .course-title-link {
    font-size: 12px;
}