.ta .edit-user-actions .spinner {
    margin: 0 auto;
}
.error .errorDisplay {   
    margin-left: auto;
    margin-right: auto;  
    text-align:center;  
}

.error .errorDisplay .okButtonContainer {
    margin-top: 2rem;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;  
    text-align:center; 
    align-items: center;  
}

.error .errorImageContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.docDeleteError {
    color: red;
    width: 98%;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 10px 10px 10px;
    margin-bottom: 15px;
    margin-top: 20px;
    background-color:white;
}
#viewDeleteDocumentsModal .modal-dialog .modal-content .modal-body table tr th button {
    background-color: #dfdfdf;
    border: 1px #DEDEDE;
    font-weight: bold;
}
/*.ta .content-block .table-block .table button {
    background-color: #ddd !important;
}*/
.docdelete-success {
    margin: 26px 0px 0px 0px;
    font-size: 15px;
}

#viewDeleteDocumentsModal .btn-cancel-action {
    text-decoration: underline;
    color: #76757A;
    margin-right: 15px;
}
