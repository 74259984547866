/* Help index page */
#helpPage .assignmentsHeader {
    margin-bottom: 45px;
}
#helpPage .row.helpBody {
    margin-right: -22px;
    margin-left: -22px;
}

#helpPage .row.helpBody {
    margin-top: 15px;
}

#helpPage .helpBox {
    padding-left: 7px;
    padding-right: 7px;
}

#helpPage img.icon {
    width: 100px;
    height: 100px;
}

#helpPage .help .col-md-4 .info h3 {
    width: 68%;
}
/* For Demo Videos */
.help .modal-header {
    display: flex;
    justify-content: center;
}

/* Header styling & layout */
#helpStudentPage .assignmentsHeader {
    justify-content: space-between;
}

#helpStudentPage .assignmentsHeader h2 {
    margin-bottom: 0;
}

.help .col-md-4 .icon {
    margin: 25px auto 0;
}


#helpStudentPage .icon.note {
    vertical-align: text-bottom;
    margin-right: 3rem;
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
}

#helpStudentPage .assignmentsHeader a {
    font-size: 16px;
}

/* Header styling & layout */
.help .assignmentsHeader {
    justify-content: space-between;
}

.help .assignmentsHeader h2 {
    margin-bottom: 0;
}

.help .col-md-4 .icon {
    margin: 25px auto 0;
}


.help .icon.note {
    vertical-align: text-bottom;
    margin-right: 3rem;
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
}

.help .assignmentsHeader a {
    font-size: 16px;
}

.help .col-md-4 .background {
    height: 200px;
    position: relative;
    width: 100%;
}

.help .col-md-4 .info {
    bottom: 0;
    height: 100%;
    position: absolute;
    width: 100%;
}

.help .col-md-4 .info h3 {
    color: #fff;
    margin: 15px auto;
    text-align: center;
    width: 75%;
    font-size: 16px;
    font-weight: bold;
}

.help .col-md-4 .info h3 a {
    color: #fff;
    font-weight: bold;
}

.help .uicontrol {
    font-weight: bold;
}

.help .stepresult {
    text-indent: 1em;
}

.help .info {
    font-style: italic;
}

.helpNav {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-top: 40px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.taHelpNav {
    border-bottom: 1px solid #ccc;
    margin-top: 40px;
    padding-top: 30px;
    overflow: hidden;
}

.taHelpNav h1 {
    margin-bottom: 30px;
}

.helpNav .col-md-6 {
    width: 50%;
    padding: 0px 15px 7px;
}

.taHelpNav .col-md-6 {
    width: 50%;
    padding: 0px 15px 7px;
}

.helpBody .col-md-12 {
    border-bottom: 1px solid #ccc;
}

.helpBody .col-md-12:last-child {
    margin-bottom: 30px;
}

.helpBody .col-md-12 article {
    margin-top: 30px;
    padding-bottom: 30px;
}

.helpBody .taskbody {
    font-size: 14px;
}

.helpBody h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 30px;
}

.taHelpNav .back-to-top {
    margin-top: 30px !important;
    border-bottom: none !important;
}

.help h2 {
    font-family: Verdana;
    font-size: 24px;
}

.help .taCategory .col-md-12 .title {
    font-size: 24px;
}

#idesign {
    margin-bottom: 20px;
}
