.userMessages .enrollmentRequestContainer {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
}

.userMessages .enrollmentRequestContainer .btn {
    margin-right: 15px;
}

.userMessages .enrollmentRequestContainer .meta > .title {
    margin-bottom: 3px;
}

.userMessages .messageBox {
    background-color: #E5E5E5;
    border: 1px solid #fff;
    height: 210px;
    padding: 30px 15px;
    text-align: center;
}