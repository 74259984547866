/** The general modal styling is in modals.css in
 * the css components folder. This file contains styling only for 
 * the approvals modal */

#approvalsModal .modal-body {
    margin-left: 5px;
    padding-left: 20px;
    padding-bottom: 25px;
}

/* Slight adjustment to padding / layout */
#approvalsModal .approvalsContainer {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    background-color: white; 
    width: 100%; 
    padding-top: 20px; 
    position: relative; 
    margin-top: 10px; 
    margin-bottom: 10px; 
    min-height: 90px;
    padding-left: 15px;
}

/* Name of the user who has a task awaiting approval */
#approvalsModal .approvalsContainer .meta > .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: .5rem;
}

/* Make the details in a column by default */
#approvalsModal .approvalsContainer .meta {
    /*display: flex;
    flex-direction: column;*/
    line-height: 1.7rem;
}

/* The meta details should be in bold */
#approvalsModal .taskName .title,
#approvalsModal .dateCompleted .title {
    font-weight: bold;
}

#approvalsModal .taskName, 
#approvalsModal .dateCompleted {
    margin-top: -.2rem;
}

/* approve/completed selector */
#approvalsModal .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

#approvalsModal .deny {
    background: none;
    border: none;
    font-size: 16px;
    margin: .75rem 3rem 0rem;
    text-decoration: underline;
}


/* Some extra styling on the selector */
#approvalsModal .completedSelector {
    margin-bottom: .4rem;
}

#approvalsModal .mCSB_container {
    left: 0px!important;
    width: 100%!important;
}