student-tiles div.tile {
    width: 22.375%;
    /* max-width: 22em;*/
    height: 300px;
    background-color: #ffffff;
    margin-right: 3%;
    margin-bottom: 1.5%;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    border: 1px solid #00375D
}

student-tiles div.rowEnd {
        margin-right: 0;
    }

student-tiles .see-more-section {
    display: flex;
    flex-direction: column;
    align-items: center
}

student-tiles .see-more-section > a {
    text-decoration: none;
    font-weight: bold;
}

student-tiles .align-glyph {
    padding-left: 5px;
    vertical-align: text-bottom;
}

student-tiles .scrollDown{
        background-color: white;
        border: 1px solid #00375d;
        width:98.5%;
        margin-bottom:1.5%;
}

student-tiles .infoSection{
    padding-left: 0px;
    padding-top: 4rem;
    margin-left:10px;
    margin-bottom: 4px;
    color: black;
    font-size: 14px;
    list-style: none;
}

student-tiles .descriptionSection{
    margin-left:10px;
    margin-bottom:10px;
}
student-tiles .detailsTopContainer{
    width:100%;
}

student-tiles .duedate {
    color: #00375D;
}

student-tiles .duedate-overdue {
    color: #f63f3f;
}

student-tiles .duedate-duesoon {
    color: #FFCC00;
}
student-tiles .offerings {
    margin-left:10px;
}
student-tiles .courseGroup{
    background-color:#FFFFFF;
}
student-tiles .courseGroupContainer{
    width:98.5%;
    margin-bottom:20px;
    border: 1px solid #00375d;
}
student-tiles .courseGroupContainer .dueDate {
margin-left:10px;
box-sizing:border-box;
color:rgb(0, 55, 93);
display:inline;
font-family:Verdana, Arial, sans-serif;
font-size:12px;
height:auto;
line-height:17.1429px;
text-size-adjust:100%;
width:auto;
}

student-tiles .courseGroupContainer .mainImg {
    width: 100%;
}

student-tiles .courseGroupContainer .info {
    padding-left: 1rem;
    padding-bottom:15px;
}

student-tiles .courseGroupContainer .assignmentName {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 16px;
}
student-tiles .courseGroupContainer h3 {
    margin: 0px 0px 15px;
}

student-tiles .courseGroupContainer.courseMeta {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
    flex-wrap: wrap;
}

student-tiles .courseGroupContainer .courseMetaContainer{
    margin-bottom:3rem;
}

student-tiles .courseGroupContainer .imageDate{
    padding-left:0px;
    padding-right:0px;
}

student-tiles .courseGroupContainer .courseMeta span:nth-last-child(n+2):after  {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

student-tiles .courseGroupContainer .title{
        margin-left: 15px;
        padding-left: 0px;
        width:70%;
}

student-tiles .courseGroupScroll{
    margin-left:1%;
    width:98%;
}

student-tiles .resourceHeaders{
    padding-top:4rem;
}
student-tiles .resourceName{
    width:90%;
}

student-tiles .resourceNameDetails{
    width:90%;
    overflow-wrap:break-word;
}