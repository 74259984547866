html {
    height: 100%;
}

body {
    background-color: #FFF;
    color: #000;
    font-family: 'Verdana', 'Arial', 'sans-serif';
    font-size: 12px;
    overflow: -moz-scrollbars-vertical;
    position: relative;
    width: 100%;
    height: auto !important;
}

h2 {
    color: black;
    font-size: 44px;
    font-family: 'Rockwell', 'Verdana', 'Arial', 'san-serif';
}

.bold {
    font-weight: bold;
}

.whitebg {
    background-color: #fff;
}
.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.red {
    color: red;
}

.timezone-stamp {
    font-size: 0.8em;
}

.adminoverlay {
    position:absolute;
    top:0; left:0; bottom:0; right:0;
    margin:0;
    padding:0;
    background:black;
    color:white;
    opacity:.25;
    width:100%;
    height:100%;
    display: block;
    z-index: 998;
}

 a[disabled], a[disabled]:hover {
     pointer-events: none;
     cursor: not-allowed;
     
 }
/* color backgrounds of non-production environments to avoid confusion */
.lmgr-dev {
	background-color: #fdd;
    background: linear-gradient(#fdd,#fff 10%,#fff 90%,#fdd) no-repeat fixed;
}
.lmgr-qa {
    background-color: #dfd;
	background: linear-gradient(#dfd,#fff 10%,#fff 90%,#dfd) no-repeat fixed;
}
.lmgr-uat {
    background-color: #ddf;
	background: linear-gradient(#ddf,#fff 10%,#fff 90%,#ddf) no-repeat fixed;
}
.not-production {
    background-color: #ffd;
	background: linear-gradient(#ffd,#fff 10%,#fff 90%,#ffd) no-repeat fixed;
}

.ng-hide.ng-hide-animate {
display: none !important;
}

.trainingInsightsNew
{
    height:47px;
    z-index:10;
    position:absolute;
    left: 78%;
    top: -20%;
}

.hidden-feature {
    display: none;
}