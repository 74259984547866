application-library-menu #root div {
  width: 100%;
}

application-library-menu button.dp-btn {
  position: absolute;
  left: 69.2vw;
  background: white;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  border: 0.1px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 225px;
  height: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

application-library-menu button.dp-btn > span {
  margin-bottom: 0;
  border: 1px solid transparent;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: normal;
  vertical-align: middle;
  color: black;
}

application-library-menu .dropdown {
  position: absolute;
  display: inline-block;
}

application-library-menu .dropdown-content {
  position: absolute;
  left: 69.2vw;
  margin-top: 40px;
  background-color: white;
  min-width: 290px;
  height: 105px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
}

application-library-menu .dropdown-content div {
  color: black;
  padding: 12px;
  min-height: 35px;
  padding-left: 10px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

application-library-menu .dropdown-content div:hover {
  background-color: #f1f1f1;
}

application-library-menu .dropdown-content a {
  text-decoration: none;
  font-size: 14px;
  color: black;
}

application-library-menu .dropdown-content span {
  color: #ff0000;
  right: 0;
  font-weight: 700;
  font-size: 12px;
  cursor: inherit;
}

application-library-menu #svg-grid {
  fill: #1eabe3;
}

application-library-menu #svg-arrows {
  fill: #1eabe3;
}
