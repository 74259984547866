/* This file contains overrodes for various browsers.
 *
 * Anything that's site wide but doesn't fit in the common.css file
 * Stuff like overriding yellow autofill garbage in chrome or
 * a fix for IE.
 * */

/* get rid of the yellow autofill crap in chrome */
input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
} 

/* Everything past this point is dragons and will need to be refactored */
/* I think the four lines below can be simplified to just "a," because the comma-separation uses OR logic, not AND logic */
a,
a.md-default-theme,
a.md-default-theme:not(.md-button),
a:not(.md-button) {
    color: #505050;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
}

a:hover {
    color: #A1A1A1;
}

/*
    Generic errors
*/
.noResults {
    padding: 45px 0px 0px;
    text-align: center;
}

.noResults h2 {
    color: #e32b1e; /* error color */
    font-size: 32px;
    font-weight: bold;
}

/**
* Overrides
*/
.no-cursor {
    cursor: default!important;
}

.alert-danger {
    color: #000;
    font-weight: bold;
    font-size: 14px;
    margin: 1rem 0rem;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.table > thead > tr > th {
    border: none;
}

.italic {
    font-style: italic;
}
