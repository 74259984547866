div#student-no-required-assignments {
    display: flex;
    align-content: center;
    justify-content: center;
}

    div#student-no-required-assignments .message-container {
        flex-wrap: wrap;
        align-self: center;
    }

    div#student-no-required-assignments .message-title {
        color: white;
        font-weight: bold;
        font-size: 22px;
        align-self: center;
    }

    div#student-no-required-assignments .message-link {
        color: white;
        font-size: 14px;
        align-self: center;
    }
