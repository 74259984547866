/**** All tooltips go here. Including confirmation tooltips ****/

/* The confirmation tooltip used in TA/premiumAdmin section */
.confirmSave {
    position: absolute;
    background-color: white;
    top: 15rem;
    height: 14rem;
    width: 20rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    -webkit-box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.32);
    box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.32);
}

.confirmWaive {
    height: 19rem !important;
}

.confirmReactivate {
    height: 16rem !important;
}

/* Confirm save content */
.confirmSave .options {
    margin-top: 2rem;
}

.confirmSave .option a,
.confirmSave .option input.confirmSaveYes {
    color: gray;
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    padding: 0px;
}

.confirmSave .options .option + .option:before {
    /* Pipe bars between course meta items*/
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

.confirmSave .message {
    text-align: center;
}

.confirmSave .options {
    display: flex;
    justify-content: center;
}

/* Arrow at top of tooltip */
.confirmSave:after, .arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.confirmSave:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: white;
    border-width: 1rem;
    margin-left: calc(-10px);
}

.confirmSave:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #c2e1f5;
    border-width: 36px;
    margin-left: -36px;
}

.confirmCancel {
    margin-left: -50px;
}

.confirmSaveUser {
    margin-left: 250px;
    z-index: 3 !important;
}

.confirmAddUser {
    margin-left: 36px;
    z-index: 3 !important;
}

/* The loading-spinner has a 100px margin by default which
 * makes it appear outside of the tooltip. This fixes the spinner
 * in this specific case */
.confirmSave .spinner {
    margin: 34px auto;
}

.removeCourseContainer {
    position: relative;
}
.confirmRemove {
    position: absolute;
    top: auto;
    right: 1.5rem;
    width: 10rem;
}


.confirmMarkSuccessful{
    top: auto;
    width: 25rem;
    height: 5rem;
    margin: 1rem;
    margin-left: -10rem;
}

.confirmMarkSuccessful .options {
    margin-top: 0;
}

.confirmCloseSelectedUsers {
    top: 5rem;
    left: 68rem;
}
    