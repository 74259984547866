.offeringModal .row {
    margin-bottom: 45px;
}

.offeringModal .row.buttons {
    height: 45px;
    margin-bottom: 0px;
}

.offeringModal .row:last-child {
    margin-bottom: 0px;
}

.offeringModal .row.searchForm .col-md-2 {
    padding: 0px;
}

.offeringModal .row.results {
    background-color: #fff;
    margin: 0px 0px 15px;
    padding: 15px;
}

.offeringModal .row.results h3 {
    margin: 0px 0px 45px;
}

.offeringModal .row.results ul {
    padding: 0px;
}

.offeringModal .row.results ul li {
    background-color: #F0F0F0;
    list-style: none;
    padding: 15px 15px 15px 40px;
}

.offeringModal .row.results ul li {
    list-style: none;
}

.number-students-selected {
    font-size: 13px;
    padding-top: 11px;
}

.modal.rosterModal .modal-dialog {
    max-width: 1500px;
    width: 1300px;
}

.rosterModal md-content {
    overflow: hidden;
}

.rosterModal .roster-detail-link {
    display: none;
}

.rosterModal md-tab-content
 {
    min-height: 400px;
    background-color: #fff;
}