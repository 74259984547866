bai-catalog-search-results .divider {
    border-top: 1px solid black;
}

bai-catalog-search-results p.large {
    font-size: 16px;
    font-weight: 700;
}


bai-catalog-search-results p.medium {
    font-size: 14px;
}

bai-catalog-search-results p.small {
    font-size: 12px;
    margin-bottom: 5px;
}


bai-catalog-search-results p.title {
    color: #0076A8;
}

bai-catalog-search-results p.title a.course-title {
    color: #0076A8;
    text-decoration: none;
}

bai-catalog-search-results p.title a:hover {
    text-decoration: underline;
}

bai-catalog-search-results label {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
}

bai-catalog-search-results label.facet-header {
    font-weight: bold;
    margin-bottom: 5px;
}

bai-catalog-search-results a.view-all {
    font-size: 14px;
    font-weight: normal;
    color: #00A1DE;
    padding-left: 1em;
    text-decoration: none;
}
bai-catalog-search-results a:hover.view-all {
    text-decoration: underline;
}

bai-catalog-search-results a.clear-all {
    font-size: 14px;
    font-weight: normal;
    color: #00A1DE;
    padding-left: 1em;
    text-decoration: none;
    float: right;
    line-height: 1.5;
}

bai-catalog-search-results a:hover.clear-all {
    text-decoration: underline;
}

bai-catalog-search-results .small-menu-down-up {
    padding-left: 0; font-size: 10px;
}