#addAssignmentsModal .filterRow {
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
}
#addAssignmentsModal .filterRow .keyword {
    padding-left: 1rem;
    padding-bottom: .2rem;
    padding-top: .3rem;
    width: 60%;
    font-size: 14px;
    margin-right: 1rem;
}
#addAssignmentsModal .filterRow .lookIn {
   
}
#addAssignmentsModal .filterRow .lookIn select {
    border: 1px solid black;
}

/* Keyword search results title */
#addAssignmentsModal .searchResultsTitle {
    font-style: italic;
    font-size: 16px;
    margin-left: 30px;
    margin-top: 1.5rem;
}

/* Assignment names selection */
#addAssignmentsModal .selectAssignments {
    background-color: white;
    margin-top: 1rem;
    margin-left: 15px;
    overflow-y: scroll;
    max-height: 240px;
}
#addAssignmentsModal .selectAssignments .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 1rem;
    margin-left: 30px;
}
#addAssignmentsModal .selectAssignments .title.error {
    color: #e32b1e;
    font-weight: bold;
    font-size: 14px;
    padding-top: 1rem;
}

#addAssignmentsModal .selectAssignments p {
    font-size: 12px;
    font-weight: normal;
    margin-left: 30px;
}
#addAssignmentsModal .selectAssignments p.error {
    color: #e32b1e;
    font-weight: bold;
    font-size: 14px;
    padding-top: 1rem;
}

#addAssignmentsModal .selectAssignments .list {
    list-style: none;
    padding-left: 2rem;
    margin-left: 30px;
    margin-top: 1rem; /* override a bootstrap class */
}

#addAssignmentsModal .selectAssignments li {
    margin-top: 1.5rem;
}
#addAssignmentsModal .selectAssignments label {
    font-weight: normal;
    padding-left: 2rem;
}

#addAssignmentsModal .selectAssignments .selectAll {
	
	font-size: 12px;
}

/* On the audience tab, compress things in sublists */
#addAssignmentsModal .selectAssignments .list.subList {
    margin-top: 0;
}
/* On the audience tab, compress things in sublists */
#addAssignmentsModal .selectAssignments .list.subList li {
    margin-top: 0;
}

.nav-pills.nav-wizard {
    margin-top: 2rem;
    margin-left: 15px; 
}
.nav-pills.nav-wizard > li {
  position: relative;
  overflow: visible;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  width: 33%;
}
.nav-pills.nav-wizard > li + li {
  margin-left: 3px;
  width: 33%;
}
.nav-pills.nav-wizard > li:first-child {
  border-left: 0;
}
.nav-pills.nav-wizard > li:first-child a {
  border-radius: 5px 0 0 5px;
}
.nav-pills.nav-wizard > li:last-child {
  border-right: 0;
}
.nav-pills.nav-wizard > li:last-child a {
  border-radius: 0 5px 5px 0;
}
.nav-pills.nav-wizard > li a {
  border-radius: 0;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 5px;
  color: #d4d4d4;
  text-align: center;
  font-size: 12px;
}
.nav-pills.nav-wizard > li .nav-arrow {
  position: absolute;
  top: 0px;
  right: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 14px 0 14px 20px;
  border-color: transparent transparent transparent #fff;
  z-index: 150;
}
.nav-pills.nav-wizard > li .nav-wedge {
  position: absolute;
  top: 0px;
  left: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 14px 0 14px 20px;
  border-color: #fff #fff #fff transparent;
  z-index: 150;
}
.nav-pills.nav-wizard > li:hover .nav-arrow,
.nav-pills.nav-wizard > li.completed .nav-arrow {
  border-color: transparent transparent transparent #aaa;
}
.nav-pills.nav-wizard > li:hover .nav-wedge,
.nav-pills.nav-wizard > li.completed .nav-wedge {
  border-color: #aaa #aaa #aaa transparent;
}
.nav-pills.nav-wizard > li:hover a,
.nav-pills.nav-wizard > li.completed a {
  background-color: #aaa;
  color: black;
}
.nav-pills.nav-wizard > li.active .nav-arrow {
  border-color: transparent transparent transparent #22abe9;
}
.nav-pills.nav-wizard > li.active .nav-wedge {
  border-color: #22abe9 #22abe9 #22abe9 transparent;
}
.nav-pills.nav-wizard > li.active a {
  background-color: #22abe9;
  text-align: center;
  color: #fff;
}

/* Calendar picker styling on due date tab */
div.md-datepicker-calendar-pane {
    z-index: 1050 !important;
}
body.md-datepicker-is-showing {
    overflow: visible !important;
}

/* The footer */
#addAssignmentsModal .footer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: 4rem;
}
#addAssignmentsModal .footer button {
    margin-left: 1.5rem;
    margin-right: 15px;
    border: 2px solid white;
    border-radius: 13px;
}

#addAssignmentsModal .messaging {
    width: 100%;
    padding: 0px 15px;
    margin: 0;
}