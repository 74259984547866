.container-header {
    margin-top: 15px; 
    margin-bottom: 15px;
}

.container-header .lock-icon {
    height: 22px;
    width: 18px;
    position: absolute;
    top: 9px;
}

.container-operand-wrapper {
    padding-right: 0px; 
    padding-left: 15px; 
    position: relative; 
    display: inline-block; 
    vertical-align: middle;
    /*color: white;*/
    font-weight: bold;
}

.container-operand-wrapper select {
    border-radius: 0px; 
    background-color: #7a7a7a;
    color: white;
    -webkit-appearance: none !important;
}

.container-operand-wrapper select option {
    color: white;
}

.container-title {
    margin-top: 10px !important; 
    margin-bottom: 10px !important; 
    font-weight: bold;
    margin-left: 25px !important;
}

.container-title-no-icon {
    margin-top: 10px !important; 
    margin-bottom: 10px !important; 
    font-weight: bold;
}

.container-wrapper {
    margin-bottom: 15px; 
    margin-left: 0px; 
    margin-right: 0px;
}

.label-wrapper {
    top: 7px; 
    font-size: 14px;
    padding-left: 0px;
    padding-right: 0px;
}

.select-wrapper {
    padding-right: 0px;
    padding-left: 0px;
    position: relative; 
    display: inline-block; 
    vertical-align: middle;
}

/*Styles change for the dropdown in search filed - Start*/
.select-wrapper-input {
    position: relative; 
    display: inline-block; 
    vertical-align: middle;
}

.select-wrapper-input .dropdown-caret-wrapper {
    right: 15px;
}

/*Styles change for the dropdown in search filed - End*/

.select-wrapper .select-prop {
    border-radius: 0px;
    -webkit-appearance: none !important;
}

.select-wrapper .select-prop-sm {
    padding-top: 0px;
    -webkit-appearance: none !important;
}

.select-prop-med{
    width: 160px !important
}

.dropdown-caret-wrapper {
    position: absolute;
    top: 0;
    right: 0px;
    display: block;
    width: 35px;
    height: 42px;
    line-height: 35px;
    text-align: center;
    pointer-events: none; 
    border-left: 1px solid #ccc;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: white;
}

.dropdown-caret-wrapper-sm {
    width: 30px !important;
    height: 30px !important;
    line-height: 20px !important;
}

.dropdown-caret-wrapper span {
    display: block; 
    margin-top: 3px;
}

.dropdown-caret-wrapper span img {
    height: 20px;
}

.dropdown-caret-wrapper-profile {
    width: 42px !important;
    line-height: 10px !important;
}

.action-wrapper {
    padding-top: 4px; 
    padding-left: 0px;
    bottom: 3px;
}

.action-wrapper span {
    padding-right: 5px;
}

.action-wrapper span img {
    height: 38px;
}

.container-action-wrapper {
    margin-bottom: 15px; 
    padding-right: 15px;
}

.container-action-wrapper .pull-right {
    /*margin-right: 2rem;*/
}

.container-action-wrapper .add-child {
    margin-right: 10px; 
    font-size: 14px; 
    font-weight: bold;
    color: #7a7a7a;
}

.container-action-wrapper .add-child span {
    text-decoration: underline;
}

.container-action-wrapper .remove-child img {
    height: 25px;
}

.childContainer {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    /*padding-top: 15px; */
    background-color: white; 
    /*padding-bottom: 15px;*/
}

/*.childContainer:hover {
    border: 1px solid #7f7f7f;
}*/

.childContainer-Color {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    /*padding-top: 15px; */
    background-color: #f0f0f0; 
    /*padding-bottom: 15px;*/
}

#childContainer #childContainer {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: #f0f0f0; 
}

#childContainer #childContainer #childContainer {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: white; 
}

#childContainer #childContainer #childContainer #childContainer {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: #f0f0f0; 
}

#childContainer #childContainer #childContainer #childContainer #childContainer {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: white; 
}

#childContainer #childContainer #childContainer #childContainer #childContainer #childContainer{
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: #f0f0f0; 
}

#childContainer #childContainer #childContainer #childContainer #childContainer #childContainer #childContainer{
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 0px; 
    margin-top: 15px; 
    background-color: white; 
}

