.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

label.inputLabel {
    padding-left: 0;
}

.inputfile + button {
    display: inline-block;
    background-color: #A4A4A4;
}
.inputfile + button:hover, 
.inputfile + button:focus {
    background-color: #A4A4A4;
}

.inputfile ~ .inputfilePath {
    display: inline-block;
    width: 74%;
    padding-top: 9px;
    padding-bottom: 10px;
    padding-left: 5px;
}

