/**** Transcripts page css ****/

/** The add/print stuff in the top right **/
#transcripts .additionalOptions {
    text-align: right;
    margin-left: auto; /* Make this aligned to the right */
}

/* Put a pipe character between the add / print buttons */
#transcripts .additionalOptions span {
    font-size: 20px;
}

#transcripts .additionalOptions span + span:before {
    content: "|";
    color: black;
    /* Typography for pipe between add and print actions */
    padding-left: 1rem;
    padding-right: 1.25rem;
}

#transcripts .datePickerHolder .datePickerHolderInner {
    margin-top: 1.75rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid #e5e5e5;
}

#transcripts .datePickerHolderInner .selectText {
    width: 200px;
    margin-right: 0 !important;
}

#transcripts .datePickerHolderInner #divLmsDatetime {
    width: 160px !important;
}
/* Styling/layout for the separator text between the date pickers */
#transcripts .datePickerHolder .separatorText {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    width: auto !important;
}

/* The error in case the start is greater than end date */
#dateRangeError {
    float: right;
    margin: 4px 0px;
    padding: 5px;
}

/** Transcripts table **/
/* Row formatting in the table.
 * This currently also formats header row.
 * Should be refactored
 */

/* table header formatting */
#transcripts .table thead th {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 16px;
}

/* Format all the headers in the contents of each column */
#transcripts .table tr {
    border-bottom: 1px solid #e5e5e5;
}

/* Format all the headers in the contents of each column */
#transcripts .table tr td h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: .7rem;
    margin-top: 1rem;
}

/*
 * Make the left column (the title column) of the table line up with the page
 */
#transcripts .table .titleColumnHeader,
#transcripts .table .titleColumn {
    /* This column should take up the most space in the table */
    width: 42%;
}
/* format the contents of the left column (title column) */
#transcripts .table tr td p {
    font-size: 14px;
}

/*
 * Make the center column, the assignment type column, relatively small
 */
#transcripts .table .typeColumnHeader,
#transcripts .table .typeColumn  {
    width: 17%;
}

#transcripts .table .codeColumnHeader,
#transcripts .table .codeColumn  {
    width: 17%;
}

/*
 * Make sure the last column, the status column,
 * is generally near the right side of the page
 */
#transcripts .table .statusColumnHeader,
#transcripts .table .statusColumn {
    /* Giving this element an rem instead of a percentage
     * makes it easier to make it somewhat flush right */
    width: 27rem;
}
/* Format the contents of the status column */
#transcripts .table .statusColumn h3 {
    font-style: italic;
}
#transcripts .table .statusColumn a {
    font-size: 14px;
    color: black;
}

/**** Transcripts add modal specific styling ****/
.addTranscriptInputField::placeholder {
    color: #A9A9A9 !important;
}

/* Transcript add modal date picker */
.transcriptAddModal #divLmsDatetime {
    border: 1px solid black;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


/* Show a custom calendar icon and position it over the original bootstrap calendar icon */

/* Make the original bootstrap calendar icon/button transparent */
.transcriptAddModal .datePicker .md-datepicker-calendar-icon {
    fill: rgba(0,0,0,0);
}

/* Make it so we can position the .calendarIcon absolutely */
.transcriptAddModal .datePicker {
    position: relative;
}
/* Resize the transparent clickable button */
.transcriptAddModal .datePicker .md-button.md-icon-button {
    margin: 0;
}
/* Put the calendar icon over the original datepicker button */
.transcriptAddModal .datePicker .calendarIcon {
    background: url('../../App/images/icons/transcripts_calendar.svg');
    width: 2rem;
    height: 2rem;
    background-size: contain;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 99999;
}

/* Make the triangle on the right hand side of the datepicker box larger */
.transcriptAddModal .datePicker .md-datepicker-expand-triangle {
    top: 65%;
    left: 16%;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #b6b6b6;
}

/* Make the datepicker box close to the calendar icon */
.transcriptAddModal .datePicker .md-datepicker-input-container {
    margin-left: 0;
    background-color: white;
}

/* Get the two calendar pickers side by side */
#transcriptAddModal .startDateGroup,
#transcriptAddModal .completionDateGroup,
#peopleTabBatchAddTranscriptModal .startDateGroup,
#peopleTabBatchAddTranscriptModal .completionDateGroup {
    display: inline-block;
}

#batchAddTranscript
{
    padding-left: 15px;
}

.ta .batch-resultsList {
    display: flex;
    flex-direction:column;
    align-items: center;
}

/* Normally this is supposed to be prefixed with
 * #transcriptsAddModal however the transcripts calendar
 * is placed outside of the modal element since
 * it's dynamically created by bootstrap. */
#transcripts ~ div.md-datepicker-calendar-pane {
    z-index: 10000;
}

/**** View Certificate modal ****/
/* This part formats the area right around the image of
 * the certificate in the modal body */
#certificateModal .certInfo {
    float: none;
    margin: 15px auto;
    width: 806px;
}