/* Premium admin modals are more gray instead of white on black */
.taModal.modal .modal-body input[type='text'],
.taModal.modal .modal-body textarea,
.taModal.modal .modal-body md-datepicker-input {
    color: black;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0, .2);
    width: 100%;
}
/* color the default text in the modal input boxes */
.taModal.modal .modal-body input[type='text']::-webkit-input-placeholder,
.taModal.modal .modal-body textarea::-webkit-input-placeholder,
.taModal.modal .modal-body .md-datepicker-input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: gray;
}
.taModal.modal .modal-body input[type='text']:-moz-placeholder,
.taModal.modal .modal-body textarea:-moz-placeholder,
.taModal.modal .modal-body .md-datepicker-input:-moz-placeholder {
   /* Mozilla Firefox 4 to 18 */
   color: gray;
   opacity:  1;
}
.taModal.modal .modal-body input[type='text']::-moz-placeholder,
.taModal.modal .modal-body textarea::-moz-placeholder,
.taModal.modal .modal-body .md-datepicker-input::-moz-placeholder {
   /* Mozilla Firefox 19+ */
   color: gray;
   opacity:  1;
}
.taModal.modal .modal-body input[type='text']:-ms-input-placeholder,
.taModal.modal .modal-body textarea:-ms-input-placeholder,
.taModal.modal .modal-body .md-datepicker-input:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   color: gray;
}

/* Form groups are packed tighter together on preimum admin modals */
.taModal.modal .form-group {
    margin-bottom: 10px;
}

.taModal.modal .form-control {
    height: 30px;
}

/* sub Title right below big bold title. allows for status */
.taModal.modal .subTitle {
    font-size: 14px;
}

/* Make down arrow fit in a smaller select container */
.taModal.modal .selectContainer .downArrow {
    height: 30px;
    margin-top: 1px;
    border-left: 1px solid #c5c5c5;
}
.taModal.modal .selectContainer .downArrow img {
    margin-top: 10px;
}

.ta-domain-switcher {
    margin: 5px 0;
}
/*
.taModal.modal .md-datepicker-input {
    background-color: white;
    border: 1px solid rgba(0,0,0, .2);
    border-radius: 2px;
}
.taModal.modal .md-datepicker-input-container {
    border-bottom-style: initial;
}
*/

