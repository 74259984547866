#student-footer {
    background-color: #00375D;
    border: 1px solid #0579AB;
    color: white;
    padding: 20px;
}

#student-footer #footer-message {
    font-family: Rockwell, Verdana, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

    #student-footer button {
    border: 2px solid #1EABE3;
    color: #1EABE3;
    align-content: center;
    background-color: white;
    font-size: 14px;
    padding: 0.4em 0.6em 0.4em 0.6em;
    }

.center-this {
    display: flex;
    justify-content: center;
    align-items: center;
}
