/* header */


/* profile top right links for edit / change password */
#account .profileManagment {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-top: 3.5rem;
    padding-bottom: 1rem;
}
#account .profileManagment a {
    color: black;
    padding-right: 3rem;
    padding-top: 11px;
}
#account .profileManagment a:last-child {
    padding-left: 3rem;
}

/* Profile info left column */
#account .profileInfo .profileInfoLeftColumn {
    width: 35%;
    padding-left: 2.5rem;
    padding-top: 3rem;
    border-right: 4px solid white;
    float: left;
    height: 34rem;
    background-color: #B9B7B8;
    font-family: 'Rockwell', 'Verdana', sans-serif;
}
#account .profileInfo .profileInfoLeftColumn > * {
    /* All elements in left column always vertical */
    display: block;
}

#account .profileInfo .profileInfoLeftColumn h3 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
}

#account .profileInfo .profileInfoLeftColumn h4 {
    margin-top: 0;
    font-size: 26px;
}

#account .profileInfo .profileInfoLeftColumn .email {
    font-size: 22px;
}


/* Profile info right column */
#account .profileInfo .profileInfoRightColumn {
    background-color: #E5E5E5;
    float: right;
    width: 65%;
    height: 34rem;
    padding-top: 2rem;
    padding-left: 3rem;
}
#account .profileInfo .profileInfoRightColumn > * {
    /* All elements in left column always vertical */
    display: block;
}

/* Profile info Right column layout */
#account .profileInfo .profileInfoRightColumn .profileInfoLine {
    display: flex;
    justify-content: flex-start;
    padding-left: 1.5rem;
    padding-top: .7rem;
    font-size: 22px;
}

/* Profile info Right column styling */
#account .profileInfo .profileInfoRightColumn .profileInfoLine label {
    font-family: 'Rockwell', 'Verdana', sans-serif;
    font-weight: normal;
    width: 17rem;
}

#account .profileInfo .profileInfoRightColumn .profileInfoLine span {
    /* Prevent overflow of text */
    font-family: 'Verdana', sans-serif;
    width: 65%;
    padding-top: 0.25rem;
    font-size: 18px;
}

#account .pwdChgHdr {
    display: flex;
    background-color: #e5e5e5;
    width: 100%;
    padding-bottom: 1.5rem;
}

/* Password change header left column */
#account .pwdChgHdr .pwdChgHdrLeftColumn {
    width: 50%;
    padding-left: 2.5rem;
}
#account .pwdChgHdr .pwdChgHdrLeftColumn h3 {
    font-family: 'Rockwell', 'Verdana', sans-serif;
    font-size: 26px;
    margin-bottom: 0;
}
#account .pwdChgHdr .pwdChgHdrLeftColumn h4 {
    font-family: 'Verdana', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin-top: .5rem;
}

/* Password change / edit profile */
#account .pwdChgHdr .pwdChgHdrRightColumn {
    margin: auto;
    width: 50%;
    text-align: right;
    padding-right: 1.5rem;
    margin-top: 2.5rem;
}

#account .pwdChgFlds {
    display: flex;
    padding-left: 1rem; /* awful. this is indenting the password
    * change fields by 1rem + 15px for the parent bootstrap row element */
    padding-top: 2rem;
    background-color: #f9f9f9;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid #e5e5e5;
}

/* Edit profile headers on left & right columns */
#account .pwdChgFlds h4 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1.5rem;
}

/* Edit profile left column */
#account .pwdChgFlds label {
    font-weight: normal;
}

#account .profileHolder{
    border-bottom:2px solid #e5e5e5;
}

/* The little arrow at the end of the select box */
#account .profileHolder .profileFieldSelectContainer {
    position: relative;
}

#account .profileHolder .profileFieldSelectContainer select {
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;
}

#account .profileHolder .profileFieldSelectContainer select::-ms-expand {
    display: none;
}

#account .profileHolder .profileFieldSelectContainer .downArrow {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid black;
    pointer-events: none;
    height: 2.7rem;
    margin-top: 4px;
}

#account .profileHolder .profileFieldSelectContainer img {
    width: 25px;
    height: 25px;
    margin-top: 6px;
    margin-right: 8px;
    margin-left: 7px;
}

/* Divide the city, state and postal code into 3 side by side boxes */
#account .addrCityStatePostalCode {
    display: flex;
    justify-content: space-between;
}
#account .addrCityStatePostalCode div {
    width: 32%;
}

/* Edit profile right column */
#account .pwdChgFldsRightColumn {
    padding-left: 1.5rem;
}

/* top/bottom margin between each input file in edit profile 
 * and change password */
#account .profileHolder input, #account .profileHolder select {
    margin-bottom: 15px;
}

/* Legacy password stuff */
#account .passwordHolder input {
    border: 1px solid #000;
    margin-bottom: 15px;
}

#account .passwordHolder a, #account .profileHolder a {
    margin-right: 3rem;
}

#account .passwordHolder button {
    width: 146px;
    border-radius: 13px;
    padding: 10px 8px;
    border: 2px solid white;
    margin-right: 2.5rem;
}

#account .profileHolder button {
    width: 150px;
    border: 2px solid #fff;
    padding: 10px 45px;
    margin-right: 2.5rem;
}

#account #stateSelector {
    width: 100%;
}

#account .summaryBox {
    width: 26rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#account .summaryBoxContent {
    width: 26rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
    border: 1px solid black;
    padding-left: 1rem;
}

#account .summaryBox .amount {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
}

#account .summaryBox .title{
    font-size:14px;
}

#account .teal {
    border-color: #00A599;
    color: #00A599;
}

#account .purple {
    border-color: #80379B;
    color: #80379B;
}

#account .green {
    border-color: #92D400;
    color: #00A599;
}

#account .orange {
    border-color: #E87722;
    color: #E87722;
}

#account .yella {
    border-color: #EBB700;
    color: #EBB700;
}

#account .baiBlue {
    border-color: #00A1DE;
    color: #00A1DE;
}

#account .completionsGroup {
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    float: right;
    margin-right: 20px;
}

#account .completionsGroup .summaryBox {
    margin-bottom:20px;
}

#account .spinnerLoading .spinner {
    margin: 0px !important;
    height: 20px !important;
    width: auto !important;
    text-align: left !important;
}

#account .orange .spinner > div {
    background-color: #E87722 !important;
}

#account .purple .spinner > div {
    background-color: #80379B !important;
}

#account .teal .spinner > div {
    background-color: #00A599 !important;
}

#account .baiBlue .spinner > div {
    background-color: #00A1DE !important;
}

.row.achievementsGroup {
    justify-content: space-around;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

#account .badgesEarned {
    font-weight: bold;
    text-align: center;
    font-size: 14px;    
    padding-bottom: 15px;
    padding-top: 5px;
}

#account .badgeDisplayed {
    padding-bottom: 15px;
    padding-left:0px;
    padding-right:0px;
}

#account .badgeDisplayed img {
    height: 100px;
    margin-bottom: 5px;
}

#account .badgeDisplayed div {
    width: 100%;
}

#account .display-flex {
  display: flex;
  flex-wrap: wrap;
}