/* Whole background of page should be mostly gray */
.ta {
    background-color: #eeeeee;
}

/* For the templates/users/home/admin.html - updated styles - Start*/
.ta-logout-search {
    margin-top: 74px;
}

.ta .header-block{
    /*padding-left: 3px;*/
}

.ta .header-block {
    padding: 10px 15px;
}

.ta .header-links {
    margin-top: 15px;
}

.ta .header-block .header-navbar a {
    text-decoration: none;
    color: #8e8e8e;
}

.ta .header-block .header-navbar .tab-active-class {
    font-weight: bold;
    color: white !important;
    background-color: grey ;
}

.ta .header-block .header-navbar a:hover {
    text-decoration: none;
    color: white;
}

.ta .header-block .header-navbar a:focus {
    color: white;
    background-color: grey ;
    font-weight: bold;
    /*border-color: #494F57;*/
}

.ta .header-block .header-navbar .header-links {
    padding-right: 15px;
}

.ta .header-block .header-links .link-separator {
    padding: 0px 15px;
}

.ta .header-block .header-links .link-right {

    padding-right: 0px;
}

.ta .header-block .header-links a {
    font-size: 14px !important;
}

.ta .content-block {
    /*padding-left:0px;
    padding-right:0px;*/
}

.ta .content-block .expand-block {
    height: 25px;
    background-color: #E5E5E5;
    width: 100%;
    margin-bottom: 3px;
}

.ta .content-block .expand-block a {
    float: right;
    padding-right: 5px;
    color: #919191;
}

.ta .content-block .expand-block a:hover {
    color: #1d1d1d;
    text-decoration: none;
}

.ta .content-block .expand-block span {
    font-size: 12px;
}

.ta .content-block .tree-block {
    /*height: 56vh; */
    /*overflow-y: scroll;*/
}

.ta .content-block .navbar-default {
    border-radius: 0px;
    box-shadow: 0px 3px 10px #a6a6a6;
    display: flex;
    margin-bottom: 2px;
    position: relative;
}

.ta .content-block .navbar-default .search-block {
    display: inline-block;
    padding: 0px 15px;
    width: 100%;
}

.ta .content-block .navbar-default .navbar-form {
    display: flex;
    padding: 10px 5px 13px;
    position: relative;
    width: 100%;
}

.ta .content-block .navbar-default .navbar-form span {
    height: 35px;
}

.ta .content-block .navbar-default .navbar-form .icon {
    height: 26px;
    margin: 5px 0px 0px 7px;
    width: 30px;
    position: absolute;
    right: 7px;
}

.ta .content-block .navbar-default .navbar-form .img-adduser {
    height: 30px;
}

.popoverHtml {
    min-width: 140px;
    text-align: center;
    padding-top: 15px;
    margin-bottom: 15px;
    z-index: 999;
}

.popoverHtml .action-links {
    padding-top: 15px;
}

.popoverHtml .action-links span{
    padding-right: 15px;
    padding-left: 15px;
}

.increase-popover-width {
   max-width: 400px;
}

.ta .content-block .navbar-default .navbar-form .img-adddocument {
}

.ta .content-block .navbar-default .left-form input {
    padding: 10px 10px 10px 38px;
}

.ta .content-block .navbar-default .search-block {
    display: inline-block;
    width: 100%;
}

.ta .content-block .navbar-default .right-form {
    float: left;
}

.ta .content-block .navbar-default .right-search {
    min-width: 375px;
    height: 100%;
}

.ta .content-block .navbar-default a {
    font-size: 12px !important;
}

.ta .batch-pipe {
    color: #777;
    width: 2px !important;
    padding-left: 10px;
    padding-top: 2px;
}

.ta .batch-transcript{
    width: 145px !important;
}

.ta .batch-links{
    min-width: 300px !important;
}

.ta .batch-edit {
    width: 75px !important;
}

.ta .batch-userview {
    width: 65px !important;
}

.ta .content-block .navbar-default .search-link {
    padding: 9px 0px 0px 12px;
}

.ta .content-block .navbar-default .pull-link-right {
    display: flex;
    margin: 18px 15px;
    min-width: 105px;
}

.ta .content-block .navbar-default .pull-link-right li {
    text-align: right;
    width: 100%;
}

.ta .content-block .navbar-default .pull-link-right .pull-span {
    margin: 0px 15px;
}

.ta .content-block .navbar-default .pull-link-left {
    display: flex;
    margin-top: 18px;
    min-width: 145px;
    margin-right: -30px !important;
}


.ta .content-block .navbar-default .filter-content {
    padding-left: 15px;
}

.ta .content-block .navbar-default .filter-content .toggle-height{
    height: 35px;
}

.ta .content-block .navbar-default .dropdown-content {
    margin-left: 15px;
}

.ta .content-block .navbar-default .dropdown-content a {
    text-decoration: none;
}

.ta .navbar-spacer {
    height:58px;
}
/*.ta .content-block .table-block {*/
/*overflow-y: auto;*/
/*overflow-x: auto;*/
/*width:100%;*/
/*}*/

.ta .content-block .table-block .advanced-search-block {
    position: absolute;
    background-color: white;
    z-index: 3;
}

.ta .content-block .table-block .advanced-search-block form input{
    height: 25px;
}

.ta .content-block .table-block .advanced-search-block form div{
    margin-bottom: 7px !important;;
}

.ta .content-block .table-block .table button {
    background-color: white;
    border: none;
    border-radius: 0px;
}

/*.ta .content-block .table-block .table td,
.ta .content-block .table-block .table tr {
    border: none;
}*/

.ta .content-block .table-block .table th {
    border: none;
    border: none;
    font-size: 12px !important;
}

.ta .content-block .table-block .table td {
    font-size: 12px !important;
}

.ta .content-block .table-block .table td a{
    font-size: 12px !important;
}

.ta .content-block .table-block .row-height {
    height: inherit;
}

.ta .content-block .table-block .table-wrapper{
    width:100%;
}

.ta .content-block .table-block .table .col-width{
    min-width: 122px;
}

.ta .content-block .table-block .table .sortorder:after {
    content: '\25b2';
}
.ta .content-block .table-block .table .sortorder.reverse:after {
    content: '\25bc';
}

.table .sortorder:after {
    content: '\25b2';
}
.table .sortorder.reverse:after {
    content: '\25bc';
}


.ta .content-block .table-block .thead-prop{
    background-color: #e5e5e5;
}

.ta .content-block .table-block .tbody-prop{
    background-color: white;
}

.tbody-prop{
    background-color: white;
}

.ta .content-block .table-block .tbody-prop a{
    font-size: 12px;
}

.ta .content-block .table-block label {
    font-weight: normal;
}

.ta .content-block .table-block .edit-user-header {
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #e5e5e5
}

.ta .content-block .table-block .edit-user-actions {
    top: 15px;
    float: right;
    padding-right: 15px;
    margin-top: 1.5rem;
}

.ta .content-block .table-block .edit-user-actions .action {
    float: left;
    margin-left: 2rem;
    margin-top: 11px;
}

.ta .content-block .table-block .edit-user-actions a.action {
    margin-top: 11px;
    padding-top: 11px;
}

.ta .content-block .table-block .edit-user-content {
    background-color: white;
    padding-top: 10px;
}

@media (min-width: 769px) and (max-width: 1023px) {
    .ta .content-block .table-block a.btn-default {
        padding: 1rem 2rem;
        font-size:14px;
        /*line-height: 1.2;*/
    }
}

@media (min-width: 1024px) and (max-width: 1399px) {
    .ta .content-block .table-block a.btn-default {
        padding: 1rem 3.5rem;
        font-size:14px;
        /*line-height: 1.2;*/
    }
}

body {
    overflow-x: hidden;
}

.ta .content-block .navbar-default .btn-custom-prop {
    min-width: 250px;
    height: 35px;
}

@media (min-width: 769px) and (max-width: 1023px) {
    .ta .content-block .navbar-default .btn-custom-prop{
        padding: 4px 35px 4px 6px;
        min-width: 125px;
        font-size:12px;
    }

    .ta .content-block .navbar-default .right-search {
        min-width: 315px;
    }

}

@media (min-width: 1024px) and (max-width: 1399px) {
    .ta .content-block .navbar-default .btn-custom-prop{
        padding: 4px 25px 4px 6px;
        min-width: 135px;
        font-size:12px;

    }

    .ta .content-block .navbar-default .right-search {
        min-width: 325px;
    }

}

/*CSS for the Error Displayer in TA (inside Tree)*/

.ta .content-block .tree-block .messaging {
    margin-right: 0px;
    margin-left: 0px;
}

.ta .content-block .tree-block .messaging .error {
    margin-top: 0px;
    margin-bottom: 15px;
}

.ta .content-block .tree-block .messaging .error span {
    font-size: 24px;
}

@media (min-width: 769px) and (max-width: 1023px) {
    .ta .content-block .tree-block .messaging .error span, .error-messaging-fix .messaging .error span {
        font-size: 16px;
    }

}

@media (min-width: 1024px) and (max-width: 1399px) {
    .ta .content-block .tree-block .messaging .error span, .error-messaging-fix .messaging .error span  {
        font-size: 20px;
    }

}

.error-messaging-fix .messaging {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.error-messaging-fix .messaging .error span {
    font-size: 24px;
}

/*Styles to viewAssignmentsModal.html - START*/

#viewAssignmentsModal .modal-width {
    min-width: 950px !important;
}

#viewAssignmentsModal .modal-dialog .modal-content-prop {
    /*top: 40px; */
    background-color: #F2F2F2;
}

#viewAssignmentsModal .modal-dialog .modal-header .modal-title-prop {
    margin-left: 0px;
    margin-top: 10px !important;
}

#viewAssignmentsModal .modal-dialog .modal-header a {
    font-size: 14px;
}

#viewAssignmentsModal .modal-dialog .modal-header div {
    top: 25px !important;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .modal-body-wrapper {
    overflow: auto; height: 400px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table {
    margin-bottom: 0px !important;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table thead tr {
    height: 45px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody tr {
    height: 40px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody .error-display-table header span {
    font-size: 16px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tr th button {
    background-color: #dfdfdf;
    border: 1px #DEDEDE;
    font-weight: bold;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody a {
    color: #000;
    font-size: 12px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody .removeInactive {
    color: #505050;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody tr td a {
    text-decoration: none;
    border-bottom: 1px solid #a9a9a9;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody td {
    padding-left: 10px;
    vertical-align: middle;
}


#viewAssignmentsModal .modal-dialog .modal-content .modal-body table tbody td [type="text"] {
    background-color: transparent;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .tbody-prop td .md-datepicker-button {
    display: none !important;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .tbody-prop td .md-datepicker-button .md-datepicker-input {
    border-bottom: 1px solid black;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .tbody-prop td .md-datepicker-input-container {
    padding-bottom: 0px;
    margin-left: 0px;
    border-bottom-color: #dfdfdf;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .tbody-prop td .md-datepicker-input-container input {
    border-bottom: 1px solid #a9a9a9;
}


#viewAssignmentsModal .modal-dialog .modal-content .modal-body .tbody-prop td .md-datepicker-input {
    min-width: 100px !important;
    font-size: 12px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .action-container{
    margin-top: 25px;
    margin-bottom: 15px;
    padding-right: 0px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .action-container .btn-cancel-action {
    text-decoration: underline;
    color: #76757A;
    margin-right: 15px;
}

#viewAssignmentsModal .modal-dialog .modal-content .modal-body .action-container .btn-apply-action{
    margin-right: 5px;
}

#viewAssignmentsModal .dueDatePicker {
    min-width: 190px !important;
    max-width: 190px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

#viewAssignmentsModal .td-no-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.offeringsToggle {
    text-decoration: none !important;
    border-bottom: 1px solid !important;
    border-bottom-color: #1EABE3 !important;
    margin-top: 10px !important;
    color: #1EABE3 !important;
}
/*Styles to viewAssignmentsModal.html - END*/


/*Styles to addAssignmentModal.html - START*/

#addNewAssignmentModal .modal-width {
    min-width: 850px !important;
}

#addNewAssignmentModal .modal-dialog .modal-content-prop {
    /*top: 40px; */
    background-color: #F2F2F2;
}

#addNewAssignmentModal .modal-dialog .modal-header .modal-title-prop {
    margin-left: 0px;
    margin-top: 10px !important;
}

#addNewAssignmentModal .modal-dialog .modal-header a {
    font-size: 14px;
}

#addNewAssignmentModal .modal-dialog .modal-header .closer {
    top: 25px !important;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body .modal-body-wrapper {
    overflow: auto; height: 350px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table {
    margin-bottom: 0px !important;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table thead tr {
    height: 45px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table tbody tr {
    height: 40px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table tr button {
    background-color: #dfdfdf;
    border: 1px #DEDEDE;
    font-weight: bold;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table tbody a {
    font-size: 12px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body table tbody td {
    padding-left: 10px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body .action-container{
    margin-top: 25px;
    margin-bottom: 15px;
    padding-right: 0px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body .action-container .btn-cancel-action{
    text-decoration: underline;
    color: #76757A;
    margin-right: 15px;
}

#addNewAssignmentModal .modal-dialog .modal-content .modal-body .action-container .btn-apply-action{
    margin-right: 5px;
}

/*Styles to viewAssignmentsModal.html - END


/*Styles to addAssignmentModal.html - START*/

#addChildCoursesModal .modal-width {
    min-width: 1280px !important;
}

#addChildCoursesModal .modal-dialog .modal-content-prop {
    /*top: 40px; */
    background-color: #F2F2F2;
}

#addChildCoursesModal .modal-dialog .modal-header .modal-title-prop {
    margin-left: 0px;
    margin-top: 10px !important;
}

#addChildCoursesModal .modal-dialog .modal-header a {
    font-size: 14px;
}

#addChildCoursesModal .modal-dialog .modal-header .closer {
    top: 25px !important;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body .modal-body-wrapper {
    overflow: auto; height: 350px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table {
    margin-bottom: 0px !important;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table thead tr {
    height: 45px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table tbody tr {
    height: 40px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table tr button {
    background-color: #dfdfdf;
    border: 1px #DEDEDE;
    font-weight: bold;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table tbody a {
    font-size: 12px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body table tbody td {
    padding-left: 10px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body .action-container{
    margin-top: 25px;
    margin-bottom: 15px;
    padding-right: 0px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body .action-container .btn-cancel-action{
    text-decoration: underline;
    color: #76757A;
    margin-right: 15px;
}

#addChildCoursesModal .modal-dialog .modal-content .modal-body .action-container .btn-apply-action{
    margin-right: 5px;
}


/*Styles to admin.markSuccessfulModal.html - START*/

#markSuccessfulModal .btn-cancel-action{
    color: #76757A;
}

#markSuccessfulBody{
    padding: 3rem;
}

#markSuccessfulBody .margin-bottom {
    margin-bottom: 10px;
}

#markSuccessfulBody #successfulScore {
    border-color: rgb(204, 204, 204);
}

/*Styles to admin.markSuccessfulModal.html - END*/


.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

/*Styles to updateAssignmentErrorModal.html - START*/

#updateAssignmentErrorModal .modal-dialog .modal-content .modal-body .modal-body-wrapper {
    overflow: auto; height: 400px;
}

#updateAssignmentErrorModal .modal-dialog .modal-content-prop {
    /*top: 40px; */
    background-color: #F2F2F2;
}

#updateAssignmentErrorModal .modal-dialog .modal-header {
    padding-left: 25px !important;
    padding-bottom: 25px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body-prop {
    padding-top: 0px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .img-container {
    top: 25px;
    padding-left: 30px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .img-container img {
    height: 70px;
    width: 70px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .error-header {
    position: absolute;
    text-align: center;
    top: 125px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .error-header p {
    margin-bottom: 0px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .error-message {
    position: absolute;
    bottom: 100px;
    min-height: 125px;
    text-align: center;
    padding-bottom: 0px;
    padding-top: 15px;
}

#updateAssignmentErrorModal .modal-dialog .modal-body .error-action {
    position: absolute;
    bottom: 50px;
}

/*Styles to updateAssignmentErrorModal.html - END*/

#peopleGroupTable {
    background-color: white;
    margin-bottom: 15px
}

#peopleGroupTable .header-wrapper {
    background-color: #d6d6d6;
}

#peopleGroupTable .header-wrapper .header-content {
    padding-left: 0px;
}

#peopleGroupTable .header-wrapper .header-content p {
    color: black;
    font-size: 26px;
    font-weight: bold;
    font-family: 'Rockwell';
    margin: 30px 0px 30px;
}

#peopleGroupTable .header-wrapper .header-action-wrapper {
    margin: 30px 0px 30px;
}

#peopleGroupTable .header-wrapper .header-action-wrapper .cancel-action {
    margin-right: 10px;
    text-decoration: underline;
    color: #6f6f6f;
}

#peopleGroupTable .group-name {
    margin-top: 15px;
}

#peopleGroupTable .group-name label {
    font-weight: bold !important;
    font-size: 14px;
}

#peopleGroupTable .people-container-wrapper {
    background-color: #F0F0F0;
    background-clip: content-box;
    margin-bottom: 15px;
    margin-top: 15px;
}

#peopleGroupTable .footer-wrapper {
    border-top: 2px solid #7a7a7a;
    padding-top: 15px;
    padding-bottom: 15px;
}


/* Rules Styles start from here*/

#allRulesTable {
    background-color: white;
    margin-bottom: 15px
}

#allRulesTable .header-wrapper {
    background-color: #d6d6d6;
}

#allRulesTable .header-wrapper .header-content {
    padding-left: 0px;
    padding-bottom: 20px;
}

#allRulesTable .header-wrapper .header-content p {
    color: black;
    font-size: 26px;
    font-family: 'Rockwell';
    margin: 30px 0px 0px;
    font-weight: bold;
}

#allRulesTable .header-wrapper .header-action-wrapper {
    margin: 30px 0px 30px;
}

#allRulesTable .header-wrapper .header-action-wrapper .cancel-action {
    margin-right: 10px;
    text-decoration: underline;
    color: #6f6f6f;
    font-size: 16px;
    padding-right: 20px;
}

#allRulesTable .body-content {
    padding-top: 15px;
}

#allRulesTable .body-content .due-input {
    height: 30px;
    width: 60px;
    margin-right: 10px;
    text-align: center;
    border: 1px solid #ccc;
}

#allRulesTable .body-content .due-header {
    margin-top: 15px;
}

#allRulesTable .body-content .form-group {
    padding-left: 0px !important;
}

#allRulesTable .body-content .form-group .heading {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;
}

#allRulesTable .body-content .form-group .heading-label {
    font-style: italic;
    font-size: 12px;
}

#allRulesTable .body-content .form-group .icon-container {
    position: absolute;
    padding-left: 10px;
    top: 27px;
}

#allRulesTable .body-content .form-group .icon-container .lock-icon {
    height: 18px;
    width: 14px;
}

#allRulesTable .body-content .form-group .input-padding {
    padding-left: 30px;
}

#allRulesTable .body-content .form-group .notification-label{
    margin-bottom: 10px;
}

#allRulesTable .footer-container {
    margin-top: 15px;
    margin-bottom: 15px;
}

#allRulesTable .footer-container hr {
    border-top: 3px solid #e5e5e5;
}

#allRulesTable .footer-container .footer-wrapper{
    margin-top: 10px;
    margin-bottom: 10px;
}

#allRulesTable .footer-container .footer-wrapper .btn-link{
    text-decoration: underline;
    font-size: 14px;
    color: black;
    padding-left: 20px;
    padding-right: 15px;
}

#allRulesTable .footer-container .footer-wrapper .btn-default{
    font-size: 14px;
    margin-left: 10px;

}

#allRulesTable .instructor-nav md-tabs-wrapper {
    padding-left: 15px;
}

#allRulesTable .people-content {
    padding-top: 0px !important;
    padding-left: 15px;
}

#allRulesTable .people-content .content-label {
    margin-bottom: 2px !important;
}

#allRulesTable .people-content .content-label label {
    font-size: 15px;
    font-weight: bold;
}

/*Custom Accordion Styles here*/

#allRulesTable .people-content .customAccordion .panel-group {
    margin-bottom: 0px;
}

#allRulesTable .people-content .customAccordion .panel-default {
    border-radius: 0px;
}

#allRulesTable .people-content .customAccordion .panel-default .toggleIcon {
    padding-right: 10px !important;
}

#allRulesTable .people-content .people-container-wrapper {
    background-color: #F0F0F0;
    background-clip: content-box;
    margin: 15px 0px 15px;
    padding-left: 0px;
    margin-top: 0px;
}

#allRulesTable .people-content .customAccordion .panel-title a {
    text-decoration: none;
    font-weight: bold;
}

#allRulesTable .people-content .customAccordion .panel-body {
    padding: 0px 0px 0px 0px;
    background-color: #F0F0F0;
}

#allRulesTable .people-content .plusClass:before {
    font-size: 14px;
    font-weight:bold;
    content:"+";
}

#allRulesTable .people-content .minusClass:before {
    font-size: 14px;
    font-weight:bold;
    content:"-";
}

/*Custom Accordion Styles here - Ends here*/
#allRulesTable .people-content .footer-container {
    padding-left: 0px;
}

#allRulesTable .people-content .footer-container span {
    font-weight: bold;
    color: #6c6c6c;
}

#allRulesTable .people-content .footer-container a {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: bold;
    color: #6c6c6c;
}

#allRulesTable .assignment-content {
    padding-top: 0px !important;
}

#allRulesTable .assignment-content .content-label {
    margin-bottom: 0px !important;
}

#allRulesTable .assignment-content .content-label label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
}

#allRulesTable .assignment-content .content-label span a {
    padding-left: 5px;
    font-size: 14px;
}

#allRulesTable .assignment-content .table{
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

#allRulesTable .assignment-content .table thead tr th button{
    padding-left: 0px;
}

#allRulesTable .assignment-content .table tbody tr td {
    padding: 10px !important;
}

#allRulesTable .assignment-content .table-bordered th{
    height: 40px;
}

#allRulesTable .disable-pointer {
    pointer-events: none;
}

#allRulesTable .enable-pointer {
    pointer-events: auto;
}

/*Dates css*/

#allRulesTable .dates-content {
    padding-top: 0px !important;
}

#allRulesTable .dates-content a {
    font-size: 12px;
}

#allRulesTable .dates-content .content-label {
    margin-bottom: 0px !important;
}

#allRulesTable .dates-content .content-label label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
}

#allRulesTable .dates-content .table{
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

#allRulesTable .dates-content .table .custom-th{
    padding-right: 15px;
}

#allRulesTable .dates-content .table button{
    background-color: #f2f2f2;
}

#allRulesTable .dates-content .table .grey {
    background-color: #f2f2f2;
}

#allRulesTable .dates-content .table .white {
    background-color: white;
}

#allRulesTable .dates-content .table thead tr th button{
    padding-left: 0px;
}

#allRulesTable .dates-content .table thead tr th {
    vertical-align: middle;
    text-align: center;
}

#allRulesTable .dates-content .table tbody tr td {
    padding: 8px !important;
    vertical-align: middle;
}

#allRulesTable .dates-content .table tbody .form-group {
    margin-bottom: 0px !important;
}

#allRulesTable .dates-content .table tbody .form-control {
    height: 30px !important;
    line-height: 1.5 !important;
}

#allRulesTable .dates-content .table tbody .row {
    margin: 0px;
}

#allRulesTable .dates-content .table .dropdown-caret-wrapper {
    /*right: 15px; */
    height: 30px; 
    width: 25px;
    background-color: #eeeeee;
}

#allRulesTable .dates-content .table .dropdown-caret-wrapper span {
    margin-top: -4px;
}

#allRulesTable .dates-content .table .custom-row .form-group {
    padding-right: 1px;
}

#allRulesTable .dates-content .table .custom-row input {
    text-align: center;
}

#allRulesTable .dates-content .table-bordered th{
    height: 40px;
}


#allRulesTable .preview-content .info {
    margin-bottom: 0px;
}

#allRulesTable .preview-content label span {
    font-weight: bold;
}

#allRulesTable .preview-content .count-container {
    border: 1px solid #ababab; 
    height: 100px; 
    margin-right: 15px; 
    margin-left: 15px;
    text-align: center;
}

#allRulesTable .preview-content .count-container span {
    line-height: 100px; 
}

#allRulesTable .preview-content .count-container .wrapper p {
    font-size: 15px;
    margin-bottom: 5px;
}

#allRulesTable .preview-content .count-container .wrapper span {
    line-height: 0px; 
    font-size: 32px; 
    font-weight: bold;
}

#allRulesTable .preview-content .operand-container {
    height: 100px; 
    text-align: center;
}

#allRulesTable .preview-content .operand-container span {
    line-height: 100px; 
}

#allRulesTable .preview-content .wrapper {
    vertical-align: middle; 
    display: inline-block;
}

#allRulesTable .preview-content .operand-container .wrapper p {
    font-size: 20px;
}

/*Styles to datesBatchEditModal.html - START*/

#datesBatchEditModal .modal-width {
    min-width: 800px !important;
}

#datesBatchEditModal .modal-dialog .modal-content-prop {
    background-color: #F2F2F2;
}

#datesBatchEditModal .modal-dialog .modal-header {
    padding-left: 25px !important; 
    padding-bottom: 25px;
}

#datesBatchEditModal .modal-dialog .modal-header .modal-title-prop {
    margin-left: 0px;
    margin-top: 10px !important;
}

#datesBatchEditModal .modal-dialog .modal-header .closer {
    top: 25px !important;
}

#datesBatchEditModal .modal-dialog .modal-body label {
    font-size: 14px;
    font-weight: normal;
}

#datesBatchEditModal .modal-dialog .modal-body input[type=checkbox], input[type=radio] {
    height: 15px;
    width: 15px;
}

#datesBatchEditModal .modal-dialog .modal-body .row {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

#datesBatchEditModal .modal-dialog .modal-body .row .form-group{
    margin-bottom: 0px;
}

#datesBatchEditModal .modal-dialog .modal-body .row .form-group .radio-inline{
    margin-bottom: 5px;
}

#datesBatchEditModal .modal-dialog .modal-body .row .form-group .text-class{
    width: 40px;
    margin-right: 5px;
    margin-left: 5px;
}

#datesBatchEditModal .modal-dialog .modal-body .row .form-group .form-control{
    line-height: 1.5;
}

#datesBatchEditModal .modal-dialog .modal-body .row .form-group input{
    border: 1px solid #cccccc;
}

#datesBatchEditModal .modal-dialog .modal-footer .btn-link{
    color: black;
    text-decoration: underline;
    margin-right: 15px;
}

/*Styles to datesBatchEditModal.html - END*/

#preview-people .modal-body {
    padding-bottom: 0px;
}

#preview-people .modal-body table {
    background-color: white;
}

#preview-people .modal-body table button {
    background-color: #e0e0e0;
    font-weight: bold;
}

#copyRuleModal .modal-body table {
    background-color: white;
}

#copyRuleModal .modal-body table tbody button {
    background-color: white;
}

#copyRuleModal .modal-body table button {
    background-color: #efefef;
    font-weight: bold;
}

#copyRuleModal .modal-dialog {
    width: 75% !important;
}

#copyRuleModal .modal-body tbody td input[type='text']{
    width: 90%;
    border: 1px solid #cccccc;
    height: 29px;
    padding-left: 5px;
}

#copyRuleModal .modal-body tbody td textarea {
    border: 1px solid #cccccc;
}

#copyRuleModal .modal-footer {
    padding-top: 0px;
}

#copyRuleModal .modal-footer .btn-link {
    color: black;
    text-decoration: underline;
    margin-right: 15px;
}

.ruleCreate {
    min-height: 40vh;
}


.control-label:after {
    content:"*";
    color:red;
}

.required-field:after {
    content:"*";
    color:red;
}


.ta.users .assignmentsHeader {
    margin: 0px;
}

.ta.users .assignmentsHeader h2 {
    padding: 0px;
}

.ta .table tr {
    border-bottom: 1px solid #e5e5e5;
}


/*Custom Date Picker*/

.customDatePicker  {
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.customDatePicker .md-datepicker-button {
    display: none;
}

.customDatePicker .md-datepicker-input-container {
    border: 1px solid #cccccc !important;
    margin-left: 0px !important;
    width: 100% !important;
}

.customDatePicker .md-datepicker-input-container input {
    text-align: left;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 10px;
    width: inherit !important;
}

.customDatePicker .md-datepicker-input-container button {
    right: 5px;
    background-color: transparent !important;
}

/*This is the wrapper for the md-date-picker*/

.custom-md-container{
    background-color: #fff;
    padding: 0px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    height: 31px;
}

.custom-md-container label {
    left: 0px !important;
    color: black;
    font-size: 15px;
    width: auto !important;
}

.custom-md-container .md-input-message-animation{
    margin-left: 0px !important;
    margin-top: -5px !important;
}

.custom-course-tabs {
    padding: 15px 0px 15px 0px;
}

.custom-course-tabs md-tabs-wrapper {
    padding-left: 15px !important;
}

/* Replace default date picker dropdown arrow button with a calendar icon*/
.customDatePicker .md-datepicker-triangle-button:after {
    content: url(/lms-web/app/images/icons/datepicker_calendar_button.svg);
}

.customDatePicker .md-datepicker-expand-triangle {
    display: none !important;
}
   
/* Dropdowns in ta header */
.ta .header-block div.header-navbar .form-group {
    display: inline;
}
.ta .header-block div.header-navbar .form-group label {
    margin-top: 10px;
    vertical-align: middle;
}

.ta .header-block div.header-navbar .form-group div.select-wrapper {
    margin-right: 5px;
}

.ta .header-block div.header-navbar .form-group select.form-control {
    margin-top: 10px;
    min-width: 100%;
}

.ta .header-block div.header-navbar .form-group div.dropdown-caret-wrapper {
    margin-top: 10px;
}

.ta .header-block div.header-navbar.pull-right div.circle-question {
    float: right;
}


.help-popover .popover-content {
    margin: 8px;
    width: auto;
    text-align: left;
    padding: 0px;
    line-height: 1.75;
}

#help-popover-full a {
    color: #505050;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Verdana', 'Arial', 'sans-serif';
    background-color: transparent;
    text-decoration: none;
}

#help-popover-full hr {
    border: none;
    display: block;
    width: auto;
    height: 1px;
    margin: 12px 0px 6px 0px;
    background-color: #A1A1A1;
    opacity: .5;
}

#help-popover-full a:hover {
    color: #A1A1A1;
    text-decoration: underline;
    background-color: transparent;
}

.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

.no-animate {
   -webkit-transition: none !important;
   transition: none !important;
}

.roundCorners{
    padding: 5px;
    border-radius: 15px 15px 0px 0px;
    margin-bottom: 0px;
    text-align:center;
}

.inactiveBackground{
    background-color:#f9f9f9;
}

.activeBackground{
    background-color:#e4e4e4;
}

.historyHeader{
   border-bottom: 2px solid #e4e4e4;
}

.courseDateHistory{
    padding-left:0px;
    padding-top:5px;
    padding-bottom:5px;
}

.ruleHistory{
    border-bottom: 1px solid #e5e5e5;
}

.ruleHistory div {
    padding-left: 0px;
    padding-right: 0px;
}

.peopleGroupHistoryHeader{
   border-bottom: 2px solid #e4e4e4;
   margin-bottom:0px;
   padding-right:15px;
}

#allRulesTable h4.panel-title{
    line-height:0;
}