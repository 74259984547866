/*@media (min-width: 768px) and (max-width: 1023px) {*/
    /*#singlecourse .body .info .infoHolder {*/
        /*width: 100%;*/
    /*}*/

    /*#singlecourse .body .info .mainImg {*/
        /*width: 100%;*/
    /*}*/

    /*#singlecourse .body .info .content {*/
        /*width: 100%;*/
    /*}*/

    /*.modal-body .navbar-form .form-group .form-control {*/
        /*width: 100%;*/
    /*}*/

    /*#accounts .col-md-12 .form-group {*/
        /*width: 100%;*/
    /*}*/

    /*#courses .certInfo .name {*/
        /*font-size: 40px;*/
        /*top: 35%;*/
    /*}*/

    /*#courses .certInfo .score {*/
        /*font-size: 40px;*/
        /*right: 7%;*/
    /*}*/

    /*#courses .certInfo .completionDate {*/
        /*bottom: 21%;*/
        /*font-size: 20px;*/
        /*left: 7%;*/
    /*}*/
/*}*/

/*@media (min-width: 1024px) and (max-width: 1299px) {*/
    /*.modal-body .navbar-form .form-group .form-control {*/
        /*width: 100%;*/
    /*}*/

    /*#courses .certInfo .name {*/
        /*top: 37%;*/
    /*}*/

    /*#courses .certInfo .score {*/
        /*font-size: 50px;*/
        /*right: 7%;*/
    /*}*/

    /*#courses .certInfo .completionDate {*/
        /*bottom: 21%;*/
        /*left: 7%;*/
    /*}*/
/*}*/

@media (min-width: 1300px) and (max-width: 1399px) {
    .container {
        width: 1270px;
    }

    /*.modal-body .navbar-form .form-group .form-control {*/
        /*width: 100%;*/
    /*}*/

    /*#courses .certInfo .score {*/
        /*right: 8%;*/
    /*}*/
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 800px;
        margin: 30px auto;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1370px;
    }

    /*.modal-body .navbar-form .form-group .form-control {*/
        /*width: 100%;*/
    /*}*/

    /*#courses .certInfo .score {*/
        /*right: 10%;*/
    /*}*/

    /*#courses .certInfo .completionDate {*/
        /*bottom: 22%;*/
        /*left: 9%;*/
    /*}*/
}