/* Styling for /users/home for premium admin.
 * People tab. */

#peopleTabBatchEditModal .cancelAndApply .cancel {
    margin-right: 2rem;
}

/*!* Profile editing */
 /** /users/edit/<userId>/ *!*/
/*!* Make this container inline block so it's horizontal with the */
 /** save and cancel buttons *!*/
/*.premiumAdminEditUserMenu .deactivateUserContainer {*/
    /*display: inline-block;*/
/*}*/

.premiumAdminEditUserMenu .fullNameHeader {
    display: inline-block;
    color: black;
    font-family: 'Rockwell';
    font-weight: bold;

}

.premiumAdminEditUserMenu .fullNameHeaderSubtitle {
    display: inline-block;
    font-size: 14px;
}

/* People tab batch edit modal results list */
.taModal .resultsList,
.transcriptAddModal .resultsList {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.taModal .resultsList ol li,
.transcriptAddModal .resultsList ol li {
    padding-left: 2rem;
}

.taModal .resultsList .userList {
    height: 40vh;
    overflow-y: scroll;
}
.taModal .resultsList .okButtonContainer {
    margin-top: 2rem;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
}

.taModal .resultsList .imageContainer,
.transcriptAddModal .resultsList .imageContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.taModal .resultsList .imageContainer p,
.transcriptAddModal .resultsList .imageContainer p {
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: left;
}

.taUsers .spinner {
    margin: 0 auto;
}

.csh-icon {
    margin-right: 4rem;
    width: 3.5rem; 
    height: 3.5rem; 
    margin-top: .8rem;
}

.checkbox-remove {
    margin: 0px !important;
    display: inline-block;
    vertical-align: middle;
}

.retake-limit-fields {
    width: 60px !important; 
    display: inline !important; 
    margin-right: 5px;
}