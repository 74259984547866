md-datepicker {}

md-datepicker .md-button.md-icon-button {
    margin: 0 6px;
    padding: 8px;
    width: 40px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    border-radius: 50%;
}

md-datepicker .md-datepicker-expand-triangle {
    left: 40%;
}

/** Transcript select date range search filter (NOT DATE RANGE ON MODAL) **/
/* styling/layout for box that contains the select date range stuff */
.datePickerHolder {
    margin-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5px;
}

.datePickerHolder .datePickerHolderInner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Styling for select text */
.datePickerHolder .selectText {
    font-size: 16px;
    font-weight: bold;
    margin-right: 4rem;
}

/* Make the original bootstrap calendar icon/button transparent */
.datePickerHolder .datePicker .md-datepicker-calendar-icon {
    fill: rgba(0,0,0,0);
}

/* Show a custom calendar icon and position it over the original bootstrap
 * calendar icon */
/* Make it so we can position the .calendarIcon absolutely */
.datePickerHolder .datePicker {
    position: relative;
}

.datePickerHolder .datePicker .label {
    color: #000;
    display: inline-block;
    font-size: 1.2rem;
    position: relative;
}

.datePickerHolder .datePicker .md-datepicker-button.md-button.md-icon-button {
    background: none;
    display: none;
    margin: 0;
    padding-bottom: 16px;
}
/* Put the calendar icon over the original datepicker button */
.datePickerHolder .datePicker .calendarIcon {
    background: url('../../App/images/icons/transcripts_calendar.svg');
    width: 2rem;
    height: 2rem;
    background-size: contain;
    display: inline-block;
    position: relative;
    top: 5px;
}

/* Style the border, width/height/padding on the datepicker box */
.datePickerHolder .datePicker .md-datepicker-input-container {
    border: 1px solid #808080;
    width: 18rem;
    padding-bottom: 0px;
}

.datePickerHolder .datePicker .md-datepicker-open .md-datepicker-input-container {
    border: none;
}

.datePickerHolder .datePicker .md-datepicker-input {
    /* Make the 'Completed Before' / 'Completed After' text in the
     * containers not get chopped off */
    min-width: 150px;

    padding-left: 5px;
    padding-top: 3px;
    color: #7f7f7f;
}
/* color the default text in the datepicker boxes */
.datePickerHolder .datePicker .md-datepicker-input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #7f7f7f;
}
.datePickerHolder .datePicker .md-datepicker-input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #7f7f7f;
    opacity:  1;
}
.datePickerHolder .datePicker .md-datepicker-input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #7f7f7f;
    opacity:  1;
}
.datePickerHolder .datePicker .md-datepicker-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7f7f7f;
}

/* Make the triangle on the right hand side of the datepicker box larger */
.datePickerHolder .datePicker .md-datepicker-expand-triangle {
    left: 20%;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #b6b6b6;
}

/* Make the datepicker box close to the calendar icon */
.datePickerHolder .md-datepicker-input-container {
    margin-left: 0;
}



.moment-picker .decade-view td,
.moment-picker .year-view td {
  min-height: 8.4em;
  min-width: 6.4285714286em;
}

.moment-picker .month-view td {
  min-width: 3.4285714286em;
}
.moment-picker .day-view td,
.moment-picker .hour-view td {
  min-height: 2.3333333333em;
  min-width: 3.4285714286em;
}
.moment-picker .minute-view td {
  min-height: 1.8em;
}

.moment-picker .moment-picker-container {
  min-width: 15em;
  min-height: 15em;
}

.moment-picker th,
.moment-picker td {
  min-width: 4em;
  min-height: 4em;
}

.moment-picker-input {
    height: 30px;
    min-width: 130px !important;
    text-align: left;
    font-size: 12px;
    padding: 5px !important;
}
.moment-picker.inline {
    position: absolute !important;
}