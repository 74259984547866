/**** Assignment list View ****/sorry
#assignmentCourseList {
    margin-top: 2rem;
}

/* Override this to bootstrap default */
.users #assignmentCourseList.row .row {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
    margin-bottom: 0;
}

/* Assignment layout */
#assignmentCourseList .assignment .tiles {
    padding: inherit;
}
#assignmentCourseList .assignment .info {
    display: flex;
    align-items: flex-start;
}

#assignmentCourseList .assignment {
    border-top: 1px solid #e5e5e5;
    padding: inherit;
    padding-top: 2rem;
    padding-bottom: 2rem;
}


#assignmentCourseList .assignment:first-child {
    border-top: none;
    padding-top: 0rem;
    padding-bottom: 2rem;
}

#assignmentCourseList .assignment:last-child {
    margin-bottom: 5rem;
    border-bottom: 1px solid #e5e5e5;
}

/* Assignment image */
#assignmentCourseList  .imageDate {
    padding-right: 0;
}
#assignmentCourseList  .imageDate .mainImg {
    width: 100%;
}

/* The date the course is due */
#assignmentCourseList  .dateHolder .date {
    font-weight: bold;
    font-size: 14px;
    padding-left: 2.5rem;
    padding-bottom: 1.5rem;
    display: block;
}

/* Assignment status styling */
#assignmentCourseList .title .status {
    font-size: 14px;
    padding-bottom: .5rem;
    display: block;
}

/* The course title */
#assignmentCourseList .title h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
}

/* The course meta id/type/etc */
#assignmentCourseList .courseMeta {
    display: flex;
    justify-content: flex-begin;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
    flex-wrap: wrap;
}
/* Pipe bars between course meta items*/
#assignmentCourseList .courseMeta span:nth-last-child(n+2):after {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

/* The course meta id/type/etc */
#assignmentCourseList .courseMetaManager {
    display: flex;
    justify-content: flex-begin;
    flex-direction: row;
    font-size: 14px;
    padding-top: .1rem;
}
/* Pipe bars between course meta items*/
#assignmentCourseList .courseMetaSpan:nth-last-child(n+2):after {
    content: "|";
    color: black;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: .9rem;
}

#assignmentCourseList .viewCourses{
    color: #505050;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
    vertical-align:bottom;
}

/* Course description */
.description {
    font-size: 14px;
    margin-bottom: 0;
}
/* Make the short version of the description have a line wrapping.
 *This is inside a div because the content is rendered
 * using ng-bind-html
 */
.description.short {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/********************* ALL THAT FOLLOWS IS COMMON ACROSS OTHER PAGES.
 * Ignore unless you aren't working on the assignment course list page.
 */

.list {
    margin-top: 15px;
}

.list .col-md-12 {
    margin-bottom: 7px;
    overflow: hidden;
    padding: 0px;
}

.list .col-md-12.even {
    background-color: #F2F2F2;
}

.list .col-md-12 .info .imageDate {
    cursor: pointer;
    float: left;
    margin-right: 15px;
    overflow: hidden;
    padding: 0px;
    position: relative;
    width: 250px;
}


.list .col-md-12 .info .imageDate .dateHolder {
    background-color: #1EABE3;
    color: #fff;
    font-weight: bold;
    padding: 7px;
    width: 250px;
}

.list .col-md-12 .info .imageDate .dateHolder .time {
    float: right;
}

.list .col-md-12 .info .imageDate .mainImg {
    background-color: #bbb;
    float: left;
    margin-right: 15px;
    width: 250px;
}

.list .col-md-12 .info .title {
    padding-top: 7px;
    position: relative;
}

.list .col-md-12 .info .title h3 {
    margin: 0px 0px 7px;
    padding-top: 3px;
    position: relative;
}

.list .col-md-12 .info .title .more {
    float: right;
    padding: 7px;
}

.list .col-md-12.alert {
    margin-bottom: 20px;
    padding: 15px;
}

.actionHolder {
    margin-top: 1rem;
    overflow: hidden;
    width: 100%;
}

/*
* Spinner for all launch buttons on the list, outside of the slide down.
*/
.actionHolder .spinner {
    float: left;
    margin: 16px 45px 16px 62px;
}

/* more link */
.actionHolder .more {
    float: left;
    font-size: 14px;
    margin-top: 10px;
}

/*Start Manager list view */

#assignmentCourseList .description.long {
    overflow: hidden;    
    white-space: normal;
    text-overflow: ellipsis;
}

/* action button css */
#assignmentCourseList .remove{
    font-size: 14px;
    color: black;
    text-decoration: underline;
}

/* action button css */
#assignmentCourseList .removeInactive {
    font-size: 14px;
    color: lightgrey;
    cursor: default;
}

/* action button css */
#assignmentCourseList .removeInactive:hover {
    cursor: default;
}

#assignmentCourseList .edit {
    margin-left:0px;
    float:left;
    padding-left: 2px;
    cursor: pointer;
}

#assignmentCourseList .assignmentActions {
    display: flex;
    margin: 0px 0px;
}

#assignmentCourseList .managerDate {
    float: left;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1.5rem;
    padding-right: 2.5rem;
}

#assignmentCourseList .managerDatePicker {
    /*width: 250px;*/
    margin-bottom: 10px;
}

#assignmentCourseList .btn-default {
    float: left;
    margin-right: 15px;
}

#assignmentCourseList .hideOnListPages {
    display: none;
}

#assignmentCourseList .slideDownMeta{
    padding-top: 0rem !important;

}

#assignmentCourseList .courseGroupIcon {
    margin-left: 2rem !important;

}

/* View Resources start */
.launchBtnResources
{
    margin-right: 4px !important;
}

#assignmentCourseList #viewResources .body {
    overflow: hidden;
    padding: 5px;
    padding-left: 10px;
}


/* View Resources end */
