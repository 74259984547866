/**
* Any place that links exist directly under the page tilte (e.g.: messages/inbox/, under title of Messages, two links exist.
* This area corresponds to that styling
*/
.subLinkHeader {
    border-bottom: 1px solid #e5e5e5;
    margin: 0 -15px 15px;
}

.subLinkHeader ul {
    margin: 15px;
    padding: 0px;
}

.subLinkHeader ul li {
    display: inline-block;
    margin-right: 15px;
}

.subLinkHeader ul li:not(:last-child):after {
    content: '|';
    padding-left: 15px;
}

.subLinkHeader ul li a.active {
    font-weight: bold;
}