input[type=number].form-control,
input[type=password].form-control,
input[type=text].form-control,
input[type=time].form-control,
select {
    -webkit-appearance: none !important;
}
select.form-control,
textarea.form-control {
    border-radius: 0;
    border: 1px solid #ccc;
    outline: 0;
}

/* Added this rule in as our controls above do not allow for the correct error borders */
.has-error input[type=number].form-control,
.has-error input[type=password].form-control,
.has-error input[type=text].form-control,
.has-error input[type=time].form-control,
.has-error select.form-control,
.has-error textarea.form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-color: #a94442 !important;
}

/* Dropdown component */
.taModal.modal .selectContainer .fakeSelectElement {
    border-radius: 2px;
    border: 1px solid rgba(0,0,0, .2);
    padding-top: 6px;
    padding-bottom: 23px;
}

.taModal.modal .selectContainer .downArrow {
    height: 30px;
}

.taModal.modal .selectContainer .downArrow img {
    margin-top: 11px;
}

/* Locked input box */
.lockedInputField .icon {
    position: absolute;
    top: 31px;
    left: 28px;
    height: 20px;
    width: 20px;
    z-index: 2;
}
.lockedInputField input {
    padding-left: 40px;
}
.lockedInputField textarea {
    text-indent: 29px;
}
.lockedInputField .selectContainer .fakeSelectElement {
    padding-left: 4rem;
}
.form-control {
    height: 42px;
}

.checkbox + .checkbox,
.radio + .radio {
    margin: 0px 0px 10px;
}

.checkboxHolder input[type="checkbox"] {
    display: none;
    float: left;
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

.checkbox input[type=checkbox],
.radio input[type=radio] {
    margin: 0px;
    position: relative;
}

.checkboxHolder label {
    cursor: pointer;
    display: inline;
    padding-left: 5px;
    position: relative;
    vertical-align: middle;
}

.checkboxHolder label::before {
    background-color: #fff;
    border: 1px solid #777;
    border-radius: 0px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 12px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    width: 12px;
    top: 1px;
}

.checkboxHolder label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: -2px;
    top: 0;
    margin-left: -20px;
    padding-left: 4px;
    padding-top: 0;
    font-size: 11px;
    color: #1EABE3;
}

.checkboxHolder input[type="checkbox"]:checked + label:after {
    content: "\2714";
    font-family: 'Rockwell', 'Times New Roman';
}

.radio {
    margin: 0px 0px 10px;
}

.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -15px;
    border: 1px solid #777;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -15px;
    border-radius: 50%;
    background-color: #555555;

    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
}

.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(.75, .75);
    -ms-transform: scale(.75, .75);
    -o-transform: scale(.75, .75);
    transform: scale(.75, .75);
}

.radio input[type="radio"]:checked + label::after {
    background-color: #1EABE3;
}

/* Styling for modal input box */
.modalInputBox {
    border: 1px solid black;
    padding-left: .5rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    margin-right: 3rem;
    width: 15rem;
    margin-bottom: .4rem;
}

/* The little arrow at the end of the select box */
.selectContainer {
    position: relative;
}

.selectContainer select {
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 1rem;
    padding-bottom: .2rem;
    padding-top: .3rem;
    font-size: 14px;
}

/* This is for select boxes that have custom menues */
.selectContainer .fakeSelectElement {
    padding-left: 1rem;
    padding-bottom: .2rem;
    padding-top: .3rem;
    font-size: 14px;
    display: inline-block;
    border: 1px solid #c0c0c0;
    background-color: white;
    width: 100%;
    height: 2.5rem;
}

.fakeSelectElement.disabled {
    background-color: #eee;
}
.selectContainer .fakeSelectElement ~ .fakeSelectDropdown {
    position: absolute;
    top: 3rem;
    left: 0;
    height: 20rem;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 50rem;
    background-color: white;

    padding-left: 2rem;
    padding-top: 1.5rem;

    -moz-box-shadow: 2px 2px 8px 1px #adadad;
    -webkit-box-shadow: 2px 2px 8px 1px #adadad;
    box-shadow: 2px 2px 8px 1px #adadad;
    z-index: 1;
}

.selectContainer .fakeSelectElement ~ .fakeSelectDropdown .fakeSelectItem {
    display: block;
    margin-bottom: 1.5rem;
}

.selectContainer select::-ms-expand {
    display: none;
}

.selectContainer .downArrow {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid black;
    pointer-events: none;
    height: 1.8rem;
    margin-top: 4px;
}

.selectContainer img {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 8px;
    margin-left: 7px;
}

/* Autocomplete stuff */
.autocompleteBox {
    background-color: white;
    z-index: 1;
    padding-left: 2rem;
    padding-top: .5rem;
}

.autocompleteBox .result {
    display: block;
}

