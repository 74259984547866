/*the container must be positioned relative:*/
bai-catalog-header #root .autocomplete, bai-catalog-header-lms #root .autocomplete {
  position: relative;
  display: inline-block;
  font-size: 14px;
  height: auto;
}

    bai-catalog-header #root .autocomplete input {
        margin-top: 0.15em;
    }

    bai-catalog-header-lms #root .autocomplete input {
        margin-top: 0.2em;
    }


    #search-term {
        border: 1px solid transparent;
        background-color: #f1f1f1;
        padding: 10px;
    }

#search-term[type=text] {
  background-color: #f1f1f1;
  width: 100%;
}

#search-term[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
  cursor: pointer;
}

bai-catalog-header #root .autocomplete-items, bai-catalog-header-lms #root .autocomplete .autocomplete-items {
    position: absolute;
    border: 1px solid black;
    width: 175%;
    /*border-bottom: none;*/
    border-top: none;
    z-index: 99;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    background-color: white;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: 3px 3px 10px grey;
}

bai-catalog-header-lms #root .autocomplete .autocomplete-items {
    width: 125%;
}

    bai-catalog-header #root .autocomplete-items .suggestion-row, bai-catalog-header-lms #root .autocomplete-items .suggestion-row {
        padding-left: 15px;
        cursor: pointer;
        height: auto;
        background-color: white;
        margin-bottom: 5px;
    }

        bai-catalog-header #root .autocomplete-items .suggestion-row div, bai-catalog-header-lms #root .autocomplete-items .suggestion-row div {
            background-color: inherit;
            height: inherit;
        }

    bai-catalog-header #root .autocomplete-items .suggestion, bai-catalog-header-lms #root .autocomplete-items .suggestion {
        margin-bottom: 5px;
    }

bai-catalog-header #root .autocomplete .indented, bai-catalog-header-lms #root .autocomplete .indented {
    padding-left: 40px;
}

/*when hovering an item:*/
.autocomplete-items .suggestion:hover {
  background-color: #00A1DE !important;
  color: #ffffff !important; 
}

/*when navigating through the items using the arrow keys:*/
bai-catalog-header #root .autocomplete-items .autocomplete-active, bai-catalog-header-lms #root .autocomplete-items .autocomplete-active {
    background-color: #00A1DE !important;
    color: #ffffff !important;
}

bai-catalog-header #root .autocomplete-items #see-all, bai-catalog-header-lms #root .autocomplete-items #see-all {
    color: #00A1DE;
    margin-top: 20px;
    text-decoration: underline;
}

bai-catalog-header #root .autocomplete-items .hr-row, bai-catalog-header-lms #root .autocomplete-items .hr-row {
    height: 10px;
}

bai-catalog-header #root .autocomplete-items hr, bai-catalog-header-lms #root .autocomplete-items hr {
    border-top: 1px solid black;
    height: 5px;
}

