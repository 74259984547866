/* The tree view on the TA / premium admin pages */
.tree {
    background-color: #E5E5E5;
    margin-bottom: .25rem;
}

/* make the tree title and open close button bigger */
.tree .title span {
    font-weight: bold;
    font-size: 20px;
}

/* Make the open close button in the title aligned to the right */
.tree .title .largeOpenClose.openClose {
    width: 2rem;
    text-align: right;
    margin-right: 2.5rem;
}

/* Style the icon svg for the list */
.tree .treeRow .icon {
    width: 12px;
    height: 16px;
    display: inline-block;
    margin-bottom: 3px;
    margin-left: .5rem;
    margin-right: .5rem;
}

/* We don't want padding on the ul elements
 * because it messes up with the background
 * color of the highlighting. we want the whole
 * row/group highlighting to cover the entire element
 * and not be indented */
ul.tree {
    padding-left: 0;
}
.tree ul {
    padding-left: 0;
}

/* get rid of all bullet points on the inner items */
.tree li {
    list-style-type: none;
}

/* Every row should have the +/- on the right */
.tree .treeRow {
	display: flex;
	justify-content: space-between;
	/* give some breathing room between each row */
	padding-top: .3rem;
	padding-bottom: .3rem;
}

/* Make all open close buttons horizontally centered */
.tree .treeRow .openClose {
    margin-right: 1.95rem;
    width: 2rem;
    text-align: right;
}

/* Make the area right below the title have some padding on top */
.tree ul.topLevelGroups {
    padding-top: 1rem;
    /* If the tree becomes too tall, make it start scrolling */
    max-height: 40vh;
    overflow-y: scroll;
}

/* The group should have a gray box around it
 * The actual row the user clicked on should be slightly darker. The group is the highest level around the
 * 
 */
.tree .groupHighlight {
    background-color: #D7D7D7;
}
/* The clicked row should be slightly darker */
.tree div.highlight {
    background-color: #B6B6B6;
}

.tree li .treeRow {
    padding-left: 2rem;
}

.tree li li .treeRow {
    padding-left: 2rem;
}

.tree li li li .treeRow {
    padding-left: 4rem;
}

.tree li li li li .treeRow {
    padding-left: 6rem;
}

.tree li li li li li .treeRow {
    padding-left: 8rem;
}

.tree li li li li li li .treeRow {
    padding-left: 10rem;
}

.tree li li li li li li .treeRow {
    padding-left: 12rem;
}

.tree li li li li li li li .treeRow {
    padding-left: 14rem;
}

.tree li li li li li li li li .treeRow {
    padding-left: 16rem;
}

.tree li li li li li li li li li .treeRow {
    padding-left: 18rem;
}

.tree li li li li li li li li li li .treeRow {
    padding-left: 20rem;
}

.tree li li li li li li li li li li li .treeRow {
    padding-left: 22rem;
}

.tree li li li li li li li li li li li li .treeRow {
    padding-left: 24rem;
}


/***** NEW TREE STYLING *****/
treecontrol ul {
    background-color: #e5e5e5;
    margin-bottom: 1px;
}
/* Make the title of each tree in bold */
treecontrol > ul > li > .treeRow > .iconAndName > .tree-label {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

treecontrol > ul > li > .treeRow > .openClose > span {
    font-weight: bold;
    font-size: 20px;
    color: black;
}

/* Every row should have the +/- on the right */
treecontrol .treeRow {
    display: flex;
    justify-content: space-between;
    /* give some breathing room between each row */
    padding-top: .3rem;
    padding-bottom: .3rem;
    width: 100%;
}

/* Make the container for the tree scrollable */
treecontrol > ul > li > treeitem > ul {
    max-height: 500px;
    overflow: auto;
}

/* Make the second level of the tree left aligned with the title */
treecontrol > ul > li > treeitem > ul > li {
    padding: 0;
}

body treecontrol li {
    line-height: initial;
}

body treecontrol li img {
    margin-right: 5px;
}

treecontrol.tree-classic li .tree-label {
    font-size: 12px;
}

treecontrol.tree-classic li .tree-selected {
    background-color: #B6B6B6;
    font-weight: normal;
}

/* Overriding a default style on the tree */
body treecontrol li {
    padding: initial;
}

treecontrol li .treeRow {
    padding-left: 2rem;
}

treecontrol li li .treeRow {
    padding-left: 2rem;
}

treecontrol li li li .treeRow {
    padding-left: 4rem;
}

treecontrol li li li li .treeRow {
    padding-left: 6rem;
}

treecontrol li li li li li .treeRow {
    padding-left: 8rem;
}

treecontrol li li li li li li .treeRow {
    padding-left: 10rem;
}

treecontrol li li li li li li li .treeRow {
    padding-left: 12rem;
}

treecontrol li li li li li li li li .treeRow {
    padding-left: 14rem;
}

treecontrol li li li li li li li li li .treeRow {
    padding-left: 16rem;
}

treecontrol li li li li li li li li li li .treeRow {
    padding-left: 18rem;
}

treecontrol li li li li li li li li li li li .treeRow {
    padding-left: 20rem;
}

treecontrol li li li li li li li li li li li li .treeRow {
    padding-left: 22rem;
}

treecontrol li li li li li li li li li li li li li .treeRow {
    padding-left: 24rem;
}

treecontrol > ul > li > .treeRow .tree-selected {
    background-color: grey;
}
