#profile-container {
    border: 1px solid #00375D;
}

#student-sidebar {
    font-family: Verdana,Arial,sans-serif;
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 15px;
}

#student-sidebar #student-name {
    font-family: Rockwell,Verdana,Arial,sans-serif;
    font-size: 24px;
}

#student-sidebar #profile-header {
    background-color: #00375D;
    padding-top: 15px;
    padding-bottom: 15px;
}

#student-sidebar a:hover,
#student-sidebar a:visited,
#student-sidebar a:link,
#student-sidebar a:active {
    font-family: inherit;
    font-size: 12px;
    color: inherit;
    text-decoration: none;
}

#profile-container #profile-summary {
    color: #505050;
    padding-top: 15px;
    padding-bottom: 15px;
}

#student-sidebar #profile-header .border-right {
    border-right: 1px solid white;
}

#profile-header > div {
    padding-bottom: 5px;
}

#student-sidebar hr {
    border-top: 2px solid white;
    height: 10px;
    margin: 5px 15px 0px 15px;
}

#student-sidebar .profile-action {
    padding-right: 8px;
}

#profile-header #messages-row {
    padding-bottom: 0px;
    font-size: 12px;
}

#student-sidebar .secondary-blue {
    color: #1EABE3
}

#student-sidebar .category {
    font-size: 14px;
    font-weight: 900;
    padding-top: 7px;
}

#student-sidebar .count {
    font-size: 24px;
    font-weight: 900;
}

#profile-summary > div, #cp-summary > div {
    padding-bottom: 5px;
}

#student-sidebar #cp-summary-container {
    border: 1px solid #0579AB;
    border-top: none;
    color: #505050;
    padding-top: 15px;
    padding-bottom: 15px;
}

#student-sidebar .section-title {
    font-size: 18px;
    font-family: Rockwell,Verdana,Arial,sans-serif;
    color: #00375D;
    font-weight: bold;
}

#cp-summary-container .cp-badge {
    text-align: left;
    float: left;
    padding-right: 15px;
}

#cp-ellipses {
    font-size: 24px;
    font-family: Rockwell;
    font-weight: bold;
    float: left;
    width: 48px;
}

#student-sidebar #transcript-summary-container {
    border: 1px solid #0579AB;
    border-top: none;
    color: #505050;
    padding-top: 15px;
    padding-bottom: 15px;
}

.transcriptTitle{
    font-size: 14px;
    font-weight: 900;
    padding-top: 7px;
    max-width: 70%;
    overflow-wrap:break-word;
    word-break:break-word;
    word-wrap:break-word;
    overflow:hidden;
    text-align:left;
}

.transcriptDate {
    padding-top: 7px;
    max-width: 30%;
    overflow-wrap:normal;
}

.transcriptLink{
    padding-top: 7px;
    overflow-wrap:normal;
    text-align:right;
}

#student-sidebar #help-container {
    border: 1px solid #0579AB;
    border-top: none;
    color: #505050;
    padding-top: 15px;
    padding-bottom: 15px;
}

.helpImage {
    margin-bottom:5px;
    margin-right:10px;
}

#help-summary .category{
    margin-top:11px;
}

#help-summary .helpImage:first-of-type{
    margin-top:5px;
}

#student-sidebar #ldc-container {
    border: 1px solid #0579AB;
    border-top: none;
    color: #505050;
    padding-top: 15px;
    padding-bottom: 15px;
}

#ldc-summary .category{
    margin-top:11px;
}

#ldc-summary .helpImage:first-of-type{
    margin-top:5px;
}
