/**** CSS For /users/messages ****/

/* message item header */
#userMessages .messageItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

#userMessages .MessageDate {
    font-size: 18px;
    font-family: sans-serif;
    color: darkgray;
}

#userMessages h3 {
    font-size: 18px;
    font-family: sans-serif;
    color: black;
    font-weight: bold;
}

/* message item styling */
#userMessages .messageItem:first-child {
    margin-top: 4rem;
}
#userMessages .messageItem {
    border-top: 1px solid #e5e5e5;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 0;
}
#userMessages .messageItem:last-child {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 3rem;
}

#userMessages .messageItem .description {
    width: 90%;
    font-size: 14px;
}
