.floatingDate {
    background-color: #fff;
    color: #000;

    height: 50px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 7px;
    width: 50px
}

.floatingDate .month {
    background-color: #f00;
    color: #fff;
    font-size: 14px;
    width: 100%;
}

.floatingDate .month.green {
    background-color: #0f0;
}

.floatingDate .day {
    font-size: 22px;
}