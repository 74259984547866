body {
}

.insightsTable {
    padding-right: 0px;
    padding-left:15px;
    height: 500px;
    overflow: auto;
}

#CourseMapResults .spinner {
    margin: 0px !important;
    height: 20px !important;
    width: auto !important;
    text-align: left !important;
}

.insightsHeader{    
    margin-top: 2px;
    line-height: 1.42857143;
    vertical-align: top;
    font-size: 12px;
    font-weight: bold;
}

.insightsPortalTooLightFontColor {
    color: #ffffff;
}

.insightsHeader h2 {
    font-size: 32px;
    font-family: 'Rockwell', 'Verdana', 'Arial', 'san-serif';
}

.insightsHeaderTitle {
    background-color:#ffffff;
    border-right: white 1px solid;
}

.courseListHeader{
    font-family: 'Rockwell', 'Verdana', 'Arial', 'san-serif';
    font-size: 20px;
    font-weight:400;
    padding-left:15px;
    padding-top: 3rem
}

.courseTableHeader {
    padding-right:0px;
}

.courseTableHeader th{
    background-color: #E4E4E4;
    font-size:12px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight:bold;
}

.courseMapHeader{
    padding-left:15px;
    
    padding-right: 15px;
}

.courseMapHeader div {
    border-bottom: 2px solid black;
}

#CourseMapResults .padding{
    padding-left:15px;
}
.insightsCourses { 
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    background-color:#ffffff;    
    padding-top: 8px;
    padding-bottom: 8px;
}

.insightsPeerCourses { 
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #E4E4E4;
    background-color:#F9F9F9;    
    padding-top: 8px;
    padding-bottom: 8px;
}

.ICLarge{
    padding-left: 1px;
    padding-right: 2px !important;
}

.ICSmall { 
    padding-left:0px;
    padding-right:0px;
    text-align:center;
}

.ICXSmall{
    padding-left:5px;
    padding-right:5px;
}

.insightsHeaderBorderSpace
{    
    border-right-color: #a6a6a6;
    border-right-style: solid;
    border-right-width: 3px;
}

.peerTable{
    padding-left:0px !important;
}

.insightsContent
{
    padding-bottom: 20px;
}

.courseDescToolTip .tooltip-inner
{
    background-color:#e5e5e5;
    color: #000;
    border-color: #a6a6a6;
    border-style: solid;
    border-width: 2px;
}
.tooltip.in{
    opacity:1!important;
} 

.insightsTitle {
    background-color: white;
    padding: 2px;
}

.mapResultsFooter {
    background-color:#ffffff;
    padding-bottom: 10px;
    padding-left:30px;
    padding-right:30px;
    padding-top:5px;
}

.seeFooter
{
    color: #1EABE3;
    text-decoration: underline;
    font-size: 14px;
}

.seeMoreCourses{
    padding-left:2px;
}

.totalsFooter{
    font-size: 14px;
    text-align: center;
    background-color:#1EABE3;
    color: #ffffff;    
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px;
}

.courseHelpTextBottom{
    padding-top:10px;
    padding-bottom:10px;
    padding-left:15px;
    font-size:14px;
    font-weight:400;
}

.courseHelpTextTop{
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left:15px;
    font-size:14px;
    font-weight:400;
}


.spacer{
    padding-left:10px;
    padding-right:40px;
}

.selectedFooter{
    padding-top:20px;
    padding-bottom:20px;
}

.sortorder:after {
    content: '\25b2';
}
.sortorder.reverse:after {
    content: '\25bc';
}

.sortorder:after {
    content: '\25b2';
}
.sortorder.reverse:after {
    content: '\25bc';
}

.ICExpandAssigned{
    width: 22% !important;
    text-align: center;
}

.ICExpandRank{
    width: 14% !important;
    text-align: center;
}

.ICExpandStatus{
    width: 14% !important;
    text-align: center;
}
.ICExpandPeerStatus{
    width: 14% !important;
    text-align: center;
}

#CourseMapResults input[type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    width: 1.5ex;
    height: 0.9ex;
    background: rgba(0, 0, 0, 0);
    top: 1.5ex;
    left: 0.3ex;
    border: 3px solid #1EABE3;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/*hides the existing checkbox*/
#CourseMapResults input[type="radio"],
#CourseMapResults input[type="checkbox"] {
    position: absolute;
    left: -999em;
}

#CourseMapResults input[type="checkbox"] + label {
    position: relative;
    cursor: pointer;
}

#CourseMapResults input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 2ex;
    width: 2ex;
    background-color: white;
    border: 1px solid rgb(166, 166, 166);
    border-radius: 4px;
    box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
    margin-right: 0.5em;
}

.hiddenHeaderSpacer{
    padding-right: 5px;
    visibility: hidden;
}

/* Insights Comments */
#CourseMapResults #commentContainer{
    padding-left:15px;
}

.commentLabel{
    font-weight: normal;
    font-size: 14px;
    padding: 5px;
    border-radius: 15px 15px 0px 0px;
    margin-bottom:0px;
}

#commentContainer .activeBackground {
    background-color: #e4e4e4;
}

#commentContainer .inactiveBackground {
    background-color: #f9f9f9;
}

#commentContainer .activeTab{
    border-top:2px solid #e4e4e4;
}

#commentContainer .col-md-12{
    padding-left:0px;
}

.insightsComments{
    height: 120px;
    overflow: auto;
}

.insightsCommentCreated{
    text-align: left;
    padding-bottom: 2px; 
    font-style:italic;
}

.commentContainer{
    border-bottom:1px solid #eee;
    padding-left: 0px;    
    padding-bottom: 5px;
    padding-top: 10px;
}

.commentContainer div{
    padding-left:0px;
}

.commentContainer div div{
    padding-left:0px;
}

.commentContainer:hover {
    background-color: #F9F9F9;    
}

.deleteButton {
    text-align: right;
    display:none;
}

.deleteButtonShow {
    text-align: right;
    display:block !important;
}

.commentContainer:hover .deleteButton {
   display:block;   
}
.deleteButton:hover {
    display:block;
}

.deleteImage {
    height: 25px;
}

.sureDelete {
    text-align: center;
    background-color: #1EABE3;
    border: 2px solid rgb(238, 238, 238);
    padding: 5px;
    color:white;
}

.sureDelete a {
    color:white;
}

.commentTextBox{
    padding: 5px 10px;
    border-radius: 3px !important;
    border: 1px solid #ccc !important;
    outline: 0;
    height:100px !important;
}

.newComment {
    font-size: 14px !important;
}

.historyContainer{
    border-bottom:1px solid #eee;
    padding-left: 0px;    
    padding-bottom: 5px;
    padding-top: 10px;
}

.mapHistory{
    padding-right:0px !important;
}

.mapHistory div{
    padding-right:0px !important;
    padding-left:0px;
}

.mapHistory.insightsComments {
    height: 222px !important;
}
/* END Insights Comments */

#CourseMapResults .popover{
    max-width:500px !important;
}

.courseDescriptionPopover {
    max-width: 600px;
}

.courseDescriptionPopover .popover-content{
    padding:0px;
}

/* start wizard styling */
.decisionMapContent{
    background-color:white;
    padding-top: 20px;
    padding-left: 2rem;
}

.decisionMapContent label {
    font-weight: normal;
    font-size: 14px;
}

.decisionMapInput {
    width: 50%;
}

.decisionMapConfirmText {
    font-weight:bold;
    background-color: white;
    padding: 20px;
    color:#1EABE3;
    margin-left: -20px !important;
}

.stepActiveText {    
    color:#1EABE3 !important;
}

.insightsDecisionMapWizard { 
    line-height: 1.42857143;
    vertical-align: top;
    background-color:#ffffff;    
    padding-top: 10px;
    padding-bottom: 8px;
    padding-right:0px;
}

.insightsDecisionMapWizard label {
    font-weight: normal;
    font-size: 14px;
}

.stepBarShort::before {
    left: -4.4em !important;
    width: 4.4em !important;
}

.stepBarMedium::before {
    left: -5.4em !important;
    width: 5.4em !important;
}

.stepBarLong::before {
    left: -8.3em !important;
    width: 8.3em !important;
}

#DecisionMapReport .title {
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 8px;

}

.reportCommentSave {
   float: right;
   margin-top: 5px;
   padding: 5px 10px !important;
}

.insightsCoursesReportComments {
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.insightsCoursesReportComments #commentContainer {
    background-color: white;
}

.insightsCoursesReportComments #commentContainer #commentTxtBoxContainer {
    padding-top:8px;
}

.insightsCoursesReportComments .commentContainer{
    border-bottom:none;
}

.insightsCoursesReportComments #commentList {
    background-color: #f9f9f9;
    min-height:150px;
}

.insightsCoursesReportComments .commentContainer div {
    padding-right:0px;
}

.insights-table {
    background-color: #eeeeee;
}

.insights-table a {
    font-size: 12px;
}

.insights-table button {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    padding-left: 0;
    padding-right: 0;
}

.insights-table button:hover {
    text-decoration: underline;
}

.decisionMakerLinks {
    margin-left: -15px;
    margin-right: -15px;
}

.insights-risks-table {
    border-bottom: 1px solid #ddd;
}

.insights-risks-table>tbody>tr>td.riskRating {
    background-color: #F2F2F2;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    color: #999999;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
    vertical-align: middle;
}

.insights-risks-table>tbody>tr>td.riskRating.active {
    color: #000000;
    font-weight: bold;
}

.insights-risks-table>tbody>tr>td.riskRating.low.active {
    background-color: #66B340;
}

.insights-risks-table>tbody>tr>td.riskRating.medium.active {
    background-color: #FF9900;
}

.insights-risks-table>tbody>tr>td.riskRating.high.active {
    background-color: #FF8080;
}

.insights-risks-table>tbody>tr>td.riskRating.low:hover {
    background-color: #CBECB0;
}

.insights-risks-table>tbody>tr>td.riskRating.medium:hover {
    background-color: #FBD5B5;
}

.insights-risks-table>tbody>tr>td.riskRating.high:hover {
    background-color: #F8D1D3;
}

.insights-risks-table>tbody>tr>td.riskRating.low.active:hover {
    background-color: #66B340;
}

.insights-risks-table>tbody>tr>td.riskRating.medium.active:hover {
    background-color: #FF9900;
}

.insights-risks-table>tbody>tr>td.riskRating.high.active:hover {
    background-color: #FF8080;
}

.insightsCategory{
    padding:2px !important;
    font-size:20px !important;
}

.insightsCategoryArrow {
    margin-left: 45% !important;
}

.categorySpacer{
    height:20px;
}

/* Insights Tips */
.tipsHeader {
    background-color:#1EABE3;
    padding:5px;    
    font-weight: 400;
    text-align: center;
    font-size: 24px;
    color:white;
    font-style: italic;
    font-family:'Rockwell', 'Verdana', 'Arial', 'san-serif';
}

.tipsArrowDown{
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-right: 20px solid white;
    border-top: 20px solid transparent;
    background-color: #1EABE3;
    margin-left:48%;
}

.tips
{
    padding-left:20px;
    padding-right:20px;
}

.tipsImage{
    height:30px;
    width:30px;
    background-image:url('../../App/images/icons/lightbulb_white.svg');
    background-size:30px;    
    display: inline-block;
    vertical-align: middle;
}

.StartCreateTips {
    padding-top:20px;
}

.StartCreateTips .tipsHeader {
    margin-left: 40px;
    margin-right: 40px;
}

.StartCreateTips .tips {
    padding-left: 50px;
    padding-right: 50px;
}

.decisionMakerAdd {
    background-color:#E4E4E4 !important;
    color:black !important;
    font-size:12px !important;
}

.decisionMakerCannotRemove {
    cursor: not-allowed !important;
}

.decisionMakerCannotRemove.decisionMakerAdd.btn.pill:before {
    content: none !important;
}

.decisionReportTip{
    padding-top: 5px;
    font-size: 14px;
}
/* END Insights Tips */

/* START selection confirmation modal */
.selectionsModal{
    background-color:white;
    padding:20px;
    margin-top: 20px;
}

.selectedOptions{
    padding:20px;
}

.removeSelectionsTable tr{
    line-height: 1.42857143;
    vertical-align: top;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

.removeSelectionsTable td {
    padding: 10px;
}

.removeSelectionsTable a{
    font-size:14px !important;
}

/* END selection confirmation modal */