.glommer {
    margin-top: 15px;
    padding: 0px 15px 0 0;
}

ul.glommer li {
    width: 3em;
    height: 3em;
    text-align: center;
    line-height: 3em;
    border-radius: 5em;
    margin: 0 4em 0 0;
    display: inline-block;
    color: white;
    position: relative;
}

ul.glommer li span.number {
    z-index: 2;
    position: relative;
    font-size: 17px;
}

ul.glommer li span.info {
    z-index: 2;
    position: absolute;
    top: 28px;
    left: -28px;
    color: #1EABE3;
    width: 9rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

ul.glommer li::before {
    content: '';
    position: absolute;
    top: 1.4em;
    left: -4em;
    width: 4em;
    height: 0.4em;
    z-index: 1;
}

ul.glommer li:first-child {
    margin-left: 0px;
}

ul.glommer li:last-child {
    margin-right: 0px;
}

ul.glommer li:first-child::before {
    display: none;
}

ul.glommer .active {
    background: #1EABE3;
}

ul.glommer li {
    background: #CECECE;
}

ul.glommer li::before {
    background: #CECECE;
}

ul.glommer li.active::before {
    background: #1EABE3;
}

ul.glommer li span.info {
    color: #CECECE;
}
