bai-product-details p {
    font-size: 14px;
}
bai-product-details p.medium {
    font-size: 14px;
}

bai-product-details .title {
    padding-bottom: 0.5em;
}

bai-product-details .description {
    padding-bottom: 2.5em;
}