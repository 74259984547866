#messages {
    position: absolute;
    right: 0;
    top: 150px;
    width: 350px;
    z-index: 10;
}

#messages .message {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    border: 2px solid #1EABE3;
    border-right: none;
    margin-bottom: 10px;
    padding: 15px;
    position: relative;
}

#messages .message h3 {
    font-size: 14px;
    margin: 0px;
    padding: 0px 0px 7px;
}

#messages .message p {
    font-size: 11px;
}

#messages .message .closer {
    background: #f00;
    border: 1px solid #f00;
    color: #fff;
    left: -12px;
    top: -12px;
}
