md-tabs.col-md-12 {
    padding: 28px 0 36px 0;
    display: inline-block;
}

md-tabs.md-default-theme .md-tab,
md-tabs .md-tab,
.md-tab {
    background-color: #fff;
}

md-tabs.col-md-12.instructor-nav .md-tab {
    color: #505050;
    font-size: 15px;
    padding: 0;
    text-decoration: underline;
    text-transform: capitalize;
}

md-tabs.col-md-12.instructor-nav2 .md-tab {
    color: #505050;
    font-size: 12px;
    padding: 0;
    text-decoration: underline;
    text-transform: capitalize;
}

md-tabs.col-md-12.instructor-nav .md-tab:not(:first-child):before {
    content: "|";
    color: black;
    display: inline-block;
    font-weight: 400;
    padding-right: 6px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-decoration: none !important;
}

md-tabs.col-md-12.instructor-nav .md-tab.md-active {
    font-weight: bold;
    border: none;
}
md-tabs.col-md-12.instructor-nav md-ink-bar {
    display: none;
}

md-tabs.col-md-12.instructor-nav md-tabs-wrapper {
    background-color: #fff;
    padding-left: 24px;
    border: none;
}
md-tabs.row.instructor-nav2 md-tabs-wrapper {
    background-color: #e0e0e0;
    height: 40px;
}
md-tabs.row.instructor-nav2 md-tabs-wrapper md-tab-item {
    background-color: #e0e0e0;    
    line-height: 40px;
}
md-tabs.col-md-12.instructor-nav2 .md-tab {
    background-color: #e0e0e0;
    line-height: 40px;
}

md-tabs.col-md-12.instructor-nav2 md-tabs-wrapper {
    background-color: #e0e0e0;
    height: 40px;    
    border: none;
}
md-tabs.col-md-12.instructor-nav2.md-dynamic-height {
    padding-top: 0;
}

md-pagination-wrapper {
    width: 999999px !important;
}

md-tabs.col-md-12 md-tabs-wrapper {
    background-color: #f8f8f0;
    padding: 0px;
}

.md-button {
    padding: 0px 30px;
}

.md-button span {
    color: #fff;
    padding: 0px;
}

.md-button.md-error:disabled {
    background-color: #ff0033;
}

md-dialog .md-actions,
md-dialog md-dialog-actions {
    padding: 0px 16px;
}

.panel-body {
    padding: 0px 0px 0px 30px;
}

.md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator,
md-calendar-month .md-calendar-month-label:not(.md-calendar-month-label-disabled) {
    text-decoration: underline;
}

@media (min-width: 960px) {
    .md-padding {
        padding-top: 0;
    }
}

#taCal .md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
    background: rgb(3,169,244);
    color: rgba(0,0,0,0.87);
    border-color: transparent;
}

/* Rules to deal with modal always being positioned at top and fixed */
.md-dialog-is-showing {
    overflow: visible;
}

md-dialog,
md-dialog>form {
    display: block;
}

md-dialog .messaging {
    margin: 0px;
}