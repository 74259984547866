img.icon {
    display: block;
    height: 100px;
    margin: 0px;
    padding: 0px;
    width: 100px;
}

.magnifying-glass {
    padding: 0px;
    border: none;
    background: none;
    display: block;
    margin-left: 1rem;
}

/* file size: 1.1ko | optimized file size: 0.9ko | base64 size: 1.3ko */
.magnifying-glass svg {
    display: block;
    fill: #009ce6;
    height: 42px;
    width: 42px;
}

.circle-question {
    padding: 4px;
    border: 2px;
    background-color: #1EABE3;
    height: 50px;
    border-radius:50%;
    width: 50px;
}

#peopleGroupTable .circle-question {
    margin-top: 25px;
    margin-right: 15px;
    z-index: 1;
}

#video-icon{
    display: block;
    margin-left: -2.5px;
    margin-top: -2.5px;
    width: 47px;
    background-color: #ffffff;
    clip-path: circle(45%);
}