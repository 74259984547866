a.btn-default,
.btn-default {
    color: #fff;
    text-decoration: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #1EABE3;
    border: 1px solid #fff;
    font-size: 14px;
    padding: 1rem 5rem;
}

.btn-default,
.btn-default:active,
.btn-default:focus,
.btn-default:hover {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #1EABE3;
    border: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    padding: 1rem 5rem;
}

/* Button styling */
button.btn-default {
    /* Remove all text shadows on buttons from now on.
     * bootstrap adds a text shadow automagically so we remove it. */
    text-shadow: initial;
}

/* Buttons for Navgation on TA Page*/

.btn-nav {
    background-color: white;
    border: 1px solid #d6d6d6;
    color: #a6a6a6;
    padding: 6px 26px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 0px;
    box-shadow: 1px 1px 9px #d6d6d6;
}

.btn-nav:hover { 
    background-color: grey;
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.btn-nav:active {
  color: white; 
  background-color: grey ; 
  font-weight: bold;
  /*border-color: #494F57;*/
}

.btn-nav-search {
    width: 60px;
    height: 35px;
    margin-left: 7px;
    border-radius: 11px;
    font-weight: bold;
    background-color: #1EABE3;
    border-color: white;

}

.btn-filter-toggle {
    background-color: white;
    border: 1px solid #d6d6d6;
    color: #000000;
    padding: 4px 50px 4px 6px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 0px;
    /*box-shadow: 1px 1px 9px #d6d6d6;*/
}

/* Used in the ta page course wizard
 * Puts a gray line on top of the next/previous/cancel buttons
 
 Use like so to create a border in a page
<div class="col-md-12 ">
    <div class="row grayTopBorder">
    </div>
</div>
 */
.graySeparator {
    border-top: 1px solid #e5e5e5;
    padding-top: 3rem;
    margin-top: 2rem;
    display: block;
    margin-left: 0;
    margin-right: 0;
}

/* Pill style buttons */
.btn.pill {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 3px;
    padding: 3px 6px;
}

.btn.pill:before {
    content: 'x ';
    color: #000;
}

.new-student-button, .new-student-button:active, .new-student-button:focus, .new-student-button:hover {
    font-size: 12px;
    padding-left: 2.25em;
    padding-right: 2.25em;
    border: none;
    border-radius: 10px;
    background-color: #1EABE3;
    color: white;
}

.btn-bottom-left {
    bottom: 0px;
    left: 0px;
    margin-bottom: 5px;
    margin-left: 5px;
}

