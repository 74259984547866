/** The general modal styling is in modals.css in
 * the css components folder. This file contains styling only for 
 * the enrollment request AND some styling common to both the 
 * enrollment request modal and approvals modal */

/* background styling for each item in the enrollment request and approvals list */

.modal .requestsList {
    height: 40vh;
    margin-right: 24px; /* We're off by 6 pixels somewhere. */
    overflow-y: scroll;
}

.modal .requestsList::-webkit-scrollbar-corner {
    /*
     * background-image: url(resources/corner.png);
     * background-repeat: no-repeat;
     * */
    background-color: #e5e5e5;
}

.modal .requestContainer {
    background-color: white;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 15px;
}

.modal .requestContainer + .requestContainer {
    margin-top: 1rem;
}

/**** Enrollment request container ****/
/* Name of the user who's asking for enrollment */
.enrollmentRequestContainer .meta > .title {
    font-size: 16px;
    font-weight: bold;
}

.requestContainer .row {
    width: 100%;
}

/* Make the details in a column by default */
.enrollmentRequestContainer .meta {
    display: flex;
    flex-direction: column;
    line-height: 1.7rem;
}

/* The meta details should be in bold */
.enrollmentRequestContainer .assignmentName .title,
.enrollmentRequestContainer .startDate .title,
.enrollmentRequestContainer .endDate .title,
.enrollmentRequestContainer .location .title {
    font-weight: bold;
}

.enrollmentRequestContainer .assignmentName,
.enrollmentRequestContainer .dateContainer,
.enrollmentRequestContainer .location {
    margin-top: -.2rem;
}

.enrollmentRequestContainer .requestedDate {
    font-style: italic;
    margin-bottom: .5rem;
}

/* Start/end date should be in a row */
.enrollmentRequestContainer .dateContainer {
    display: flex;
    flex-direction: row;
}

/* End date should be visually separated from the start date */
.enrollmentRequestContainer .endDate {
    margin-left: 5rem;
}

/* approve/deny buttons */
.enrollmentRequestContainer .actions {
    align-items: center;
}

.enrollmentRequestContainer .actions .spinner {
    margin: 0px auto;
}

.enrollmentRequestContainer .deny {
    background: none;
    border: none;
    font-size: 16px;
    margin: .75rem 3rem 0rem;
    text-decoration: underline;
}
