.dropdown-menu {
    border-radius: 0px;
    margin: 0px;
    /* width: 100%; */
    overflow-y: auto;
    max-height: 300px;
}

.dropdown-menu > .active,
.dropdown-menu > .active:focus,
.dropdown-menu > .active:hover {
    background-color: #00A1DE;
    background-image: none;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    background: none;
}
